import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { ScreenContainer } from '../../../core/components/container/ScreenContainer';
import { VersionTag } from '../../../core/components/VersionTag';
import { Header } from '../../../navigation/components/Header';
import { useMainStore } from '../../../store/mainStore';
import { getMitarbeiterUserIsLoggedIn } from '../../../store/userSlice';

export const AdminWelcomeLayout = () => {
  const isLoggedIn = useMainStore(getMitarbeiterUserIsLoggedIn);
  if (isLoggedIn) {
    return <Navigate to="/admin" />;
  }
  return (
    <ScreenContainer>
      <Header />
      <OutletContainer className="onBoardingScreenContainer-scroll-ancestor">
        <Outlet />
      </OutletContainer>
      <VersionTag />
    </ScreenContainer>
  );
};

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  padding-top: 100px;
  padding-bottom: 200px;
  background-color: 'red';
`;
