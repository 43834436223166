import { StateCreator } from 'zustand';
import { colors } from '../styles/colors';
import { AppCustomization } from '../types/Practices/AppCustomizationType';

type AppBranding = Omit<Omit<AppCustomization, 'practiceId'>, 'colors'> & {
  practiceId?: string;
  colors?: {
    primary?: string;
    dark?: string;
    medium?: string;
    light?: string;
  };
};

export type AppBrandingState = {
  appBranding?: AppBranding;
  setAppBranding: (appBranding?: AppBranding) => void;
  setAppBrandingColors: (colors: { primary?: string; dark?: string; medium?: string; light?: string }) => void;
  setAppBrandingLogoUrl: (logoUrl: string) => void;
  setAppBrandingMapsInfo: (mapsInfo: {
    googleMapsId?: string;
    longitude?: number;
    latitude?: number;
    mapsStaticImageUrl?: string;
  }) => void;
  setAppBrandingMapsImageUrl: (mapsImageUrl?: string) => void;
  setAppBrandingAddress: (address: {
    practiceName: string;
    owner: string;
    houseNumber: string;
    street: string;
    zip: string;
    city: string;
  }) => void;
  setAppBrandingOpeningHours: (openingHours: { dayNumber: number; text: string }[]) => void;
  setAppBrandingContact: (contact: { phone?: string; mail?: string; website?: string }) => void;
  setAppBrandingClaim: (claim: string) => void;
  setAppBrandingServices: (services: { title: string; services: string[] }[]) => void;
  setAppBrandingImages: (imageUrls?: string[]) => void;
  addAppBrandingImage: (imageUrl: string) => void;
  setAppBrandingDescription: (description: string) => void;
  resetAppBrandingState: () => void;
};

const initialState = {
  appBranding: {
    services: [{ title: '', services: [''] }],
  },
};

export const createAppBrandingSlice: StateCreator<AppBrandingState> = (set) => ({
  ...initialState,
  setAppBranding: (appBranding?: AppBranding) => set({ appBranding }),
  setAppBrandingColors: (newColors: { primary?: string; dark?: string; medium?: string; light?: string }) =>
    set((state: AppBrandingState) => {
      if (!state.appBranding) {
        return { appBranding: { colors: newColors } };
      }
      const appBranding = { ...state.appBranding, colors: newColors };
      return { appBranding };
    }),
  setAppBrandingLogoUrl: (logoUrl: string) =>
    set((state: AppBrandingState) => {
      if (!state.appBranding) {
        return { appBranding: { logoUrl } };
      }
      const appBranding = { ...state.appBranding, logoUrl };
      return { appBranding };
    }),
  setAppBrandingMapsInfo: (mapsInfo: {
    googleMapsId?: string;
    longitude?: number;
    latitude?: number;
    mapsImageUrl?: string;
  }) =>
    set((state: AppBrandingState) => {
      if (!state.appBranding) {
        return { appBranding: { mapsInfo } };
      }
      const appBranding = { ...state.appBranding, mapsInfo };
      return { appBranding };
    }),
  setAppBrandingAddress: (address: {
    practiceName: string;
    owner: string;
    houseNumber: string;
    street: string;
    zip: string;
    city: string;
  }) =>
    set((state: AppBrandingState) => {
      if (!state.appBranding) {
        return { appBranding: { address } };
      }
      const appBranding = { ...state.appBranding, address };
      return { appBranding };
    }),
  setAppBrandingContact: (contact: { phone?: string; mail?: string; website?: string }) =>
    set((state: AppBrandingState) => {
      if (!state.appBranding) {
        return { appBranding: { contact } };
      }
      const appBranding = { ...state.appBranding, contact };
      return { appBranding };
    }),
  setAppBrandingOpeningHours: (openingHours: { dayNumber: number; text: string }[]) =>
    set((state: AppBrandingState) => {
      if (!state.appBranding) {
        return { appBranding: { openingHours } };
      }
      const appBranding = { ...state.appBranding, openingHours };
      return { appBranding };
    }),
  setAppBrandingClaim: (claim: string) =>
    set((state: AppBrandingState) => {
      if (!state.appBranding) {
        return { appBranding: { claim } };
      }
      const appBranding = { ...state.appBranding, claim };
      return { appBranding };
    }),
  setAppBrandingServices: (services: { title: string; services: string[] }[]) =>
    set((state: AppBrandingState) => {
      if (!state.appBranding) {
        return { appBranding: { services } };
      }
      const appBranding = { ...state.appBranding, services };
      return { appBranding };
    }),
  setAppBrandingImages: (imageUrls?: string[]) =>
    set((state: AppBrandingState) => {
      if (!imageUrls) {
        delete state.appBranding?.imageUrls;
        return state;
      }
      if (!state.appBranding) {
        return { appBranding: { imageUrls } };
      }
      const appBranding = { ...state.appBranding, imageUrls };
      return { appBranding };
    }),
  addAppBrandingImage: (imageUrl: string) =>
    set((state: AppBrandingState) => {
      if (!state.appBranding) {
        return { appBranding: { imageUrls: [imageUrl] } };
      }
      const appBranding = { ...state.appBranding, imageUrls: [...(state.appBranding.imageUrls || []), imageUrl] };
      return { appBranding };
    }),
  setAppBrandingDescription: (description: string) =>
    set((state: AppBrandingState) => {
      if (!state.appBranding) {
        return { appBranding: { description } };
      }
      const appBranding = { ...state.appBranding, description };
      return { appBranding };
    }),
  setAppBrandingMapsImageUrl: (mapsImageUrl?: string) =>
    set((state: AppBrandingState) => {
      if (!state.appBranding) {
        return { appBranding: { mapsInfo: { mapsImageUrl } } };
      }
      const appBranding = { ...state.appBranding, mapsInfo: { ...state.appBranding.mapsInfo, mapsImageUrl } };
      return { appBranding };
    }),
  resetAppBrandingState: () => set(initialState),
});

export const getAppBranding = (state: AppBrandingState) => state.appBranding;
export const getAppBrandingColors = (state: AppBrandingState) => {
  const appBranding = getAppBranding(state);
  const appBrandingColors = appBranding?.colors;
  return {
    Primary: appBrandingColors?.primary || colors.Primary,
    Dark: appBrandingColors?.dark || colors.Dark,
    Medium: appBrandingColors?.medium || colors.Medium,
    Light: appBrandingColors?.light || colors.Light,
    White: colors.White,
  };
};
export const getAppBrandingLogoUrl = (state: AppBrandingState) => getAppBranding(state)?.logoUrl;
export const getAppBrandingMapsInfo = (state: AppBrandingState) => getAppBranding(state)?.mapsInfo;
export const getAppBrandingAddress = (state: AppBrandingState) => getAppBranding(state)?.address;
export const getAppBrandingContact = (state: AppBrandingState) => getAppBranding(state)?.contact;
export const getAppBrandingOpeningHours = (state: AppBrandingState) => getAppBranding(state)?.openingHours;
export const getAppBrandingClaim = (state: AppBrandingState) => getAppBranding(state)?.claim;
export const getAppBrandingServices = (state: AppBrandingState) => getAppBranding(state)?.services;
export const getAppBrandingImages = (state: AppBrandingState) => getAppBranding(state)?.imageUrls;
export const getAppBrandingDescription = (state: AppBrandingState) => getAppBranding(state)?.description;
export const getAppBrandingPracticeId = (state: AppBrandingState) => getAppBranding(state)?.practiceId;
