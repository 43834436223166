import { useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ProgressButton } from '../../../core/components/buttons/ProgressButton';
import { CheckoutBill } from '../components/CheckoutBill';
import { CheckoutCalculatorButtons } from '../components/CheckoutCalculatorButtons';
import { OnboardingBillContractButton } from '../components/OnboardingBillContractButton';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

export const OnboardingBillScreen = () => {
  const navigate = useNavigate();
  const { practiceId } = useOnboardingPracticeInfo();

  const [confirmedContract, setConfirmedContract] = useState(false);

  if (!practiceId) {
    return <Navigate to="../../practices-names" />;
  }

  const handleBack = () => {
    navigate('../invoice-address');
  };

  const handleNext = async () => {
    navigate('../checkout');
  };

  return (
    <OnboardingScreenContainer handleBack={handleBack}>
      <CheckoutBill />
      <OnboardingBillContractButton confirmedContract={confirmedContract} setConfirmedContract={setConfirmedContract} />
      <ButtonContainer>
        <ProgressButton
          text="Zahlungspflichtig bestellen"
          onClick={handleNext}
          numberOfSteps={1}
          currentStep={confirmedContract ? 1 : 0}
        />
      </ButtonContainer>
      <CheckoutCalculatorButtons />

      <Outlet />
    </OnboardingScreenContainer>
  );
};

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 10px;
`;
