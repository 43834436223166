import { collection, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { Practice } from '../../../types/Practices/PracticeType';

export const useAllPractices = () => {
  const [practices, setPractices] = useState<Practice[]>();
  useEffect(() => {
    const sub = onSnapshot(collection(db, 'practices'), (snapshot) => {
      const newPractices = snapshot.docs.map((doc) => doc.data() as Practice);
      const sortedPractices = newPractices.sort((a, b) => {
        if (a.createdAt && b.createdAt) {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        } else if (a.createdAt) {
          return -1;
        } else if (b.createdAt) {
          return 1;
        } else {
          return a.name?.localeCompare(b.name);
        }
      });
      setPractices(sortedPractices);
    });
    return sub;
  }, []);
  return practices;
};
