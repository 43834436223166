import { useState } from 'react';
import { getAppBrandingImages } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { AppBrandingPracticeImages } from '../../../appBranding/components/AppBrandingPracticeImages';
import { useAppBrandingPracticeInfo } from '../../../appBranding/utils/useAppBrandingPracticeInfo';
import { SettingsBrandingComponentsWrapper } from './SettingsBrandingComponentsWrapper';

export const SettingsBrandingImages = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { uploadAppBrandingData, uploading } = useAppBrandingPracticeInfo();
  const zustandAppBrandingImages = useMainStore(getAppBrandingImages);
  const [initialAppBrandingImages, setInitialAppBrandingImages] = useState(zustandAppBrandingImages);
  const nextButtonDisabled = zustandAppBrandingImages === initialAppBrandingImages;
  const handleSave = async () => {
    setInitialAppBrandingImages(zustandAppBrandingImages);
    await uploadAppBrandingData();
    setEventIndicator('success', 'Änderungen gespeichert');
  };

  return (
    <SettingsBrandingComponentsWrapper
      title="Bilder"
      description="Lade Bilder hoch, die deine Praxis und dein Team repräsentieren."
      nextButton={{
        text: 'Speichern',
        onClick: handleSave,
        loading: uploading,
        disabled: nextButtonDisabled,
      }}
    >
      <AppBrandingPracticeImages />
    </SettingsBrandingComponentsWrapper>
  );
};
