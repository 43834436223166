import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { colors } from '../../../../styles/colors';
import { H2, H3, H4, Small } from '../../../../styles/textStyles';
import { priceFormatter } from '../screens/AdminSalesCalculatorScreen';
import { Slider } from './Slider';

type AdminSalesCalculatorCompensationsComponentProps = {
  setCompensationValue: (value: number) => void;
};

export const AdminSalesCalculatorCompensationsComponent = (props: AdminSalesCalculatorCompensationsComponentProps) => {
  const { t } = useTranslation();
  const [fullTimeTherapists, setFullTimeTherapists] = useState(5);
  const [partTimeTherapists, setPartTimeTherapists] = useState(2);
  const invitedPatients = fullTimeTherapists * 5 * 28 + partTimeTherapists * 5 * 14;
  const [physiofitUsers, setPhysiofitUsers] = useState(50);
  const [gkvInsured, setGkvInsured] = useState(50);
  const successRate = 25;
  const billableUsers = Math.round((physiofitUsers / 100) * (gkvInsured / 100) * invitedPatients);
  const price = billableUsers * (successRate / 100) * 25;

  useEffect(() => {
    props.setCompensationValue(price);
  }, [price]);

  return (
    <Container>
      <H2>{t('ADMIN-SALES-COMPENSATION-TITLE')}</H2>
      <ContentContainer>
        <ParametersCard>
          <H3>{t('ADMIN-SALES-COMPENSATION-THERAPISTS-TITLE')}</H3>
          <SingleParameterContainer>
            <StyledH4>{t('ADMIN-SALES-COMPENSATION-THERAPISTS-PARAMETER-1')}</StyledH4>
            <Slider min={0} max={15} value={fullTimeTherapists} setValue={setFullTimeTherapists} />
          </SingleParameterContainer>
          <SingleParameterContainer>
            <StyledH4>{t('ADMIN-SALES-COMPENSATION-THERAPISTS-PARAMETER-2')}</StyledH4>
            <Slider min={0} max={15} value={partTimeTherapists} setValue={setPartTimeTherapists} />
          </SingleParameterContainer>
          <SingleParameterContainer>
            <div>
              <StyledH4>{t('ADMIN-SALES-COMPENSATION-THERAPISTS-PARAMETER-3')}</StyledH4>
              <StyledSmall>{t('ADMIN-SALES-COMPENSATION-THERAPISTS-PARAMETER-3-DESCRIPTION')}</StyledSmall>
            </div>
            <ValueDisplay>
              <StyledH3>{invitedPatients}</StyledH3>
            </ValueDisplay>
          </SingleParameterContainer>
        </ParametersCard>
        <Separator />
        <ParametersCard>
          <H3>{t('ADMIN-SALES-COMPENSATION-POTENTIAL-TITLE')}</H3>
          <SingleParameterContainer>
            <StyledH4>{t('ADMIN-SALES-COMPENSATION-POTENTIAL-PARAMETER-1')}</StyledH4>
            <Slider min={0} max={100} value={physiofitUsers} setValue={setPhysiofitUsers} isPercentage />
          </SingleParameterContainer>
          <SingleParameterContainer>
            <StyledH4>{t('ADMIN-SALES-COMPENSATION-POTENTIAL-PARAMETER-2')}</StyledH4>
            <Slider min={0} max={100} value={gkvInsured} setValue={setGkvInsured} isPercentage />
          </SingleParameterContainer>
          <SingleParameterContainer>
            <div>
              <StyledH4>{t('ADMIN-SALES-COMPENSATION-POTENTIAL-PARAMETER-3')}</StyledH4>
              <StyledSmall>{t('ADMIN-SALES-COMPENSATION-POTENTIAL-PARAMETER-3-DESCRIPTION')}</StyledSmall>
            </div>
            <ValueDisplay>
              <StyledH3>{billableUsers}</StyledH3>
            </ValueDisplay>
          </SingleParameterContainer>
        </ParametersCard>
      </ContentContainer>
      <PriceContainer>
        <PriceLabel>
          {t('ADMIN-SALES-COMPENSATION-PRICE-LABEL', {
            amountPatients: billableUsers,
            successRate: successRate,
          })}
        </PriceLabel>
        <Price>{priceFormatter.format(price)}</Price>
      </PriceContainer>
    </Container>
  );
};

const StyledSmall = styled(Small)`
  color: ${colors.Medium};
`;

const Container = styled.div`
  flex: 3;
  max-width: 780px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${hexWithOpacity(colors.Medium, 0.1)};
  border-radius: 20px;
  gap: 40px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${hexWithOpacity(colors.Medium, 0.2)};
  margin: 10px 0;
`;

const ParametersCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SingleParameterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledH4 = styled(H4)`
  color: ${colors.Medium};
`;

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
`;

const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Price = styled(H2)`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const PriceLabel = styled(H4)`
  flex: 2;
  display: flex;
  justify-content: flex-start;
`;

const ValueDisplay = styled.div`
  padding: 0 20px;
  width: 80px;
  height: 44px;
  background-color: ${colors.White};
  border-radius: 4px;
  border: 1px solid ${colors.Medium};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  opacity: 0.6;
`;
