import { doc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { CustomButton } from '../../../core/components/buttons/CustomButton';
import { InfoText } from '../../../core/components/text/InfoText';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { getUserChainId } from '../../../store/userSlice';
import { colors } from '../../../styles/colors';
import { Body, H4 } from '../../../styles/textStyles';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

const PaymentOptions = [
  {
    value: 'same',
    title: 'ONBOARDING-PAYMENT-TYPE-SCREEN-PAY-ONCE-TITLE',
    description: 'ONBOARDING-PAYMENT-TYPE-SCREEN-PAY-ONCE-SUBTITLE',
  },
  {
    value: 'different',
    title: 'ONBOARDING-PAYMENT-TYPE-SCREEN-PAY-SEPARATELY-TITLE',
    description: 'ONBOARDING-PAYMENT-TYPE-SCREEN-PAY-SEPARATELY-SUBTITLE',
  },
];

export const OnboardingPaymentTypeURL = 'payment-type';

export const OnboardingPaymentTypeScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const chainId = useMainStore(getUserChainId);
  const {
    practiceId,
    practiceIndex,
    nextPracticeId,
    paymentType: initialPaymentType,
    isMigration,
  } = useOnboardingPracticeInfo();

  const [chosenOption, setChosenOption] = useState(initialPaymentType ?? '');
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    try {
      setLoading(true);
      await updateDoc(doc(db, 'practiceChains/' + chainId), { 'onboarding.onboardingPaymentType': chosenOption });
      if (chosenOption === 'same' && nextPracticeId) {
        navigate(`../../${nextPracticeId}/practice-data-info`);
      } else {
        navigate('../invoice-address');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (!practiceId || practiceIndex !== 0 || isMigration) {
    return <Navigate to="/practices-names" />;
  }
  return (
    <OnboardingScreenContainer
      handleBack={() => navigate('../practice-starter')}
      nextButton={{
        currentStep: chosenOption !== '' ? 1 : 0,
        numberOfSteps: 1,
        onClick: handleNext,
        loading: loading,
      }}
    >
      <InfoText
        headline={t('ONBOARDING-PAYMENT-TYPE-SCREEN-TITLE')}
        text={[t('ONBOARDING-PAYMENT-TYPE-SCREEN-SUBTITLE')]}
      />
      {PaymentOptions.map((option) => (
        <PaymentOptionContainer onClick={() => setChosenOption(option.value)} key={option.title}>
          <StyledSVG
            name={chosenOption === option.value ? 'CheckBoxRoundActive' : 'CheckBoxRoundInactive'}
            primaryColor={chosenOption === option.value ? colors.Primary : colors.Medium}
            onClick={() => setChosenOption(option.value)}
            size={20}
          />

          <PaymentOptionTextContainer>
            <StyledH4>{t(option.title)}</StyledH4>
            <StyledBody>{t(option.description)}</StyledBody>
          </PaymentOptionTextContainer>
        </PaymentOptionContainer>
      ))}
    </OnboardingScreenContainer>
  );
};

const PaymentOptionContainer = styled(CustomButton)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-left: 10px;
`;

const PaymentOptionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  padding-left: 17px;
`;

const StyledH4 = styled(H4)`
  color: ${colors.Medium};
  text-align: left;
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
  text-align: left;
`;

const StyledSVG = styled(GenericSvg)`
  margin-top: 10px;
`;
