import { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { hexWithOpacity } from '../../utils/hexWithOpacity';

export type Props = {
  initialValue?: string;
  placeholder?: string;
  onChange?: (_value: string) => void;
  rows?: number;
  maxLength?: number;
};

export const TextArea = (props: Props) => {
  const [value, setValue] = useState(props.initialValue ?? '');
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  return (
    <StyledTextArea
      placeholder={props.placeholder}
      value={value}
      onChange={handleChange}
      rows={props.rows}
      maxLength={props.maxLength}
    />
  );
};

const StyledTextArea = styled.textarea`
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${hexWithOpacity(colors.Dark, 0.25)};
  background-color: ${colors.White};
  color: ${colors.Dark};
  resize: none;
  padding: 8px 12px;
  font:
    400 16px/26px Ubuntu,
    sans-serif;
  outline: none;
  box-shadow: none;
  transition: border-bottom-color 0.2s ease;

  &::placeholder {
    color: ${colors.Dark};
    opacity: 0.5;
  }
  &:focus {
    outline: none;
    border: 1px solid ${colors.Dark};
  }
  &:hover:not(:disabled) {
    border: 1px solid ${colors.Dark};
  }
  &:disabled {
    opacity: 0.5;
  }
`;
