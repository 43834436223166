import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { CustomButton } from '../../../core/components/buttons/CustomButton';
import { useWindowDimensions } from '../../../core/utils/useWindowDimensions';
import { getChainPractices, getIsAddNewPractice } from '../../../store/chainSlice';
import { getInvitationData } from '../../../store/invitationSlice';
import { useMainStore } from '../../../store/mainStore';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';
import { colors } from '../../../styles/colors';
import { Body } from '../../../styles/textStyles';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';
import { CheckoutBillLine } from './CheckoutBillLine';
import { formatMoney } from './CheckoutCalculatorModal';

const roundTwoDecimals = (value: number) => Math.round(value * 100) / 100;

export const CheckoutBillNormal = () => {
  const { isMobileWidth } = useWindowDimensions();
  const [showDetails, setShowDetails] = useState(!isMobileWidth);
  useEffect(() => {
    setShowDetails(!isMobileWidth);
  }, [isMobileWidth]);

  const { paymentType, practiceName, practiceIndex } = useOnboardingPracticeInfo();
  const isAddNewPractice = useMainStore(getIsAddNewPractice);
  const practices = useMainStore(getChainPractices);

  const invitationData = useMainStore(getInvitationData);
  const practicesAmount = paymentType === 'same' ? practices?.length ?? 1 : 1;
  const testMonth = invitationData?.hubspotData.testMonths;
  const hasTestMonth = Boolean(testMonth) && !isAddNewPractice;
  const priceReduction = isAddNewPractice ? undefined : invitationData?.hubspotData.priceReduction;
  const hasPracticePackage =
    invitationData?.hubspotData.brandingPackage && (paymentType === 'same' || practiceIndex === 0) && !isAddNewPractice;
  const practicePackagePrice = Number(invitationData?.hubspotData.brandingPackagePrice ?? 0);
  const originalPracticePackagePrice = 750;
  const hasPackageReduction = practicePackagePrice < originalPracticePackagePrice;

  const pricePerMonth = priceReduction ? roundTwoDecimals(100 - 100 * priceReduction) : 100;
  const pricePerYear = pricePerMonth * 12;
  const originalPrice = hasTestMonth ? 100 : 1200;
  const practiceDisplayPrice = hasTestMonth ? pricePerMonth : pricePerYear;

  const onboardingFeePracticePrice = Number(invitationData?.hubspotData.onboardingFeePrice ?? 0);
  const onboardingFeeTotalPrice =
    paymentType === 'same' ? onboardingFeePracticePrice * practicesAmount : onboardingFeePracticePrice;
  const hasOnboardingFee = invitationData?.hubspotData.onboardingFee && onboardingFeeTotalPrice > 0;

  const totalPracticePrice = paymentType === 'same' ? practiceDisplayPrice * practicesAmount : practiceDisplayPrice;
  let nettoPrice = totalPracticePrice;
  if (hasOnboardingFee) {
    nettoPrice += onboardingFeeTotalPrice;
  }
  if (hasPracticePackage) {
    nettoPrice += practicePackagePrice;
  }
  const tax = roundTwoDecimals(nettoPrice * 0.19);
  const bruttoPrice = roundTwoDecimals(nettoPrice + tax);

  return (
    <>
      <DetailsButton onClick={() => setShowDetails(!showDetails)}>
        <Body>{'Details anzeigen'}</Body>
        <RotatingIconButton name="ArrowDown" $isRotated={showDetails} size={32} />
      </DetailsButton>
      <Details $display={showDetails}>
        {paymentType === 'same' && practices ? (
          practices.map((practice, index) => (
            <CheckoutBillLine
              key={index}
              title={`Physiofit Partnergebühr (Standort ${practice.name})`}
              text={
                !hasTestMonth
                  ? `Die Physiofit Partnergebühr wird jährlich abgerechnet und beträgt ${formatMoney(pricePerMonth)} monatlich.`
                  : `${testMonth}-Monatiger Testzeitraum bis zum ${new Date().toLocaleDateString()}, danach ${formatMoney(100)} pro Monat bei jährlicher Zahlung.`
              }
              discountText={priceReduction ? formatMoney(originalPrice) : undefined}
              value={formatMoney(practiceDisplayPrice)}
              active
            />
          ))
        ) : (
          <CheckoutBillLine
            title="Physiofit Partnergebühr"
            subtitle={`(Standort ${practiceName})`}
            value={formatMoney(practiceDisplayPrice)}
            discountText={priceReduction ? formatMoney(originalPrice) : undefined}
            text={
              !hasTestMonth
                ? `Die Physiofit Partnergebühr wird jährlich abgerechnet und beträgt ${formatMoney(pricePerMonth)} monatlich.`
                : `${testMonth}-Monatiger Testzeitraum bis zum ${new Date().toLocaleDateString()}, danach ${formatMoney(100)} pro Monat bei jährlicher Zahlung.`
            }
            active
          />
        )}

        {hasOnboardingFee && (
          <CheckoutBillLine
            title={`${practicesAmount}x Praxis Onboarding`}
            text="Einmalige Onboarding Gebühr für eine Praxis."
            value={formatMoney(onboardingFeeTotalPrice)}
            active
          />
        )}
        {hasPracticePackage && (
          <CheckoutBillLine
            title="App Branding Paket"
            text="Du zahlst das Branding Paket nur ein einziges Mal! Nach der Einrichtung kannst du deine gebrandete App uneingeschränkt in jeder deiner Praxen nutzen."
            discountText={hasPackageReduction ? formatMoney(originalPracticePackagePrice) : undefined}
            value={formatMoney(practicePackagePrice)}
            active
          />
        )}
      </Details>
      <Divider />
      <div>
        <CheckoutBillLine title="Nettobetrag" value={formatMoney(nettoPrice)} active />
        <CheckoutBillLine title="Mehrwertsteuer" value={formatMoney(tax)} />
        <CheckoutBillLine title="Bruttobetrag" value={formatMoney(bruttoPrice)} />
      </div>
    </>
  );
};

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${colors.Light};
`;

const DetailsButton = styled(CustomButton)`
  width: 100%;
  display: none;

  justify-content: space-between;
  align-items: center;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    display: flex;
  }
`;

const RotatingIconButton = styled(GenericSvg)<{ $isRotated: boolean }>`
  transform: rotate(${(props) => (props.$isRotated ? '180deg' : '0deg')});
  transition: transform 0.3s ease;
`;

const Details = styled.div<{ $display: boolean }>`
  display: ${(props) => (props.$display ? 'flex' : 'none')};
  gap: 20px;
  flex-direction: column;
`;
