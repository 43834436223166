import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { colors } from '../../../../styles/colors';
import { Exercise } from '../../../../types/Content/ExerciseType';

type SingleImagePreviewProps = {
  exercise: Exercise;
  setCurrentExercise: (exercise: Exercise) => void;
};

export const SingleImagePreview = (props: SingleImagePreviewProps) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      <Image
        key={props.exercise.id}
        src={props.exercise.videoAssets?.b?.autoThumbnailUrl ?? props.exercise.imagePreviewAsset?.autoThumbnailUrl}
        onClick={() => props.setCurrentExercise(props.exercise)}
        onLoadedData={() => setLoaded(true)}
      />
      {loaded && (
        <SkeletonWrapper>
          <Skeleton height={60} width={70} />
        </SkeletonWrapper>
      )}
    </>
  );
};

const Image = styled.img`
  width: 70px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    border: 2px solid ${colors.Primary};
  }
`;

const SkeletonWrapper = styled.div`
  width: 100%;
`;
