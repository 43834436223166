import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../../firebaseConfig';
import { Exercise } from '../../../../types/Content/ExerciseType';

export const useExercisesConnect = (exerciseIds: string[]) => {
  const [exercises, setExercises] = useState<Exercise[]>();

  useEffect(() => {
    if (!exerciseIds) return;
    const fetchExercises = onSnapshot(
      query(collection(db, 'content/exercises/exercises'), where('id', 'in', exerciseIds)),
      (snapshot) => {
        const exercisesData = snapshot.docs.map((doc) => doc.data());
        setExercises(exercisesData as Exercise[]);
      },
    );
    return fetchExercises;
  }, [exerciseIds]);

  return { exercises };
};
