import React from 'react';
import styled from 'styled-components';
import { DESKTOP_BREAKPOINT } from '../../styles/breakpoints';

type Props = {
  children: React.ReactNode;
};
export const PhoneMockLayoutContainer = React.forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
  return <Container ref={ref}>{props.children}</Container>;
});

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1050px;
  justify-content: 'flex-start';
  position: relative;
  background-color: 'blue';
  min-height: 800px;
  margin-bottom: 200px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    padding-top: 20px;
    width: 100%;
    max-width: 955px;
    justify-content: center;
    min-height: auto;
  }
`;
