import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { colors } from '../../../../styles/colors';
const SliderContainer = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SliderTrack = styled.div`
  flex-grow: 1;
  height: 7px;
  background-color: ${hexWithOpacity(colors.Medium, 0.2)};
  position: relative;
`;

const SliderFill = styled.div<{ percentage: number }>`
  height: 100%;
  background-color: ${colors.Primary};
  width: ${(props) => props.percentage}%;
`;

const SliderThumb = styled.div<{ percentage: number }>`
  width: 6px;
  height: 20px;
  border-radius: 100px;
  background-color: ${colors.Primary};
  position: absolute;
  top: 50%;
  left: ${(props) => props.percentage}%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

const ValueDisplay = styled.div`
  margin-left: 10px;
  width: 80px;
  height: 44px;
  background-color: ${colors.White};
  border-radius: 10px;
  border: 1px solid ${colors.Medium};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  background-color: ${colors.White};
  border: none;
  border-radius: 10px;
  text-align: center;
  color: ${colors.Medium};
`;
type SliderProps = {
  min?: number;
  max?: number;
  value: number;
  setValue?: (value: number) => void;
  isPercentage?: boolean;
};

export const Slider = ({ min = 0, max = 100, value, setValue, isPercentage }: SliderProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const [rectX, setRectX] = useState(0);
  const [rectWidth, setRectWidth] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setRectX(e.target.getBoundingClientRect().left);
    setRectWidth(e.target.getBoundingClientRect().width);
  };

  const handleMouseMove = (event) => {
    if (isDragging) {
      console.log('clientX: ', event.clientX);
      console.log('rectX: ', rectX);
      const x = event.clientX - rectX;
      const percentage = (x / rectWidth) * 5;
      const newValue = Math.round((percentage / 100) * (max - min) + min);
      console.log(newValue);

      //   const newValue = Math.round((percentage / 100) * (max - min) + min);
      setValue?.(Math.max(min, Math.min(max, newValue)));
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleOnMouseClick = (e) => {
    const x = e.clientX - rectX;
    const percentage = (x / rectWidth) * 100;
    const newValue = Math.round((percentage / 100) * (max - min) + min);
    setValue?.(Math.max(min, Math.min(max, newValue)));
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  const handleInputValueChange = (e) => {
    const newValue = parseInt(e.target.value);
    setValue?.(Math.max(min, Math.min(max, Number.isNaN(newValue) ? 0 : newValue)));
  };

  const percentage = ((value - min) / (max - min)) * 100;

  return (
    <SliderContainer onClick={handleOnMouseClick} onMouseDown={handleMouseDown}>
      <SliderTrack onClick={handleOnMouseClick} onMouseDown={handleMouseDown}>
        <SliderFill percentage={percentage} onMouseDown={handleMouseDown} />
        <SliderThumb percentage={percentage} onMouseDown={handleMouseDown} />
      </SliderTrack>
      <ValueDisplay>
        <StyledInput value={isPercentage ? value + '%' : value} onChange={handleInputValueChange} />
      </ValueDisplay>
    </SliderContainer>
  );
};
