import styled from 'styled-components';
import { getChainPracticeName } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { getUserFirstName } from '../../../store/userSlice';
import { colors } from '../../../styles/colors';
import { Small } from '../../../styles/textStyles';

type Props = {
  smsName: string;
  practiceName?: string;
  practiceId: string;
};

export const SMSPhoneMockContent = ({ smsName, practiceName, practiceId }: Props) => {
  const ownerName = useMainStore(getUserFirstName);
  const chainPracticeName = useMainStore((state) => getChainPracticeName(state, practiceId));
  const practiceFullName = practiceName ?? chainPracticeName;
  const practiceNameText = smsName && smsName !== '' ? smsName : '"Praxisname"';
  return (
    <Container>
      <HeadlineName>{practiceNameText}</HeadlineName>
      <SMSContainer>
        <SMSText>
          {`Hallo Max Mustermann, anbei der Link für dein persönliches Programm. Bitte installiere die PHYSIOFIT App, und starte mit deinem Programm. Dein Downloadlink: "..." Viele Grüße ${ownerName} (${practiceFullName})`}
        </SMSText>
      </SMSContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
`;

const HeadlineName = styled(Small)`
  color: ${colors.Dark};
  text-align: center;
  margin: 0;
`;

const SMSContainer = styled.div`
  display: flex;
  background-color: ${colors.Light};
  border-radius: 20px;
  padding: 14px 12px;
  margin: 30px 20px 0px 5px;
`;

const SMSText = styled(Small)`
  color: ${colors.Dark};
  text-align: left;
`;
