import { StateCreator } from 'zustand';
import { InvitationData } from '../types/WebUser/InvitationDataType';

export type InvitationState = {
  invitationData?: InvitationData;
  setInvitationData: (invitationData?: InvitationData) => void;
  resetInvitationState: () => void;
};

const initialState = {
  invitationData: undefined,
};

export const createInvitationSlice: StateCreator<InvitationState> = (set) => ({
  ...initialState,
  setInvitationData: (invitationData?: InvitationData) => set({ invitationData: invitationData }),
  resetInvitationState: () => set(initialState),
});

export const getInvitationData = (state: InvitationState) => state.invitationData;

export const getInvitationId = (state: InvitationState) => getInvitationData(state)?.id;
export const getInvitationFirstName = (state: InvitationState) =>
  getInvitationData(state)?.hubspotData.privateInfo.firstName;
export const getInvitationLastName = (state: InvitationState) =>
  getInvitationData(state)?.hubspotData.privateInfo.lastName;
export const getInvitationEmail = (state: InvitationState) => getInvitationData(state)?.hubspotData.privateInfo.email;
export const getInvitationCustomSubscriptionId = (state: InvitationState) =>
  getInvitationData(state)?.hubspotData?.subscription?.subscriptionId;
export const getInvitationTypeIsMigration = (state: InvitationState) => getInvitationData(state)?.type === 'migration';
export const getInvitationLoaded = (state: InvitationState) => !!getInvitationData(state);
