import {} from 'prettier';
import { ComponentProps, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain';
import {
  getChainBoughtBrandingPackage,
  getOnboardingFlags,
  getPracticesBrandingCompleted,
} from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { H1, H4 } from '../../../styles/textStyles';
import { AppBrandingStartButtons } from '../components/AppBrandingStartButtons';
import { ComingSoonCard } from '../components/ComingSoonCard';
import { DHLTrackingButtons } from '../components/DHLTrackingButtons';
import { MainScreenContainer } from '../components/MainScreenComponent';
import { OverviewPracticeLinks } from '../components/OverviewPracticeLinks';
import { StepCard } from '../components/StepCard';
import { useGetAddTherapistsCardsData } from '../utils/useGetAddTherapistsCardsData';

export const OverviewScreen = () => {
  const navigate = useNavigate();
  const onboardingFlags = useMainStore(getOnboardingFlags);
  const clickedSocialMediaPage = onboardingFlags?.clickedSocialMediaPage;
  const clickedDownloadWebappPage = onboardingFlags?.clickedDownloadWebappPage;
  const clickedShowIntercom = onboardingFlags?.clickedShowIntercom;
  const addTherapistsElements = useGetAddTherapistsCardsData();
  const appBrandingOnboardingCompleted = useMainStore(getPracticesBrandingCompleted);
  const boughtBranding = useMainStore(getChainBoughtBrandingPackage);
  const [showAll, setShowAll] = useState(false);

  const { show } = useIntercom();
  const openSocialMediaPage = () => {
    window.open('https://www.physiofit.app/downloads', '_blank');
    setTimeout(() => {
      setChainOnboardingFlag('clickedSocialMediaPage');
    }, 1000);
  };

  const openDownloadWebAppPage = () => {
    navigate('../webapp-installation-instruction');
    setTimeout(() => {
      setChainOnboardingFlag('clickedDownloadWebappPage');
    }, 1000);
  };

  const toggleIntercom = () => {
    show();
    setTimeout(() => {
      setChainOnboardingFlag('clickedShowIntercom');
    }, 1000);
  };

  const addLocation = () => {
    navigate('/new-practice/practices-names');
  };

  const elements: Array<ComponentProps<typeof StepCard>> = [
    {
      active: true,
      title: 'Onboarding abgeschlossen',
      content: 'Super gemacht! Alle deine angegebenen Daten sind bei uns eingegangen.',
      mainButton: {
        title: 'Standort hinzufügen',
        onClick: addLocation,
        label: 'Du kannst jederzeit weitere Physiofit Standorte hinzufügen.',
      },
      variant: 'button',
    },
    {
      active: true,
      title: 'Starter Paket versendet',
      content:
        'Mit dem Abschluss deines Onboardings erhält jeder deiner Standorte ein Starter Paket. Die Materialien im Starter Paket unterstützen dich und dein Team, Physiofit optimal in den Praxisalltag zu integrieren und deine Patientinnen und Patienten von den Vorteilen der Physiofit App zu überzeugen.',
      variant: 'custom',
      bottomComponent: <DHLTrackingButtons />,
    },
    ...(boughtBranding
      ? [
          {
            active: appBrandingOnboardingCompleted,
            title: 'App Branding',
            content: 'Passe jetzt das Design und die Inhalte der Physiofit App für deine Patienten an.',
            variant: 'custom' as 'custom',
            bottomComponent: <AppBrandingStartButtons completed={appBrandingOnboardingCompleted} />,
          },
        ]
      : []),
    {
      title: 'Dein Physiofit Social Media Paket für deine Praxis Community',
      active: clickedSocialMediaPage,
      content:
        'Hier ist dein exklusives Social Media Partnerpaket mit Vorlagen für Facebook und Instagram. Zeig deinen Patientinnen und Patienten dass deine Praxis immer auf die neueste Technologie setzt um Therapieergebnisse weiter zu verbessern. ',
      mainButton: {
        title: 'Dein Physiofit Social Media Paket',
        onClick: openSocialMediaPage,
      },
      variant: 'button',
    },
    {
      title: 'Euer Direktzugang zur Physiofit Therapeuten App',
      active: clickedDownloadWebappPage,
      content:
        'Speichert die Physiofit Therapeuten App auf euren mobilen Geräten in eurer Praxis. Wie das geht? Ganz einfach – folge einfach unserer Anleitung.',
      mainButton: {
        title: 'Zur Anleitung',
        onClick: openDownloadWebAppPage,
      },
      variant: 'button',
    },
    {
      title: 'Dein persönlicher Support und Hilfebereich ist nur einen Klick entfernt!',
      active: clickedShowIntercom,
      content:
        'Klicke einfach auf den Button am rechten unteren Bildschirmrand, um direkt Kontakt zu unserem Physiofit-Team aufzunehmen. Wir sind da, um deine Fragen zu beantworten und dich bei deinem Start mit Physiofit bestmöglich zu unterstützen. Hier findest du auch häufig gestellte Fragen anderer Nutzer und Hilfe-Artikel zu allen relevanten Themen rund um Physiofit.',
      mainButton: {
        title: 'Ok, verstanden',
        onClick: toggleIntercom,
      },
      variant: 'button',
    },
    ...addTherapistsElements,
  ];

  const showAllDone = () => {
    setShowAll(!showAll);
  };

  const finishedSteps = elements.filter((element) => element.active);
  const openSteps = elements.filter((element) => !element.active);

  return (
    <MainScreenContainer>
      <QRContainer>
        <StyledH1>Deine Praxis Links</StyledH1>
        <OverviewPracticeLinks />
      </QRContainer>
      <Container>
        <StyledH1>Dein Physiofit Onboarding</StyledH1>
        {finishedSteps.length > 0 && <StyledH4>Abgeschlossene Schritte</StyledH4>}
        {finishedSteps
          .reverse()
          .slice(0, showAll ? 10000 : 2)
          .map((element, index) => (
            <StepCard key={index + element.title} {...element} />
          ))}
        {finishedSteps.length > 2 && (
          <ShowMoreButton>
            <TextButton text={showAll ? 'Weniger anzeigen' : 'Alle anzeigen'} onClick={showAllDone} />
          </ShowMoreButton>
        )}

        <StyledH4>Deine nächsten Schritte</StyledH4>
        {openSteps.map((element, index) => (
          <StepCard key={index + element.title} {...element} />
        ))}
        <ComingSoonCard />
      </Container>
    </MainScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 200px;
`;

const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

const StyledH1 = styled(H1)`
  margin-bottom: 40px;
`;

const StyledH4 = styled(H4)`
  color: ${colors.Medium};
`;

const ShowMoreButton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;
