import { doc, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ProgressButton } from '../../../core/components/buttons/ProgressButton';
import { ContentContainer } from '../../../core/components/container/ContentContainer';
import { usePracticeContactConnect } from '../../../core/utils/connectFirestore/usePracticeContactConnect';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';
import { PracticeContactForm } from '../../onboarding/components/PracticeContactForm';
import { useHandleInformationForm } from '../../onboarding/utils/useHandleInformationForm';

type FormFields = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export const PracticeSettingsContactForm = () => {
  const { practiceId } = useParams();
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { practiceContact, loadingPracticeContact } = usePracticeContactConnect(practiceId);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState<FormFields>({
    firstName: practiceContact?.firstName || '',
    lastName: practiceContact?.lastName || '',
    email: practiceContact?.email || '',
    phoneNumber: practiceContact?.phoneNumber || '',
  });

  useEffect(() => {
    setFields({
      firstName: practiceContact?.firstName || '',
      lastName: practiceContact?.lastName || '',
      email: practiceContact?.email || '',
      phoneNumber: practiceContact?.phoneNumber || '',
    });
  }, [practiceContact]);

  const missingData = !fields.firstName || !fields.lastName || !fields.email || !fields.phoneNumber;
  const noChanges =
    fields.firstName === practiceContact?.firstName &&
    fields.lastName === practiceContact?.lastName &&
    fields.email === practiceContact?.email &&
    fields.phoneNumber === practiceContact?.phoneNumber;
  const buttonDisabled = missingData || noChanges;

  const { handleInput } = useHandleInformationForm(fields, setFields);

  const handleSave = async () => {
    setLoading(true);
    try {
      await updateDoc(doc(db, 'practices/' + practiceId + '/documents/practiceContact'), { ...fields });
      setEventIndicator('success', 'Kontaktdaten gespeichert');
    } catch (error) {
      console.error('Error writing document: ', error);
      setEventIndicator('error', 'Fehler beim Speichern der Kontaktdaten');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContentContainer>
      <StyledH3>Ansprechpartner</StyledH3>
      <PracticeContactForm fields={fields} handleInput={handleInput} loadingData={loadingPracticeContact} />
      <ButtonContainer>
        <ProgressButton onClick={handleSave} loading={loading} disabled={buttonDisabled} text={'Speichern'} />
      </ButtonContainer>
    </ContentContainer>
  );
};

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
