import { getFunctions, httpsCallable } from 'firebase/functions';

export const createAdminUser = async (invitationId: string) => {
  const functions = getFunctions();
  const call = httpsCallable(functions, 'createAdminUser');
  const callableReturnMessage = await call({
    invitationId,
  });
  console.log(callableReturnMessage);
};
