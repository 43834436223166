import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IconButton } from '../../../../core/components/buttons/IconButton';
import { colors } from '../../../../styles/colors';
import { Body, H3 } from '../../../../styles/textStyles';

type WheelPickerProps = {
  options?: { id: string; label: string; value: string }[];
  title: string;
  handleSelect?: (id: string) => void;
};

export const WheelPicker = ({ options, title, handleSelect }: WheelPickerProps) => {
  if (!options) {
    return null;
  }

  const [selectedOption, setSelectedOption] = useState(options?.[0]);
  const [visibleOptions, setVisibleOptions] = useState<{ id: string; label: string; value: string }[]>([]);

  // Function to update visible options based on selected option
  useEffect(() => {
    if (!options) return;

    const currentIndex = options.findIndex((option) => option.id === selectedOption?.id);

    const previousIndex = (currentIndex - 1 + options.length) % options.length;
    const nextIndex = (currentIndex + 1) % options.length;

    const updatedVisibleOptions = [options[previousIndex], options[currentIndex], options[nextIndex]];

    setVisibleOptions(updatedVisibleOptions);
  }, [selectedOption, options]);

  const handleSelectPreviousOption = () => {
    if (!options) return;

    const currentIndex = options.findIndex((option) => option.id === selectedOption?.id);
    const previousIndex = (currentIndex - 1 + options.length) % options.length;
    setSelectedOption(options[previousIndex]);
  };

  const handleSelectNextOption = () => {
    if (!options) return;

    const currentIndex = options.findIndex((option) => option.id === selectedOption?.id);
    const nextIndex = (currentIndex + 1) % options.length;
    setSelectedOption(options[nextIndex]);
  };

  useEffect(() => {
    if (selectedOption) {
      handleSelect?.(selectedOption.id);
    }
  }, [selectedOption, handleSelect]);

  const { t } = useTranslation();

  return (
    <Container>
      <Body>{title}</Body>
      <IconButton iconName="ArrowUp" onClick={handleSelectPreviousOption} />
      {visibleOptions.map((option, index) => {
        return (
          <Option key={index} onClick={() => setSelectedOption(option)} selected={selectedOption?.id === option?.id}>
            {t(option?.label)}
          </Option>
        );
      })}
      <IconButton iconName="ArrowDown" onClick={handleSelectNextOption} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 165px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Option = styled(H3)<{ selected: boolean }>`
  width: 100%;
  padding: 10px;
  border-radius: 100px;
  background-color: ${({ selected }) => (selected ? colors.Primary : 'transparent')};
  color: ${({ selected }) => (selected ? colors.White : colors.Dark)};
  opacity: ${({ selected }) => (selected ? 1 : 0.3)};
  text-align: center;
  cursor: pointer;
`;
