import styled from 'styled-components';
import { MainButton } from '../../../../core/components/buttons/MainButton';
import { colors } from '../../../../styles/colors';
import { Body, H3 } from '../../../../styles/textStyles';

type Props = {
  children: React.ReactNode;
  title: string;
  description: string;
  nextButton?: {
    text: string;
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
  };
};

export const SettingsBrandingComponentsWrapper = (props: Props) => {
  return (
    <Container>
      <div>
        <StyledH3>{props.title}</StyledH3>
        <StyledBody>{props.description}</StyledBody>
      </div>
      {props.children}
      {props.nextButton && (
        <ButtonContainer>
          <MainButton
            text={props.nextButton?.text ?? 'Speichern'}
            onClick={props.nextButton?.onClick}
            disabled={props.nextButton?.disabled}
            loading={props.nextButton?.loading}
          />
        </ButtonContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledH3 = styled(H3)`
  padding-top: 20px;
  padding-bottom: 15px;
  color: ${colors.Medium};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  justify-content: flex-end;
  align-items: center;
`;

const StyledBody = styled(Body)`
  opacity: 0.6;
`;
