import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { GenericSvg, SVGName } from '../../assets/svgs/GenericSVG';
import { colors } from '../../styles/colors';
import { H4 } from '../../styles/textStyles';
import { CustomButton } from './buttons/CustomButton';
import { IconButton } from './buttons/IconButton';

type Props = {
  title: string;
  children: React.ReactNode;
  onIconClick?: () => void;
  iconUrl?: string;
  iconName?: SVGName;
};

export const AccordionComponent: React.FC<Props> = ({ title, children, onIconClick, iconUrl, iconName }) => {
  const [active, setActive] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (contentRef.current) {
      setHeight(active ? contentRef.current.scrollHeight : 0);
    }
  }, [active]);

  const handleToggle = () => {
    setActive(!active);
  };

  const handleIconClick = (event: React.MouseEvent) => {
    if (onIconClick) {
      event.stopPropagation();
      onIconClick();
    }
  };

  return (
    <AccordionItem>
      <AccordionContainer>
        {onIconClick &&
          (iconUrl ? (
            <CustomButton onClick={handleIconClick}>
              <IconImage src={iconUrl} alt={'avatar-icon'} />
            </CustomButton>
          ) : (
            <IconButton onClick={handleIconClick} iconName="AddProfilePicture" stroke={colors.Primary} size={48} />
          ))}
        {iconName && <GenericSvg onClick={handleIconClick} name={iconName} size={48} />}
        <AccordionButton onClick={handleToggle}>
          <StyledH4>{title}</StyledH4>
          <RotatingIconButton name="ArrowDown" $isRotated={active} size={32} primaryColor={colors.Medium} />
        </AccordionButton>
      </AccordionContainer>
      <ContentContainer $active={active} style={{ height }} ref={contentRef}>
        {children}
      </ContentContainer>
    </AccordionItem>
  );
};

const IconImage = styled.img`
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
  border-radius: 50%;
  object-fit: cover;
`;

const AccordionItem = styled.div`
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
`;

const AccordionButton = styled(CustomButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const AccordionContainer = styled.div`
  width: 100%;
  padding: 10px 0px;
  display: flex;
  gap: 20px;
  align-items: center;
`;

const RotatingIconButton = styled(GenericSvg)<{ $isRotated: boolean }>`
  transform: rotate(${(props) => (props.$isRotated ? '180deg' : '0deg')});
  transition: transform 0.3s ease;
`;

const ContentContainer = styled.div<{ $active: boolean }>`
  overflow: hidden;
  transition: height 0.3s ease-in-out;
`;

const StyledH4 = styled(H4)`
  color: ${colors.Medium};
`;
