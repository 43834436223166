import styled from 'styled-components';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { getAppBrandingClaim } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { getRandomPracticeClaim } from '../utils/getRandomPracticeClaim';

export const AppBrandingClaimInput = () => {
  const claim = useMainStore(getAppBrandingClaim);
  const setClaim = useMainStore((state) => state.setAppBrandingClaim);

  const handleClaimChange = (value: string) => {
    const newClaim = value.slice(0, 300);
    setClaim(newClaim);
  };

  const generateClaim = () => {
    const newClaim = getRandomPracticeClaim();
    setClaim(newClaim);
  };

  return (
    <Container>
      <SingleTextInput
        label="Slogan"
        value={claim ?? ''}
        onChange={handleClaimChange}
        placeholder="Dein Praxisversprechen"
      />
      <AddButtonContainer>
        <TextButton
          text={'Vorschlag erstellen'}
          icon={{ name: 'Magic', size: 24, color: colors.Primary }}
          onClick={generateClaim}
        />
      </AddButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const AddButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
