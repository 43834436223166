import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { getChainId, getChainName } from '../store/chainSlice';
import { getInvitationFirstName } from '../store/invitationSlice';
import { useMainStore } from '../store/mainStore';
import { getUserFirstName, getUserId } from '../store/userSlice';

export const useIntercomInit = () => {
  const firstName = useMainStore(getUserFirstName);
  const invitationFirst = useMainStore(getInvitationFirstName);
  const chainName = useMainStore(getChainName);
  const chainId = useMainStore(getChainId);
  const userId = useMainStore(getUserId);
  const { update } = useIntercom();

  useEffect(() => {
    update({
      name: firstName || invitationFirst || 'Partner-Nutzer',
      languageOverride: 'German',
      // userId: userId ?? 'unknown',
      company: {
        companyId: chainId ?? 'unknown',
        name: chainName ?? 'unknown',
      },
      customAttributes: {
        webAppVersion: import.meta.env.VITE_VERSION,
        chainName: chainName ?? 'unknown',
        chainId: chainId ?? 'unknown',
      },
    });
  }, [update, firstName, invitationFirst, chainName, chainId, userId]);
};
