import styled from 'styled-components';
import { getAppBrandingOpeningHours } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';
import { AppBrandingOpeningHoursDay } from './AppBrandingOpeningHoursDay';

export const AppBrandingOpeningHours = () => {
  const openingHours = useMainStore(getAppBrandingOpeningHours);
  const setOpeningHours = useMainStore((state) => state.setAppBrandingOpeningHours);
  const tageArray = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];

  const handleValueChange = (value: string, index: number) => {
    console.log('openingHours', openingHours);
    const newOpeningHours = [...(openingHours ?? [])];
    newOpeningHours[index] = { dayNumber: index, text: value };
    setOpeningHours(newOpeningHours);
  };
  return (
    <Container>
      {tageArray.map((tag, index) => (
        <AppBrandingOpeningHoursDay
          key={tag}
          value={openingHours?.[index]?.text ?? '08:00-18:00'}
          setValue={(value) => handleValueChange(value, index)}
          label={tag}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
