import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { LoadingSpinner } from '../../../core/components/loading/LoadingSpinner.tsx';
import { InfoText } from '../../../core/components/text/InfoText.tsx';
import { setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain.ts';
import { db } from '../../../firebaseConfig.ts';
import { colors } from '../../../styles/colors.ts';
import { PracticeStripeData } from '../../../types/Practices/PracticeStripeDataType.ts';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo.ts';

export const PaymentStatusScreen = () => {
  const { t } = useTranslation();
  const [loadingNext, setLoadingNext] = useState(false);
  const [practiceStripeData, setPracticeStripeData] = useState<PracticeStripeData>();
  const { practiceId, practiceName, nextPracticeId, allPracticeIds, isAddNewPractice } = useOnboardingPracticeInfo();

  useEffect(() => {
    if (!practiceId) return;
    const sub = onSnapshot(doc(db, 'practices/' + practiceId + '/documents/practiceStripeData'), (doc) => {
      setPracticeStripeData(doc.data() as PracticeStripeData);
    });
    return sub;
  }, [practiceId]);

  const navigation = useNavigate();

  const handleNext = async () => {
    setLoadingNext(true);
    try {
      if (nextPracticeId) {
        navigation(`/onboarding/${nextPracticeId}/practice-data-info/`);
      } else {
        const promises: Array<Promise<void>> = [];
        promises.push(setChainOnboardingFlag('practicesCompleted', 'practicesCompleted'));
        allPracticeIds?.forEach((id) => promises.push(setChainOnboardingFlag(`practices.${id}.completed`)));
        await Promise.all(promises);
        if (isAddNewPractice) {
          navigation('/');
        } else {
          navigation('/onboarding/sms-names');
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingNext(false);
    }
  };

  const loading = !practiceStripeData || practiceStripeData?.latestSetupIntentEvent === 'setup_intent.created';

  if (!practiceId) {
    return <Navigate to="/practices-names" />;
  }

  const handleBack = () => {
    navigation('../practice-starter');
  };
  return (
    <OnboardingScreenContainer
      handleBack={handleBack}
      nextButton={
        !loading
          ? {
              loading: loadingNext,
              onClick: handleNext,
            }
          : undefined
      }
    >
      {loading ? (
        <LoadingSpinner color={colors.Primary} />
      ) : (
        <InfoText
          preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName })}
          headline={'Zahlung abgeschlossen'}
          text={[
            'Glückwunsch! Deine Zahlung ist abgeschlossen und wir gratulieren zu deiner neuen Physiofit Partnerschaft. Wir freuen uns auf die Zusammenarbeit mit dir und deinem Team.',
          ]}
        />
      )}
    </OnboardingScreenContainer>
  );
};
