import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { GenericSvg } from '../../../../assets/svgs/GenericSVG';
import { MainButton } from '../../../../core/components/buttons/MainButton';
import { LoadingSpinner } from '../../../../core/components/loading/LoadingSpinner';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { NavigationHeader } from '../../../../navigation/components/NavigationHeader';
import { colors } from '../../../../styles/colors';
import { Body, ButtonText, H1, H3 } from '../../../../styles/textStyles';
import { VideoPreviewComponent } from '../components/VideoPreviewComponent';
import { WheelPicker } from '../components/WheelPicker';
import { useHandleEasyPlanner } from '../utils/useHandleEasyPlanner';
import { Program } from '../utils/useProgramsConnect';

const therapyPhaseOptions = [
  { id: '1', label: 'ADMIN-SALES-THERAPY-PHASE-OPTION-ONE', value: 'akut' },
  { id: '2', label: 'ADMIN-SALES-THERAPY-PHASE-OPTION-TWO', value: 'chronisch' },
  { id: '3', label: 'ADMIN-SALES-THERAPY-PHASE-OPTION-THREE', value: 'erhaltung' },
  { id: '4', label: 'ADMIN-SALES-THERAPY-PHASE-OPTION-FOUR', value: 'aufbau' },
  { id: '5', label: 'ADMIN-SALES-THERAPY-PHASE-OPTION-FIVE', value: 'praevention' },
  { id: '6', label: 'ADMIN-SALES-THERAPY-PHASE-OPTION-SIX', value: 'rehabilitation' },
];

const difficultyOptions = [
  { id: '1', label: 'ADMIN-SALES-DIFFICULTY-OPTIONS-LABEL-ONE', value: 'leicht' },
  { id: '2', label: 'ADMIN-SALES-DIFFICULTY-OPTIONS-LABEL-TWO', value: 'mittel' },
  { id: '3', label: 'ADMIN-SALES-DIFFICULTY-OPTIONS-LABEL-THREE', value: 'schwer' },
];

export const AdminSalesEasyPlanConfiguratorScreen = () => {
  const { programs, handleSelectProgram, selectedProgram } = useHandleEasyPlanner();
  const programsOptions =
    programs?.map((program) => {
      return { id: program.id, label: program.title, value: program.bodyRegion };
    }) || [];

  const { t } = useTranslation();

  const navigate = useNavigate();

  const close = () => {
    navigate('/admin/sales/tools');
  };

  const handleNext = () => {
    navigate('/sales-tools/benefits');
  };

  return (
    <Container>
      {selectedProgram ? (
        <ContentContainer>
          <ConfigurationContainer>
            <TitleContainer>
              <H1>{t('ADMIN-SALES-EASY-PLAN-CONFIGURATOR-HEADLINE')}</H1>
              <StyledBody>{t('ADMIN-SALES-EASY-PLAN-CONFIGURATOR-SUBTITLE')}</StyledBody>
            </TitleContainer>
            <PlanPickerContainer>
              <WheelPicker
                title={t('ADMIN-SALES-WHEEL-PICKER-TITLE-FIRST')}
                options={programsOptions}
                handleSelect={handleSelectProgram}
              />
              <WheelPicker title={t('ADMIN-SALES-WHEEL-PICKER-TITLE-SECOND')} options={therapyPhaseOptions} />
              <WheelPicker title={t('ADMIN-SALES-WHEEL-PICKER-TITLE-THIRD')} options={difficultyOptions} />
            </PlanPickerContainer>
          </ConfigurationContainer>
          <PreviewContainer>
            <VideoPreviewComponent program={selectedProgram as Program} />
            <DetailsContainer>
              <ProgramGoalsContainer>
                <H3>{t('ADMIN-SALES-PROGRAM-GOALS-TITLE')}</H3>
                <Goals>
                  <SingleGoalContainer>
                    <GenericSvg name="CheckmarkWithBackground" primaryColor={colors.Primary} size={24} />
                    <StyledBody>{t('ADMIN-SALES-PROGRAM-GOAL-ONE')}</StyledBody>
                  </SingleGoalContainer>
                  <SingleGoalContainer>
                    <GenericSvg name="CheckmarkWithBackground" primaryColor={colors.Primary} size={24} />
                    <StyledBody>{t('ADMIN-SALES-PROGRAM-GOAL-TWO')}</StyledBody>
                  </SingleGoalContainer>
                  <SingleGoalContainer>
                    <GenericSvg name="CheckmarkWithBackground" primaryColor={colors.Primary} size={24} />
                    <StyledBody>{t('ADMIN-SALES-PROGRAM-GOAL-THREE')}</StyledBody>
                  </SingleGoalContainer>
                </Goals>
              </ProgramGoalsContainer>
              <ProgramGoalsContainer>
                <H3>{t('ADMIN-SALES-PROGRAM-STRUCTURE-TITLE')}</H3>
                <ProgramSettingsContainer>
                  <SingleSettingContainer>
                    <GenericSvg name="Clock" primaryColor={colors.Medium} size={24} />
                    <StyledButtonText>{t('ADMIN-SALES-PROGRAM-STRUCTURE-TIME-DURATION')}</StyledButtonText>
                  </SingleSettingContainer>
                  <SingleSettingContainer>
                    <GenericSvg name="BarChart" primaryColor={colors.Medium} size={24} />
                    <StyledButtonText>{t('ADMIN-SALES-PROGRAM-STRUCTURE-FREQUENCY')}</StyledButtonText>
                  </SingleSettingContainer>
                  <SingleSettingContainer>
                    <GenericSvg name="Calendar" primaryColor={colors.Medium} size={24} />
                    <StyledButtonText>{t('ADMIN-SALES-PROGRAM-STRUCTURE-PROGRAM-DURATION')}</StyledButtonText>
                  </SingleSettingContainer>
                </ProgramSettingsContainer>
              </ProgramGoalsContainer>
            </DetailsContainer>
          </PreviewContainer>
        </ContentContainer>
      ) : (
        <LoadingSpinnerWrapper>
          <LoadingSpinner color={colors.Primary} size={96} />
        </LoadingSpinnerWrapper>
      )}
      <StyledMainButton text="Weiter" onClick={handleNext} />
      <NavigationHeader onCloseClick={close} />
    </Container>
  );
};

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ProgramSettingsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const SingleSettingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const StyledButtonText = styled(ButtonText)`
  color: ${colors.Medium};
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 40%;
`;

const ProgramGoalsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const Goals = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SingleGoalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
`;

const ConfigurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 40%;
  padding: 40px 20px;
  justify-content: space-between;
  align-items: stretch;
`;

const PlanPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 40px 0;
`;

const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 40px;
  overflow: hidden;
`;

const StyledMainButton = styled(MainButton)`
  position: absolute;
  bottom: 20px;
  right: 40px;
`;

const ContentContainer = styled.div`
  padding: 40px;
  max-width: 1920px;
  width: 100%;
  height: 100%;
  gap: 60px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  background-color: ${hexWithOpacity(colors.Medium, 0.1)};
  border-radius: 20px;
`;
