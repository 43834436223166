import { useState } from 'react';
import { getAppBrandingColors } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { AppBrandingColorPicker } from '../../../appBranding/components/AppBrandingColorPicker';
import { useAppBrandingPracticeInfo } from '../../../appBranding/utils/useAppBrandingPracticeInfo';
import { SettingsBrandingComponentsWrapper } from './SettingsBrandingComponentsWrapper';

export const SettingsBrandingColor = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { uploadAppBrandingData, uploading } = useAppBrandingPracticeInfo();
  const zustandBrandingColor = useMainStore(getAppBrandingColors)?.Primary;

  const [initialAppBrandingColor, setInitialBrandingColor] = useState(zustandBrandingColor);
  const nextButtonDisabled = zustandBrandingColor === initialAppBrandingColor;
  const handleSave = async () => {
    setInitialBrandingColor(zustandBrandingColor);
    await uploadAppBrandingData();
    setEventIndicator('success', 'Änderungen gespeichert');
  };
  return (
    <SettingsBrandingComponentsWrapper
      title="Farbe für deine App"
      description="Deine Corporate Farbe verleiht der App den letzten Schliff. Wähle sie hier aus, um das Design deiner Praxis optimal widerzuspiegeln."
      nextButton={{
        text: 'Speichern',
        onClick: handleSave,
        disabled: nextButtonDisabled,
        loading: uploading,
      }}
    >
      <AppBrandingColorPicker />
    </SettingsBrandingComponentsWrapper>
  );
};
