import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MainButton } from '../../../../core/components/buttons/MainButton';
import { H1 } from '../../../../styles/textStyles';

export const AdminSalesToolsScreen = () => {
  const navigate = useNavigate();

  const openCalculator = () => {
    navigate('/sales-tools/calculator');
  };

  const openBenefits = () => {
    navigate('/sales-tools/benefits');
  };

  const openEasyPlanConfigurator = () => {
    navigate('/sales-tools/easy-plan-configurator');
  };

  return (
    <Container>
      <StyledH1>Sales Tools</StyledH1>
      <MainButton text={'open calculator screen'} onClick={openCalculator} />
      <MainButton text={'open Benefits screen'} onClick={openBenefits} />
      <MainButton text={'open Easy Plan Configurator screen'} onClick={openEasyPlanConfigurator} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const StyledH1 = styled(H1)``;
