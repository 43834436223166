import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InfoText } from '../../../core/components/text/InfoText';
import { getAppBrandingLogoUrl } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';
import { AppBrandingLogoUpload } from '../components/AppBrandingLogoUpload';
import { AppBrandingScreenContainer } from '../components/AppBrandingScreenContainer';
import { useAppBrandingPracticeInfo } from '../utils/useAppBrandingPracticeInfo';

export const AppBrandingLogoScreen = () => {
  const { t } = useTranslation();
  const { practiceName } = useAppBrandingPracticeInfo();
  const initialAppBrandingColor = useMainStore(getAppBrandingLogoUrl);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleNext = () => {
    navigate('../address');
  };

  const handleBack = () => {
    navigate('../color');
  };

  const currentStep = initialAppBrandingColor ? 1 : 0;

  return (
    <AppBrandingScreenContainer
      handleBack={handleBack}
      nextButton={{
        text: 'Weiter',
        onClick: handleNext,
        numberOfSteps: 1,
        currentStep: currentStep,
        loading: loading,
      }}
    >
      <div>
        <StyledInfoText
          headline={'Praxis Logo'}
          text={[
            'Ziehe dein Logo einfach per Drag-and-Drop in das Feld oder klicke, um die Datei auszuwählen. So gibst du deiner Physiofit App sofort den Look deiner Praxis!',
          ]}
          preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName: practiceName })}
        />
        <AppBrandingLogoUpload setLoading={setLoading} />
      </div>
    </AppBrandingScreenContainer>
  );
};
const StyledInfoText = styled(InfoText)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
