import { useState } from 'react';
import styled from 'styled-components';
import { LoadingSpinner } from '../../../../core/components/loading/LoadingSpinner';
import { colors } from '../../../../styles/colors';
import { AppBrandingVideoUpload } from '../../../appBranding/components/AppBrandingVideoUpload';
import { SettingsBrandingComponentsWrapper } from './SettingsBrandingComponentsWrapper';

export const SettingsBrandingVideo = () => {
  const [loading, setLoading] = useState(false);
  return (
    <SettingsBrandingComponentsWrapper
      title="Video"
      description="Lade ein Video hoch, das deine Praxis und dein Team repräsentiert."
    >
      {loading ? (
        <LoadingContainer>
          <LoadingSpinner color={colors.Primary} />
        </LoadingContainer>
      ) : (
        <AppBrandingVideoUpload setLoading={setLoading} />
      )}
    </SettingsBrandingComponentsWrapper>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
`;
