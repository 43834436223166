import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InfoText } from '../../../core/components/text/InfoText';
import { getAppBrandingServices } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';
import { AppBrandingScreenContainer } from '../components/AppBrandingScreenContainer';
import { AppBrandingServicesInput } from '../components/AppBrandingServicesInput';
import { useAppBrandingPracticeInfo } from '../utils/useAppBrandingPracticeInfo';

export const AppBrandingServicesScreen = () => {
  const { t } = useTranslation();
  const { practiceName } = useAppBrandingPracticeInfo();
  const services = useMainStore(getAppBrandingServices);
  const setServices = useMainStore((state) => state.setAppBrandingServices);

  const navigate = useNavigate();

  const handleNext = () => {
    navigate('../images');
  };

  const handleBack = () => {
    navigate('../claim');
  };

  const handleSkip = () => {
    setServices([]);
    navigate('../images');
  };

  const { totalSteps, currentSteps } = services?.reduce(
    (acc, e) => {
      acc.totalSteps += e.services.length + 1;
      acc.currentSteps += e.services.filter((e) => e !== '').length;
      acc.currentSteps += e.title !== '' ? 1 : 0;
      return acc;
    },
    { totalSteps: 0, currentSteps: 0 },
  ) ?? { totalSteps: 1, currentSteps: 0 };

  return (
    <AppBrandingScreenContainer
      handleBack={handleBack}
      nextButton={{
        text: 'Weiter',
        onClick: handleNext,
        numberOfSteps: totalSteps,
        currentStep: currentSteps,
      }}
      textButton={{
        text: 'Schritt überspringen',
        onClick: handleSkip,
      }}
    >
      <div>
        <StyledInfoText
          headline={'Leistungen'}
          text={[
            'Gib deine Therapie- und Behandlungsangebote an, damit sich deine Patienten ein umfassendes Bild deiner Leistungen machen können.',
          ]}
          preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName: practiceName })}
        />
        <AppBrandingServicesInput />
      </div>
    </AppBrandingScreenContainer>
  );
};
const StyledInfoText = styled(InfoText)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
