import { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { addDoc, collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore';
import { MainButton } from '../../../../core/components/buttons/MainButton';
import { SingleTextInput } from '../../../../core/components/textInput/SingleTextInput';
import { db } from '../../../../firebaseConfig';
import { H2, H3 } from '../../../../styles/textStyles';

type Position = {
  x: number;
  y: number;
};

const blinkingBackground = keyframes`
  0% { background-color: transparent; }
  33% { background-color: rgba(0, 115, 230, 0.2); }
  66% { background-color: rgba(230, 0, 115, 0.2); }
  100% { background-color: transparent; }
`;

export const Game = () => {
  const [position, setPosition] = useState<Position>({ x: 35, y: 350 });
  const [amountOfClicks, setAmountOfClicks] = useState(0);
  const [countdown, setCountdown] = useState<number>(3000);
  const [timer, setTimer] = useState<number>(0);
  const [isGameOver, setIsGameOver] = useState(false);
  const [finalScore, setFinalScore] = useState(0);
  const [playerName, setPlayerName] = useState('');
  const [running, setRunning] = useState(false);

  const [highScore, setHighScore] = useState<Array<{ name: string; score: number; date: string }>>([]);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  useEffect(() => {
    // Only set up listener if the game is running
    if (!running) return;

    const unsubscribe = onSnapshot(query(collection(db, 'scores'), orderBy('score', 'desc'), limit(5)), (snapshot) => {
      setHighScore(snapshot.docs.map((doc) => doc.data() as { name: string; score: number; date: string }));
    });

    return () => {
      unsubscribe();
    };
  }, [running]); // Only run when running state changes

  const resetTimer = useRef<NodeJS.Timeout>();
  const countdownInterval = useRef<NodeJS.Timeout>();
  const timerInterval = useRef<NodeJS.Timeout>();

  const saveScore = async () => {
    if (!playerName) return;
    setSaveButtonDisabled(true);
    await addDoc(collection(db, 'scores'), {
      name: playerName,
      score: finalScore,
      date: new Date().toISOString(),
    });
  };

  useEffect(() => {
    if (countdown === 0) {
      setIsGameOver(true);
      const score = calculateScore();
      setFinalScore(score);
      // Check if this is a new highscore
      if (timerInterval.current) clearInterval(timerInterval.current);
      if (countdownInterval.current) clearInterval(countdownInterval.current);
    }
  }, [countdown, highScore]);

  const calculateScore = () => {
    return Math.floor(timer + amountOfClicks * 100);
  };

  const handleReplay = () => {
    setPlayerName('');
    setIsGameOver(false);
    setSaveButtonDisabled(false);
    setAmountOfClicks(0);
    setCountdown(0);
    setTimer(0);
    setPosition({ x: 35, y: 350 });
  };

  const handleClick = () => {
    handleHover();
    if (resetTimer.current) {
      clearTimeout(resetTimer.current);
    }

    // Start or continue timer on successful click
    if (amountOfClicks === 0) {
      if (timerInterval.current) clearInterval(timerInterval.current);
      setTimer(0);
      timerInterval.current = setInterval(() => {
        setTimer((prev) => prev + 10);
      }, 10);
    }

    setAmountOfClicks((prev) => prev + 1);

    // Reset countdown with new calculation
    if (countdownInterval.current) clearInterval(countdownInterval.current);
    const nextCountdown = Math.floor(Math.max((-0.15 * amountOfClicks + 3) * 1000, 900)); // Convert to milliseconds, minimum 100ms

    setCountdown(nextCountdown);

    countdownInterval.current = setInterval(() => {
      setCountdown((prev) => Math.max(0, prev - 10));
    }, 10);

    resetTimer.current = setTimeout(() => {
      setCountdown(0);
      if (timerInterval.current) clearInterval(timerInterval.current);
      if (countdownInterval.current) clearInterval(countdownInterval.current);
    }, nextCountdown); // Use the new countdown duration for reset timer
  };

  const handleHover = () => {
    let newX, newY;
    do {
      newX = Math.random() * 70;
      newY = Math.random() * 700;
    } while (Math.sqrt(Math.pow(newX - position.x, 2) + Math.pow(newY - position.y, 2)) < 80);

    setPosition({
      x: newX,
      y: newY,
    });
  };

  const handleNameChange = (value: string) => {
    setPlayerName(value);
  };

  const isNewHighscore = finalScore > (highScore[0]?.score ?? 0);

  const targetWidth = Math.max(50, Math.min(300, 400 - amountOfClicks * 12));

  // Add cleanup for all intervals in component unmount
  useEffect(() => {
    return () => {
      if (resetTimer.current) clearTimeout(resetTimer.current);
      if (countdownInterval.current) clearInterval(countdownInterval.current);
      if (timerInterval.current) clearInterval(timerInterval.current);
    };
  }, []);

  return (
    <Container>
      {!running ? (
        <>
          <MainButton text="Klick mich 👑👑" onClick={() => setRunning(true)} color="red" />
        </>
      ) : (
        <>
          {isGameOver ? (
            <GameOverContainer>
              <H2>Game Over!</H2>
              {isNewHighscore && <NewHighscoreText>Neuer Highscore! 🎉</NewHighscoreText>}
              <H3>Endpunktzahl: {finalScore} Punkte</H3>
              <H2>Highscores</H2>
              <HighScoreList>
                {highScore.map((score, index) => (
                  <HighScoreItem key={index}>
                    <span>{index + 1}.</span>
                    <span>{score.name}</span>
                    <span>{score.score} Punkte</span>
                    <span>{new Date(score.date).toLocaleString('de-DE', { dateStyle: 'short' })}</span>
                  </HighScoreItem>
                ))}
              </HighScoreList>
              {!saveButtonDisabled && (
                <NameInputContainer>
                  <SingleTextInput
                    label="Dein Name"
                    value={playerName}
                    onChange={handleNameChange}
                    placeholder="Namen eingeben..."
                  />
                  <SaveScoreButton text="Punktzahl speichern" onClick={saveScore} disabled={!playerName} />
                </NameInputContainer>
              )}
              <H3>Punkteberechnung:</H3>
              <ScoreExplanation>
                Basiszeit: {timer}ms
                <br />
                Klick-Bonus: {amountOfClicks} × 100 = {amountOfClicks * 100}
                <br />
                Gesamtpunktzahl: {finalScore}
              </ScoreExplanation>

              <MainButton text="Nochmal spielen" onClick={handleReplay} />
            </GameOverContainer>
          ) : (
            <>
              <H2>Anzahl der Klicks: {amountOfClicks}</H2>
              <H2>Countdown: {countdown}ms</H2>
              <H2>Zeit: {timer}ms</H2>
              <PlayArea $showBlink={timer > 7000} timer={timer}>
                <ButtonContainer position={position}>
                  <TargetButton text="Klick mich!" onClick={handleClick} $width={targetWidth} />
                </ButtonContainer>
              </PlayArea>
            </>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PlayArea = styled.div.attrs<{ $showBlink?: boolean; timer: number }>(({ $showBlink, timer }) => ({
  style: {
    animationDuration: $showBlink ? `${Math.max(20000 / timer, 0.3)}s` : '0s',
  },
}))<{ $showBlink?: boolean; timer: number }>`
  width: 800px;
  height: 800px;
  border: 2px solid #ccc;
  border-radius: 8px;
  position: relative;

  ${({ $showBlink }) =>
    $showBlink &&
    css`
      animation: ${blinkingBackground} ease-in-out infinite;
    `}
`;

const ButtonContainer = styled.div<{ position: Position }>`
  position: absolute;
  left: ${(props) => props.position.x}%;
  top: ${(props) => props.position.y}px;
  transition: all 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

const GameOverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 40px 0;
`;

const ScoreExplanation = styled.div`
  font-size: 1.1em;
  line-height: 1.5;
  margin: 10px 0;
  padding: 15px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`;

const NameInputContainer = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 10px 0;
`;

const TargetButton = styled(MainButton)<{ $width: number }>`
  width: ${(props) => props.$width}px;
`;

const HighScoreList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const HighScoreItem = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 100px 150px;
  gap: 1rem;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
`;

const SaveScoreButton = styled(MainButton)`
  margin-top: 1rem;
  width: 100%;
`;

const NewHighscoreText = styled.div`
  color: #2ecc71;
  font-size: 1.5em;
  font-weight: bold;
  margin: 10px 0;
  animation: ${blinkingBackground} 2s ease-in-out infinite;
`;
