import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InfoText } from '../../../core/components/text/InfoText';
import { getAppBrandingImages } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';
import { AppBrandingPracticeImages } from '../components/AppBrandingPracticeImages';
import { AppBrandingScreenContainer } from '../components/AppBrandingScreenContainer';
import { useAppBrandingPracticeInfo } from '../utils/useAppBrandingPracticeInfo';

export const AppBrandingImagesScreen = () => {
  const { t } = useTranslation();
  const { practiceName } = useAppBrandingPracticeInfo();
  const images = useMainStore(getAppBrandingImages);

  const navigate = useNavigate();

  const handleNext = () => {
    navigate('../description');
  };

  const handleBack = () => {
    navigate('../services');
  };

  const currentSteps = isEmpty(images) ? 0 : 1;

  return (
    <AppBrandingScreenContainer
      handleBack={handleBack}
      nextButton={{
        text: 'Weiter',
        onClick: handleNext,
        numberOfSteps: 1,
        currentStep: currentSteps,
      }}
    >
      <div>
        <StyledInfoText
          headline={'Praxisfotos'}
          text={[
            'Gib deinen Patienten mit Praxisfotos einen Einblick in deine Räumlichkeiten, damit sie wissen, dass sie sich bei dir wohlfühlen werden. Das erste Bild nutzen wir außerdem als Titelbild für deine Praxisseite. Lade bitte mindestens ein Bild hoch.',
          ]}
          preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName: practiceName })}
        />
        <AppBrandingPracticeImages />
      </div>
    </AppBrandingScreenContainer>
  );
};
const StyledInfoText = styled(InfoText)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
