import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { deleteDoc, doc } from 'firebase/firestore';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '../../../core/components/buttons/ButtonGroup';
import { removePracticeFromChain, setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain';
import { db } from '../../../firebaseConfig';
import { NavigationHeader } from '../../../navigation/components/NavigationHeader';
import {
  getChainPractices,
  getChainPracticesWithoutPayment,
  getIncompleteChainPracticesWithPayment,
  getIsAddNewPractice,
} from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';
import { useHandleScroll } from '../utils/useHandleScroll';
import { useProgressHeight } from '../utils/useProgressHeight';
import { OnboardingProgress } from './OnboardingProgress';

type Props = {
  children: React.ReactNode;
  hideBackButton?: boolean;
  hideProgress?: boolean;
  handleBack?: () => void;
  hideCloseButton?: boolean;
  nextButton?: {
    text?: string;
    onClick: () => void;
    disabled?: boolean;
    currentStep?: number;
    numberOfSteps?: number;
    loading?: boolean;
  };
  textButton?: {
    disabled?: boolean;
    text: string;
    onClick: () => void;
    loading?: boolean;
  };
};
export const OnboardingScreenContainer = ({
  children,
  hideBackButton,
  hideProgress,
  nextButton,
  handleBack,
  textButton,
  hideCloseButton,
}: Props) => {
  const navigate = useNavigate();
  const notPayedPractices = useMainStore(getChainPracticesWithoutPayment);
  const payedIncompletePractices = useMainStore(getIncompleteChainPracticesWithPayment);
  const practices = useMainStore(getChainPractices);
  const progressHeight = useProgressHeight();
  const isAddNewPractice = useMainStore(getIsAddNewPractice);
  const onBackClick = () => {
    if (handleBack) {
      handleBack();
    } else {
      navigate(-1);
    }
  };
  const handleClose = async () => {
    if (practices && notPayedPractices && !isEmpty(notPayedPractices)) {
      if (
        confirm(
          'Möchtest du das Hinzufügen einer neuen Praxis wirklich abbrechen? Alle bisherigen Eingaben werden gelöscht.',
        )
      ) {
        const promises: Promise<any>[] = [];
        for (const element of notPayedPractices) {
          promises.push(deleteDoc(doc(db, 'practices/' + element?.id)));
          promises.push(removePracticeFromChain(element?.id, practices));
        }
        if (payedIncompletePractices) {
          for (const element of payedIncompletePractices) {
            promises.push(setChainOnboardingFlag(`practices.${element.id}.completed`));
          }
        }
        await Promise.all(promises);
        navigate('/personal-data');
      }
    } else {
      navigate('/personal-data');
    }
  };
  const { t } = useTranslation();

  const { containerRef, childRef } = useHandleScroll();

  return (
    <>
      <NavigationHeader
        onBackClick={hideBackButton ? undefined : onBackClick}
        onCloseClick={isAddNewPractice && !hideCloseButton ? handleClose : undefined}
      />
      <Container ref={containerRef} $hideProgress={hideProgress} $minHeight={progressHeight ?? 0}>
        <ContentContainer>
          {children}
          <ButtonGroup
            progressButton={
              nextButton ? { ...nextButton, text: nextButton.text ?? t('ONBOARDING-BUTTON-NEXT') } : undefined
            }
            textButton={textButton}
          />
        </ContentContainer>

        {!hideProgress && <StyledOnboardingProgress ref={childRef} className="child" />}
      </Container>
    </>
  );
};

const Container = styled.div<{ $hideProgress?: boolean; $minHeight: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  width: 955px;
  justify-content: ${(props) => (props.$hideProgress ? 'center' : 'flex-start')};
  position: relative;
  background-color: 'blue';
  min-height: ${(props) => props.$minHeight}px;
  margin-bottom: 200px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    padding-top: 20px;
    width: 100%;
    max-width: 955px;
    justify-content: center;
    min-height: auto;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  padding: 0 20px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  gap: 20px;
`;

const StyledOnboardingProgress = styled(OnboardingProgress)`
  position: absolute;
  top: 0;
  right: 0;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    display: none;
  }
`;
