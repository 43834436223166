import styled from 'styled-components';
import { getChainPractices } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { PracticeLink } from '../../settings/components/PracticeLink';

export const OverviewPracticeLinks = () => {
  const chainPractices = useMainStore(getChainPractices);
  return (
    <Container>
      {chainPractices?.map((p, index) => <PracticeLink key={'qrCode' + index} practiceId={p.id} />)}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
