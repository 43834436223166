import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { colors } from '../../../../styles/colors';
import { H2, H4 } from '../../../../styles/textStyles';
import { priceFormatter } from '../screens/AdminSalesCalculatorScreen';

type AdminSalesCalculatorPartnerFeesComponentProps = {
  partnerFees: number;
};

export const AdminSalesCalculatorPartnerFeesComponent = (props: AdminSalesCalculatorPartnerFeesComponentProps) => {
  const { t } = useTranslation();
  const price = props.partnerFees;
  return (
    <Container>
      <ContentContainer>
        <H2>{t('ADMIN-SALES-PARTNER-FEES-TITLE')}</H2>
        <List>
          <ListItem>{t('ADMIN-SALES-PARTNER-FEES-SERVICE-1')}</ListItem>
          <ListItem>{t('ADMIN-SALES-PARTNER-FEES-SERVICE-2')}</ListItem>
          <ListItem>{t('ADMIN-SALES-PARTNER-FEES-SERVICE-3')}</ListItem>
          <ListItem>{t('ADMIN-SALES-PARTNER-FEES-SERVICE-4')}</ListItem>
        </List>
      </ContentContainer>
      <PriceContainer>
        <PriceLabel>{t('ADMIN-SALES-PARTNER-PRICE-LABEL')}</PriceLabel>
        <Price>{priceFormatter.format(price)}</Price>
      </PriceContainer>
    </Container>
  );
};

const Container = styled.div`
  flex: 2;
  max-width: 480px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${hexWithOpacity(colors.Medium, 0.1)};
  border-radius: 20px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const List = styled.ul`
  list-style: inside;
  color: ${colors.Medium};
  padding: 0;
`;

const ListItem = styled.li`
  font-family: 'Lora';
  font-size: 15px;
  font-weight: 400px;
  color: ${colors.Medium};
`;

const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Price = styled(H2)`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const PriceLabel = styled(H4)`
  flex: 2;
  display: flex;
  justify-content: flex-start;
`;
