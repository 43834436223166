import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AccordionComponent } from '../../../core/components/AccordionComponent';
import { InfoText } from '../../../core/components/text/InfoText';
import { getAppBrandingImages } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';
import { Body } from '../../../styles/textStyles';
import { AppBrandingDescription } from '../components/AppBrandingDescription';
import { AppBrandingScreenContainer } from '../components/AppBrandingScreenContainer';
import { useAppBrandingPracticeInfo } from '../utils/useAppBrandingPracticeInfo';

export const AppBrandingDescriptionScreen = () => {
  const { t } = useTranslation();
  const { practiceName } = useAppBrandingPracticeInfo();
  const images = useMainStore(getAppBrandingImages);
  const setDescription = useMainStore((state) => state.setAppBrandingDescription);

  const navigate = useNavigate();

  const skip = () => {
    setDescription('');
    navigate('../intro-video');
  };

  const handleNext = () => {
    navigate('../intro-video');
  };

  const handleBack = () => {
    navigate('../images');
  };

  const currentSteps = isEmpty(images) ? 0 : 1;

  return (
    <AppBrandingScreenContainer
      handleBack={handleBack}
      nextButton={{
        text: 'Weiter',
        onClick: handleNext,
        numberOfSteps: 1,
        currentStep: currentSteps,
      }}
      textButton={{
        text: 'Schritt überspringen',
        onClick: skip,
      }}
    >
      <div>
        <StyledInfoText
          headline={'Praxisbeschreibung'}
          text={[
            'Schreibe hier was deine Praxis ausmacht und gib deinen Patienten einen Einblick in deine Motivation und Geschichte. Lass sie wissen, warum sie bei dir in den besten Händen sind.',
          ]}
          preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName: practiceName })}
        />
        <AccordionContainer>
          <AccordionComponent title="Beispieltext anzeigen">
            <TextExample>
              {
                'Unsere Praxis wurde vor über X Jahren gegründet und hat sich seitdem zu einem festen Bestandteil der Region entwickelt. Mit einem erfahrenen und kompetenten Team aus Physiotherapeuten, das sich kontinuierlich fortbildet, bieten wir ein breites Spektrum an Therapiemöglichkeiten an. Jeder unserer Mitarbeiter bringt nicht nur Fachwissen, sondern auch Leidenschaft für die Physiotherapie mit, wodurch eine warme und vertrauensvolle Atmosphäre entsteht. Unser Ziel ist es, gemeinsam mit unseren Patienten ihre Gesundheit und Lebensqualität nachhaltig zu verbessern.'
              }
            </TextExample>
          </AccordionComponent>
        </AccordionContainer>
        <AppBrandingDescription />
      </div>
    </AppBrandingScreenContainer>
  );
};
const StyledInfoText = styled(InfoText)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const TextExample = styled(Body)`
  font-style: italic;
`;

const AccordionContainer = styled.div`
  margin-bottom: 40px;
`;
