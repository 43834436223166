import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AppBrandingPhoneMock } from '../features/appBranding/components/AppBrandingPhoneMock';
import { useHandleScroll } from '../features/onboarding/utils/useHandleScroll';
import { DESKTOP_BREAKPOINT } from '../styles/breakpoints';
import { PhoneMockLayoutContainer } from './components/PhoneMockLayoutContainer';

export const PhoneMockLayout = () => {
  const { containerRef, childRef } = useHandleScroll();
  const location = useLocation();

  const path = location.pathname;

  let mockType: 'home' | 'practice' | 'video' | 'summary' = 'practice';
  if (path.includes('logo') || path.includes('color')) {
    mockType = 'home';
  } else if (path.includes('video')) {
    mockType = 'video';
  } else if (path.includes('summary')) {
    mockType = 'summary';
  }

  return (
    <PhoneMockLayoutContainer ref={containerRef}>
      <Outlet />
      <StyledAppBrandingPhoneMock ref={childRef} className="child" type={mockType} />
    </PhoneMockLayoutContainer>
  );
};

const StyledAppBrandingPhoneMock = styled(AppBrandingPhoneMock)`
  position: absolute;
  top: 0;
  right: 0;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    display: none;
  }
`;
