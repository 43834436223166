import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { DrawerNavItem } from './DrawerNavItem';

type Props = {
  item: { title: string; path: string; children?: { title: string; path: string }[]; dropdown?: boolean };
  toggleDrawer: () => void;
  initialOpen?: boolean;
};

export const DrawerCategoryItems = ({ item, toggleDrawer, initialOpen }: Props) => {
  const [extended, setExtended] = useState(initialOpen);

  const path = useLocation().pathname;

  const isPracticeSettings = path.includes('practice-settings');

  useEffect(() => {
    if (!isPracticeSettings) {
      setExtended(initialOpen);
    } else {
      setExtended(path.includes(item.path));
    }
  }, [path, isPracticeSettings]);

  return (
    <>
      <DrawerNavItem
        {...item}
        closeDrawer={!extended ? () => {} : toggleDrawer}
        isParent={item.children && item.children.length > 0}
        dropdown={item.dropdown}
      />
      {extended &&
        item.children?.map((child) => (
          <Items key={child.path}>
            <DrawerNavItem key={item.path} path={child.path} closeDrawer={toggleDrawer} title={child.title} />
          </Items>
        ))}
    </>
  );
};

const Items = styled.div`
  padding-left: 20px;
`;
