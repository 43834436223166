import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ScreenContainer } from '../core/components/container/ScreenContainer';

import styled from 'styled-components';
import { VersionTag } from '../core/components/VersionTag';
import { useAppBrandingPracticeInfo } from '../features/appBranding/utils/useAppBrandingPracticeInfo';
import { getAppBrandingPracticeId } from '../store/appBrandingSlice';
import { getAppBrandingCompleted } from '../store/chainSlice';
import { useMainStore } from '../store/mainStore';
import { Header } from './components/Header';

export const AppBrandingLayout = () => {
  const { practiceId, practiceName } = useAppBrandingPracticeInfo();
  const appBrandingId = useMainStore(getAppBrandingPracticeId);
  const appBrandingCompleted = useMainStore((state) => getAppBrandingCompleted(state, practiceId));

  const location = useLocation();
  const isIntro = location.pathname.includes('intro');

  if (!practiceId || !practiceName || appBrandingCompleted) {
    return <Navigate to="/" />;
  } else if ((!appBrandingId || appBrandingId !== practiceId) && !isIntro) {
    return <Navigate to={'/branding/' + practiceId + '/intro'} />;
  }
  return (
    <ScreenContainer>
      <Header />
      <OutletContainer className="onBoardingScreenContainer-scroll-ancestor">
        <Outlet />
      </OutletContainer>
      <VersionTag />
    </ScreenContainer>
  );
};

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 100px;
`;
