import styled from 'styled-components';
import { IconButton } from '../../../../core/components/buttons/IconButton';
import { colors } from '../../../../styles/colors';
import { Body } from '../../../../styles/textStyles';
import { AdminUser } from '../../../../types/Admin/AdminUserType';

type Props = {
  user: AdminUser;
};

export const AdminUserLine = ({ user }: Props) => {
  return (
    <Container>
      <Row>
        <Element>{user.firstName + ' ' + user.lastName}</Element>
        <Element>{user.email}</Element>
        <Element>{user.state}</Element>
        <Roles>
          {Object.entries(user.roles)
            .filter(([_, value]) => value)
            .map(([key, _]) => (
              <Element key={key}>{key}</Element>
            ))}
        </Roles>
        <div>
          <IconButton iconName="Close" size={24} />
        </div>
      </Row>
    </Container>
  );
};

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${colors.Light};
`;

const Element = styled(Body)``;

const Roles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
