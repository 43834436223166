import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';
import { checkIfValidEmail } from '../utils/checkIfValidMail';

type Props = {
  setCurrentStep?: (step: number) => void;
  email: string;
  setEmail: (newMail: string) => void;
  focusOnFirstInput?: boolean;
  loadingData?: boolean;
};

export const PayoutEmailForm = ({ focusOnFirstInput, setCurrentStep, email, setEmail, loadingData }: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');

  useEffect(() => {
    if (focusOnFirstInput) {
      document.getElementById('firstInput')?.focus();
    }
  }, []);

  const changeMail = (value: string) => {
    setEmail(value);
    setError('');
    if (checkIfValidEmail(value)) {
      setCurrentStep?.(1);
    } else {
      setCurrentStep?.(0);
    }
  };

  const checkMail = () => {
    if (!checkIfValidEmail(email)) {
      setError(t('ONBOARDING-REGISTER-ERROR-EMAIL-NOT-VALID'));
    }
  };

  return (
    <Container>
      <StyledH3>{'E-Mail Adresse für deine Gutschrift als PDF'}</StyledH3>

      <FormContainer>
        <SingleTextInput
          loadingData={loadingData}
          id="firstInput"
          type="name"
          value={email}
          onChange={changeMail}
          onBlur={checkMail}
          placeholder={'E-Mail Adresse'}
          error={error}
        />
      </FormContainer>
    </Container>
  );
};

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
`;

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
`;
