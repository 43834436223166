import styled from 'styled-components';
import { AccordionComponent } from '../../../core/components/AccordionComponent';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { colors } from '../../../styles/colors';
import { Therapist } from '../../../types/Practices/TherapistType';
import { useHandleTherapistInfo } from '../utils/useHandleTherapistInfo';

type Props = {
  therapist: Therapist;
};

export const TherapistCell = ({ therapist }: Props) => {
  const { title, avatarUrl, items, handleChangeAvatar, inputRef, onButtonClick } = useHandleTherapistInfo(therapist);

  return (
    <AccordionComponent title={title} iconUrl={avatarUrl} onIconClick={onButtonClick}>
      <InnerContainer>
        <HiddenInput ref={inputRef} type="file" accept=".jpg,.jpeg,.png" onChange={handleChangeAvatar} />
        {items.map((item, index) => (
          <TextButton
            key={index + item.title}
            text={item.title}
            onClick={item.onClick}
            color={item.warning ? colors.LightRed : colors.Dark}
          />
        ))}
      </InnerContainer>
    </AccordionComponent>
  );
};

const InnerContainer = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HiddenInput = styled.input`
  display: none;
`;
