import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { ScreenContainer } from '../../../core/components/container/ScreenContainer';
import { VersionTag } from '../../../core/components/VersionTag';
import { useWindowDimensions } from '../../../core/utils/useWindowDimensions';
import { Header } from '../../../navigation/components/Header';
import { useMainStore } from '../../../store/mainStore';
import { getIsMitarbeiter, getMitarbeiterUserIsLoggedIn } from '../../../store/userSlice';
import { DESKTOP_BREAKPOINT, DESKTOP_BREAKPOINT_NUMBER } from '../../../styles/breakpoints';
import { AdminDrawer } from '../home/components/AdminDrawer';

export const AdminInAppLayout = () => {
  const { width } = useWindowDimensions();
  const drawerAlwaysOpen = width > DESKTOP_BREAKPOINT_NUMBER;

  const [isDrawerOpen, setIsDrawerOpen] = useState(drawerAlwaysOpen);
  const isMitarbeiter = useMainStore(getIsMitarbeiter);
  const userLoggedIn = useMainStore(getMitarbeiterUserIsLoggedIn);

  useEffect(() => {
    setIsDrawerOpen(drawerAlwaysOpen);
  }, [width]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  if (!userLoggedIn) {
    return <Navigate to="/adminWelcome" />;
  }
  if (userLoggedIn && !isMitarbeiter) {
    return <Navigate to="/welcome" />;
  }
  return (
    <ScreenContainer>
      <Header toggleDrawer={toggleDrawer} drawerOpen={isDrawerOpen || drawerAlwaysOpen} />
      <MainContent $isDrawerOpen={isDrawerOpen}>
        <Outlet />
        <VersionTag />
      </MainContent>
      <AdminDrawer isOpen={isDrawerOpen || drawerAlwaysOpen} toggleDrawer={toggleDrawer} />
    </ScreenContainer>
  );
};

const MainContent = styled.div<{ $isDrawerOpen: boolean }>`
  padding-left: 250px;
  width: 100vw;
  transition: padding-left 0.3s ease-in-out;
  display: flex;
  padding-bottom: 200px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    padding-left: 0px;
  }
`;
