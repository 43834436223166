import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { IconButton } from '../../../core/components/buttons/IconButton';
import { useWindowDimensions } from '../../../core/utils/useWindowDimensions';
import { colors } from '../../../styles/colors';
import { ButtonText, H4, Small } from '../../../styles/textStyles';
import { Therapist } from '../../../types/Practices/TherapistType';
import { useHandleTherapistsLinks } from '../utils/useHandlePracticeTherapistsLinks';

type TherapistLinksProps = {
  practiceId: string;
  therapist: Therapist;
};

export const TherapistLinks = (props: TherapistLinksProps) => {
  const { isMobileWidth } = useWindowDimensions();
  const { copyLink, openLink, therapistProfilePicture, therapistUrl } = useHandleTherapistsLinks(
    props.practiceId,
    props.therapist,
  );

  return (
    <Container>
      {therapistProfilePicture ? (
        <Avatar src={therapistProfilePicture?.assetUrl} />
      ) : (
        <GenericSvg name="Avatar" size={48} />
      )}
      <InnerContainer>
        <TextContainer>
          <H4>
            {props.therapist.firstName} {props.therapist.lastName}
          </H4>

          <StyledSmall onClick={openLink}>{therapistUrl}</StyledSmall>
        </TextContainer>
        <LinkButtonsContainer>
          {isMobileWidth ? (
            <IconButton iconName={'Clipboard'} onClick={copyLink} stroke={colors.Dark} size={24} />
          ) : (
            <>
              <StyledButtonText onClick={copyLink}>Link Kopieren</StyledButtonText>
              <StyledButtonText onClick={openLink}>Öffnen</StyledButtonText>
            </>
          )}
        </LinkButtonsContainer>
      </InnerContainer>
    </Container>
  );
};

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0px 20px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  overflow: hidden;
  flex: 1;
`;

const LinkButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledSmall = styled(Small)`
  color: ${colors.Medium};
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-decoration: underline;
`;

const StyledButtonText = styled(ButtonText)`
  padding: 0 20px;
  text-decoration: underline;
  cursor: pointer;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 300px;
`;
