import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { getAppBrandingColors } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { Mini } from '../../../../styles/textStyles';

type Props = {
  text?: string;
};

export const MockContactTextLine = ({ text }: Props) => {
  const appColors = useMainStore(getAppBrandingColors);

  if (!text || text === '') {
    return (
      <SkeletonWrapper>
        <Skeleton height={12} />
      </SkeletonWrapper>
    );
  } else {
    return <Text $color={appColors.Medium}>{text}</Text>;
  }
};

const Text = styled(Mini)<{ $color: string }>`
  text-align: left;
  color: ${({ $color }) => hexWithOpacity($color, 0.6)};
  max-lines: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SkeletonWrapper = styled.div`
  width: 70%;
  padding: 0;
`;
