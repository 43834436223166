import { forwardRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { getAppBrandingClaim, getAppBrandingColors } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { Small } from '../../../../styles/textStyles';

export const MockClaimCard = forwardRef<HTMLDivElement>((_, ref) => {
  const claim = useMainStore(getAppBrandingClaim);
  const appColors = useMainStore(getAppBrandingColors);

  const loading = !claim || claim === '';

  return (
    <Card ref={ref}>
      {loading ? (
        <SkeletonWrapper>
          <Skeleton height={16} count={2} />
        </SkeletonWrapper>
      ) : (
        <Text $color={appColors.Primary}>{claim}</Text>
      )}
    </Card>
  );
});

const Text = styled(Small)<{ $color: string }>`
  font-weight: 500;
  color: ${({ $color }) => $color};
  text-align: center;
`;

const Card = styled.div`
  margin: 0 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 20px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: ${colors.White};
`;

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;
