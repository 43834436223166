import { useLoaderData } from 'react-router-dom';
import styled from 'styled-components';
import { RadioButton } from '../../../core/components/buttons/RadioButton';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { colors } from '../../../styles/colors';
import { Small } from '../../../styles/textStyles';
import { PracticeInvoiceData } from '../../../types/Practices/PracticeInvoiceDataType';

type Props = {
  confirmedContract: boolean;
  setConfirmedContract: (value: boolean) => void;
};

export const OnboardingBillContractButton = ({ confirmedContract, setConfirmedContract }: Props) => {
  const invoiceData = (useLoaderData() as { practiceInvoiceData: PracticeInvoiceData }).practiceInvoiceData;
  const invoiceAddress = invoiceData.address;
  return (
    <ContractContainer>
      <RadioButton checked={confirmedContract} onPress={() => setConfirmedContract(!confirmedContract)} />
      <StyledSmall>
        {'Hiermit bestätige ich, dass ich mit meiner Zahlung dem '}
        <StyledLink
          href="https://physiofitapp.notion.site/Vertrag-und-Allgemeine-Gesch-ftsbedingungen-AGB-fedeef5a26c346829e5e86b42938e344"
          target="_blank"
          rel="noopener noreferrer"
        >
          {'Dienstleistungsvertrag'}
        </StyledLink>
        {` zwischen der Digital Training and GmbH und ${invoiceAddress?.name} zustimme.`}
      </StyledSmall>
    </ContractContainer>
  );
};

const ContractContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-left: 10px;
  align-items: center;
  padding-top: 20px;
`;

const StyledSmall = styled(Small)`
  color: ${hexWithOpacity(colors.Medium, 0.65)};
  text-align: left;
`;

const StyledLink = styled.a`
  text-decoration: underline;
  color: ${colors.Dark};
`;
