import { doc, setDoc } from 'firebase/firestore';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { addValueIfExists } from '../../../core/utils/addValueIfExists';
import { setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain';
import { db } from '../../../firebaseConfig';
import { getAppBranding } from '../../../store/appBrandingSlice';
import { getChainPracticeName } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { AppCustomization } from '../../../types/Practices/AppCustomizationType';

export const useAppBrandingPracticeInfo = () => {
  const navigate = useNavigate();
  const practiceId = useParams().practiceId ?? '';
  const practiceName = useMainStore((state) => getChainPracticeName(state, practiceId));
  const appBranding = useMainStore(getAppBranding);
  const [uploading, setUploading] = useState(false);

  const uploadData = async () => {
    if (!appBranding || !appBranding.colors) return;
    setUploading(true);
    try {
      const appBrandingData: AppCustomization = {
        practiceId,
        colors: {
          primary: appBranding.colors.primary ?? colors.Primary,
          light: appBranding.colors.light ?? colors.Light,
          medium: appBranding.colors.medium ?? colors.Medium,
          dark: appBranding.colors.dark ?? colors.Dark,
        },
        ...addValueIfExists('address', appBranding.address),
        ...addValueIfExists('mapsInfo', appBranding.mapsInfo),
        ...addValueIfExists('contact', appBranding.contact),
        ...addValueIfExists('description', appBranding.description),
        ...addValueIfExists('services', appBranding.services),
        ...addValueIfExists('claim', appBranding.claim),
        ...addValueIfExists('openingHours', appBranding.openingHours),
        ...addValueIfExists('logoUrl', appBranding.logoUrl),
        ...addValueIfExists('imageUrls', appBranding.imageUrls),
      };
      await setChainOnboardingFlag(`practices.${practiceId}.brandingData`, 'brandingData');
      await setDoc(doc(db, 'practices/' + practiceId + '/documents/practiceAppCustomization'), appBrandingData);
    } catch (error) {
      console.error(error);
    } finally {
      setUploading(false);
    }
  };

  if (!practiceId) {
    navigate('/');
  }

  return { practiceId, practiceName, uploadAppBrandingData: uploadData, uploading };
};
