import styled from 'styled-components';
import { NavigationHeader } from '../../../navigation/components/NavigationHeader';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';

type Props = {
  children: React.ReactNode;
  onBackClick?: () => void;
  onCloseClick?: () => void;
};

export const MainScreenContainer = ({ children, onBackClick, onCloseClick }: Props) => {
  return (
    <Container>
      <NavigationHeader onBackClick={onBackClick} onCloseClick={onCloseClick} />
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 720px;
  min-height: 200px;
  padding-bottom: 200px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    width: 100%;
    max-width: 720px;
    min-height: auto;
    padding: 20px 20px;
  }
`;
