import styled from 'styled-components';
import { getHexBoxShadow } from '../../../../core/utils/getHexBoxShadow';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { getAppBrandingColors } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { Mini, Small } from '../../../../styles/textStyles';

export const MockProgressCard = () => {
  const appColors = useMainStore(getAppBrandingColors);

  return (
    <ProgressCard>
      <div>
        <ProgressHeadline $color={appColors.Dark}>{'Dein Fortschritt'}</ProgressHeadline>
        <ProgressSubtitle $color={appColors.Medium}>{'in Wochen'}</ProgressSubtitle>
      </div>
      <ProgressContainer>
        {new Array(8).fill(0).map((_, index) => (
          <ProgressElement key={index}>
            <ProgressNumbers $color={appColors.Medium}>{index + 1}</ProgressNumbers>
            <Progress
              $isFirst={index === 0}
              $isLast={index === 7}
              $color={index < 2 ? appColors.Primary : index === 2 ? appColors.Medium : appColors.Light}
            />
          </ProgressElement>
        ))}
      </ProgressContainer>
    </ProgressCard>
  );
};

const ProgressHeadline = styled(Small)<{ $color: string }>`
  font-weight: 500;
  color: ${({ $color }) => $color};
`;

const ProgressSubtitle = styled(Mini)<{ $color: string }>`
  color: ${({ $color }) => hexWithOpacity($color, 0.6)};
`;

const ProgressCard = styled.div`
  margin: 0 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 10px 15px;
  padding-bottom: 10px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  position: relative;
  top: -10px;
  background-color: ${colors.White};
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
`;

const ProgressElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const Progress = styled.div<{ $isLast?: boolean; $isFirst: boolean; $color: string }>`
  height: 6px;
  width: 100%;
  background-color: ${({ $color }) => $color};
  box-shadow: ${({ $color }) => getHexBoxShadow($color, 0.5)};
  border-radius: ${({ $isFirst, $isLast }) => ($isFirst ? '4px 0 0 4px' : $isLast ? '0 4px 4px 0' : '0')};
`;

const ProgressNumbers = styled(Mini)<{ $color: string }>`
  color: ${({ $color }) => $color};
  padding-bottom: 5px;
`;
