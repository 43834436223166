import styled from 'styled-components';
import { NavigationHeader } from '../../../navigation/components/NavigationHeader';

type Props = {
  children: React.ReactNode;
  onBackClick?: () => void;
  onCloseClick?: () => void;
};

export const FullScreenContainer = ({ children, onBackClick, onCloseClick }: Props) => {
  return (
    <Container>
      <NavigationHeader onBackClick={onBackClick} onCloseClick={onCloseClick} />
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 30px;
  padding-bottom: 200px;
`;
