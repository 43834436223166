import Skeleton from 'react-loading-skeleton';
import QRCode from 'react-qr-code';
import styled from 'styled-components';
import { IconButton } from '../../../core/components/buttons/IconButton';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { useWindowDimensions } from '../../../core/utils/useWindowDimensions';
import { MOBILE_BREAKPOINT } from '../../../styles/breakpoints';
import { colors } from '../../../styles/colors';
import { ButtonText, H4, Small } from '../../../styles/textStyles';
import { useHandlePracticeLinks } from '../utils/useHandlePracticeLinks';

type PracticeLinkContainerProps = {
  practiceId: string;
};

export const PracticeLink = ({ practiceId }: PracticeLinkContainerProps) => {
  const { loadingPractice, practiceLink, practiceName, copyLink, openLink } = useHandlePracticeLinks(practiceId);

  const { isMobileWidth } = useWindowDimensions();

  return (
    <>
      {!practiceLink ? (
        <Skeleton width={'100%'} height={130} borderRadius={20} />
      ) : (
        <Container>
          {loadingPractice ? (
            <>
              <Skeleton width={120} height={120} />
              <div style={{ width: '100%' }}>
                <Skeleton height={20} count={2} />
              </div>
            </>
          ) : (
            <>
              <QRCodeContainer>
                <div style={{ height: 'auto', margin: '0 auto', maxWidth: 120, width: '100%' }}>
                  <QRCode
                    size={120}
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                    value={practiceLink}
                    viewBox={`0 0 120 120`}
                  />
                </div>
                <InnerContainer>
                  <LinkContainer>
                    <H4>{`Praxislink Standort ${practiceName}`}</H4>
                    <StyledSmall onClick={openLink}>{practiceLink}</StyledSmall>
                  </LinkContainer>
                  <LinkButtonsContainer>
                    {isMobileWidth ? (
                      <IconButton iconName={'Clipboard'} onClick={copyLink} stroke={colors.Dark} size={24} />
                    ) : (
                      <LinkButtonsContainer>
                        <StyledButtonText onClick={copyLink}>Link Kopieren</StyledButtonText>
                        <StyledButtonText onClick={openLink}>Öffnen</StyledButtonText>
                      </LinkButtonsContainer>
                    )}
                  </LinkButtonsContainer>
                </InnerContainer>
              </QRCodeContainer>
            </>
          )}
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${hexWithOpacity(colors.Medium, 0.1)};
  padding: 10px 20px;
  border-radius: 20px;
`;

const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    flex-direction: column;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  width: 100%;
  gap: 20px;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
`;

const StyledSmall = styled(Small)`
  color: ${colors.Medium};
  text-decoration: underline;
  opacity: 0.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 365px;
  cursor: pointer;
`;

const LinkButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledButtonText = styled(ButtonText)`
  padding: 0 20px;
  text-decoration: underline;
  cursor: pointer;
`;
