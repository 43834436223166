import styled from 'styled-components';
import { H4 } from '../../../styles/textStyles';
import { DrawerCategoryItems } from './DrawerCategoryItems';

type Props = {
  headline: string;
  items: { title: string; path: string; children?: { title: string; path: string }[]; dropdown?: boolean }[];
  toggleDrawer: () => void;
};

export const DrawerCategory = ({ headline, items, toggleDrawer }: Props) => {
  return (
    <Category>
      <StyledH4>{headline}</StyledH4>
      <Items>
        {items.map((item, index) => (
          <DrawerCategoryItems
            key={item.path + index}
            item={item}
            toggleDrawer={toggleDrawer}
            initialOpen={index === 0}
          />
        ))}
      </Items>
    </Category>
  );
};

const Category = styled.div``;

const StyledH4 = styled(H4)``;
const Items = styled.div`
  padding-left: 20px;
`;
