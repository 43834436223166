import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { CustomButton } from '../../../core/components/buttons/CustomButton';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { colors } from '../../../styles/colors';
import { ButtonText, Small } from '../../../styles/textStyles';

type Props = {
  maxMBFileSize: number;
  handleUpload: (files: FileList) => void;
  file?: File | null | undefined;
  canUploadMultipleFiles?: boolean;
  type: 'video' | 'image';
};

export const AppBrandingMediaUpload = ({ maxMBFileSize, handleUpload, file, canUploadMultipleFiles, type }: Props) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleUpload(e.dataTransfer.files);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      handleUpload(e.target.files);
    }
  };

  const onButtonClick = () => {
    inputRef.current?.click();
  };

  const acceptedFileTypes = type === 'image' ? '.jpg,.jpeg,.png' : '.mp4';
  const supportedFormats = type === 'image' ? 'JPG, PNG' : 'MP4';

  return (
    <UploadContainer>
      <DropZone
        $dragActive={dragActive}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <HiddenInput
          ref={inputRef}
          type="file"
          accept={acceptedFileTypes}
          onChange={handleChange}
          multiple={canUploadMultipleFiles}
        />
        {!file ? (
          <GenericSvg size={80} name="UploadFile" primaryColor={colors.Primary} secondaryColor={colors.Medium} />
        ) : type === 'image' ? (
          <LogoImage src={URL.createObjectURL(file)} alt={`Logo`} />
        ) : (
          <video width="160" height="80" controls>
            <source src={URL.createObjectURL(file)} type={file.type} />
            Your browser does not support the video tag.
          </video>
        )}
        <Text>
          {'Drag and Drop oder '}
          <Button onClick={onButtonClick}>
            <StyledButtonText>{'Datei auswählen'}</StyledButtonText>
          </Button>
        </Text>
        {canUploadMultipleFiles && <Small>{'Sie können mehrere Dateien gleichzeitig hochladen'}</Small>}
      </DropZone>
      <Subtitle>
        <SmallText>{`Unterstützte Dateiformate: ${supportedFormats}`}</SmallText>
        <SmallText>{`Maximale Dateigröße: ${maxMBFileSize}MB`}</SmallText>
      </Subtitle>
    </UploadContainer>
  );
};

const UploadContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const DropZone = styled.div<{ $dragActive: boolean }>`
  border: 3px dashed ${(props) => (props.$dragActive ? colors.Primary : hexWithOpacity(colors.Medium, 0.2))};
  border-radius: 20px;
  padding: 2rem;
  text-align: center;
  background-color: ${(props) => (props.$dragActive ? hexWithOpacity(colors.Medium, 0.1) : colors.White)};
  transition: all 0.3s ease;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const HiddenInput = styled.input`
  display: none;
`;

const Text = styled.p`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: ${colors.Medium};
`;

const SmallText = styled(Text)`
  font-size: 0.75rem;
  color: ${colors.Medium};
`;

const Button = styled(CustomButton)`
  font-weight: 500;
  text-decoration: underline;
`;

const StyledButtonText = styled(ButtonText)`
  color: ${colors.Medium};
`;

const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LogoImage = styled.img`
  width: 160px;
  height: 80px;
  object-fit: contain;
`;
