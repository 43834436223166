import { PracticeLink } from './PracticeLink';
import { SettingsHeadline } from './SettingsHeadline';

type PracticeLinkContainerProps = {
  practiceId: string;
};

export const PracticeLinkContainer = ({ practiceId }: PracticeLinkContainerProps) => {
  return (
    <div>
      <SettingsHeadline
        title={`Praxislink`}
        text={
          'Der Praxislink dient als allgemeiner Zugang zu Physiofit. Mit diesem Link hast du Zugriff auf alle Patienten und kannst auswählen über welchen Therapeuten eingeladen werden soll. So können beispielsweise auch durch den Empfang Patienten-Einladungen versendet werden.'
        }
      />
      <PracticeLink practiceId={practiceId} />
    </div>
  );
};
