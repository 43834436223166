import styled from 'styled-components';
import { TextArea } from '../../../core/components/textInput/TextArea';
import { getAppBrandingDescription } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';

export const AppBrandingDescription = () => {
  const setDescription = useMainStore((state) => state.setAppBrandingDescription);
  const description = useMainStore(getAppBrandingDescription);

  return (
    <Container>
      <TextArea
        initialValue={description}
        onChange={setDescription}
        rows={5}
        placeholder={'Deine Praxisbeschreibung'}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;
