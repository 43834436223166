import { cloneDeep, isEmpty } from 'lodash';
import styled from 'styled-components';
import { IconButton } from '../../../core/components/buttons/IconButton';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { getAppBrandingServices } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
export const AppBrandingServicesInput = () => {
  const zustandServices = useMainStore(getAppBrandingServices);
  const services = isEmpty(zustandServices) ? [{ title: '', services: [''] }] : zustandServices;
  const setServices = useMainStore((state) => state.setAppBrandingServices);

  const addCategory = () => {
    const newServices = [...(services ?? []), { title: '', services: [] }];
    setServices(newServices);
  };

  const addItem = (index: number) => {
    const newServices = cloneDeep([...(services ?? [])]);
    newServices[index].services = [...newServices[index].services, ''];
    setServices(newServices);
  };

  const removeItem = (categoryIndex: number, itemIndex: number) => {
    let newItems = cloneDeep(services?.[categoryIndex].services);
    newItems?.splice(itemIndex, 1);
    const newServices = cloneDeep([...(services ?? [])]);
    newServices[categoryIndex].services = newItems;
    setServices(newServices);
  };

  const removeCategory = (index: number) => {
    const newServices = [...(services ?? [])];
    newServices.splice(index, 1);
    setServices(newServices);
  };

  const changeCategory = (index: number, value: string) => {
    const newServices = cloneDeep([...(services ?? [])]);
    newServices[index].title = value;
    setServices(newServices);
  };

  const changeItem = (categoryIndex: number, itemIndex: number, value: string) => {
    const newServices = cloneDeep([...(services ?? [])]);
    newServices[categoryIndex].services[itemIndex] = value;
    setServices(newServices);
  };

  return (
    <Container>
      {services?.map((service, categoryIndex) => (
        <Category key={'Category_' + categoryIndex}>
          <CategoryName>
            <SingleTextInput
              value={service.title}
              onChange={(value) => changeCategory(categoryIndex, value)}
              withoutError
              placeholder="Kategorie (z.B. Physiotherapie)"
            />
            {categoryIndex > 0 ? (
              <IconButton
                iconName="Close"
                size={24}
                stroke={colors.Medium}
                onClick={() => removeCategory(categoryIndex)}
              />
            ) : (
              <Placeholder />
            )}
          </CategoryName>

          <Items>
            {service.services.map((service, itemIndex) => (
              <Item key={'item_' + categoryIndex + '_' + itemIndex}>
                <Dot />
                <SingleTextInput
                  value={service}
                  onChange={(value) => changeItem(categoryIndex, itemIndex, value)}
                  withoutError
                  placeholder="Leistung (z.B. Krankengymnastik) "
                />
                <IconButton
                  iconName="Close"
                  size={24}
                  stroke={colors.Medium}
                  onClick={() => removeItem(categoryIndex, itemIndex)}
                />
              </Item>
            ))}
            <AddButtonContainer>
              <TextButton
                text={'Weitere Leistung'}
                icon={{ name: 'Plus', size: 24, color: colors.Primary }}
                onClick={() => addItem(categoryIndex)}
              />
            </AddButtonContainer>
          </Items>
        </Category>
      ))}
      <AddButtonContainer>
        <TextButton
          text={'Weitere Kategorie'}
          icon={{ name: 'Plus', size: 24, color: colors.Primary }}
          onClick={addCategory}
        />
      </AddButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const AddButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Dot = styled.div`
  min-width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${colors.Medium};
`;

const Item = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  padding-left: 20px;
`;
const CategoryName = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const Placeholder = styled.div`
  width: 44px;
  height: 24px;
`;
