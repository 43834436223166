import { ForwardedRef, forwardRef, useState } from 'react';
import styled from 'styled-components';
import PhoneMock from '../../../assets/images/MockPhone.png';
import { TagSelection } from '../../../core/components/tags/TagSelection';
import { MockHomeScreen } from './mockComponents/MockHomeScreen';
import { MockPracticeScreen } from './mockComponents/MockPracticeScreen';
import { MockVideoScreen } from './mockComponents/MockVideoScreen';

type Props = {
  className?: string;
  type: 'home' | 'practice' | 'video' | 'summary';
  section?: string;
};
export const AppBrandingPhoneMock = forwardRef(
  ({ className, type, section }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const [summaryType, setSummaryType] = useState<'home' | 'video' | 'practice' | 'summary'>('home');
    let internalType = type === 'summary' ? summaryType : type;

    let Content;
    switch (internalType) {
      case 'home':
        Content = <MockHomeScreen />;
        break;
      case 'practice':
        Content = <MockPracticeScreen section={section} />;
        break;
      case 'video':
        Content = <MockVideoScreen />;
        break;
    }

    const tags = [
      { text: 'Start', value: 'home' },
      { text: 'Praxis', value: 'practice' },
      { text: 'Video', value: 'video' },
    ];

    return (
      <Container className={className} ref={ref}>
        <PhoneImage>
          <OverflowContainer>{Content}</OverflowContainer>
          <AbsolutePhoneImage src={PhoneMock} alt="Phone Mock" />
        </PhoneImage>
        {type === 'summary' && (
          <TagsContainer>
            <TagSelection
              tags={tags}
              selected={[summaryType]}
              setSelected={(selected) => setSummaryType(selected[0] as 'home' | 'video' | 'practice' | 'summary')}
              type="singleSelect"
            />
          </TagsContainer>
        )}
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PhoneImage = styled.div`
  position: relative;
  height: 675px;
  width: 420px;
  background-size: cover;
  padding: 0px 64px;
  padding-bottom: 22px;
`;

const OverflowContainer = styled.div`
  padding-top: 20px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: 30px;
`;

const AbsolutePhoneImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 675px;
  width: 420px;
  z-index: 2;
  pointer-events: none;
`;

const TagsContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
`;
