import { doc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import styled from 'styled-components';
import { readCloudflareUrl } from '../../../../core/utils/getResolutionUrl';
import { db } from '../../../../firebaseConfig';
import { getAppBrandingLogoUrl } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { Body } from '../../../../styles/textStyles';
import { AppBrandingLogoUpload } from '../../../appBranding/components/AppBrandingLogoUpload';
import { useAppBrandingPracticeInfo } from '../../../appBranding/utils/useAppBrandingPracticeInfo';
import { SettingsBrandingComponentsWrapper } from './SettingsBrandingComponentsWrapper';

export const SettingsBrandingLogoComponent = () => {
  const { practiceId } = useAppBrandingPracticeInfo();
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const brandingLogoUrl = useMainStore(getAppBrandingLogoUrl);
  const setAppBrandingLogoUrl = useMainStore((state) => state.setAppBrandingLogoUrl);

  const [loading, setLoading] = useState(false);
  const [newDownloadUrl, setNewDownloadUrl] = useState<string>();

  const handleSave = async () => {
    if (!newDownloadUrl) return;
    setLoading(true);
    try {
      await updateDoc(doc(db, 'practices/' + practiceId + '/documents/practiceAppCustomization'), {
        logoUrl: newDownloadUrl,
      });
      setAppBrandingLogoUrl(newDownloadUrl);
      setNewDownloadUrl(undefined);
      setEventIndicator('success', 'Änderungen gespeichert');
    } catch (error) {
      console.error(error);
      setEventIndicator('error', 'Fehler beim Speichern. Bitte versuche es erneut.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <SettingsBrandingComponentsWrapper
      title="Praxis Logo"
      description="Ziehe dein Logo einfach per Drag-and-Drop in das Feld oder klicke, um die Datei auszuwählen. So gibst du deiner Physiofit App sofort den Look deiner Praxis!"
      nextButton={{
        text: 'Speichern',
        onClick: handleSave,
        loading: loading,
        disabled: !newDownloadUrl,
      }}
    >
      <ImageContainer>
        {brandingLogoUrl ? <Image src={readCloudflareUrl(brandingLogoUrl)} /> : <Body>Noch kein Logo hochgeladen</Body>}
      </ImageContainer>
      <AppBrandingLogoUpload setLoading={setLoading} setDownloadUrl={setNewDownloadUrl} />
    </SettingsBrandingComponentsWrapper>
  );
};

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: ${colors.Light};
  height: 120px;
`;
const Image = styled.img`
  width: auto;
  height: 100px;
`;
