import { collection, onSnapshot, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../../firebaseConfig';
import { AdminUser } from '../../../../types/Admin/AdminUserType';

export const useAdminUsersConnect = () => {
  const [adminUsers, setAdminUsers] = useState<AdminUser[]>([]);

  useEffect(() => {
    const sub = onSnapshot(query(collection(db, 'adminUsers')), (snapshot) => {
      setAdminUsers(snapshot.docs.map((doc) => doc.data() as AdminUser));
    });

    return sub;
  }, []);
  return adminUsers;
};
