import { StateCreator } from 'zustand';
import { PracticeUser } from '../types/WebUser/PracticeUserType';
import { State } from './mainStore';

export type UserState = {
  firebaseUserId?: string;
  emailVerified?: boolean;
  userMail?: string;
  isMitarbeiter?: boolean;
  user?: PracticeUser;
  setUserData: (user: PracticeUser) => void;
  setAuthData: (firebaseUserId?: string, emailVerified?: boolean, userMail?: string, isMitarbeiter?: boolean) => void;
  resetUserState: () => void;
};

const initialState = {
  firebaseUser: undefined,
  emailVerified: false,
  userMail: undefined,
  user: undefined,
};

export const createUserSlice: StateCreator<UserState> = (set) => ({
  ...initialState,
  setUserData: (user) => set({ user }),
  setAuthData: (firebaseUserId, emailVerified, userMail, isMitarbeiter) =>
    set({ firebaseUserId, emailVerified, userMail, isMitarbeiter }),
  resetUserState: () => set(initialState),
});

export const getFirebaseUserId = (state: UserState) => state.firebaseUserId;
export const getUserMail = (state: UserState) => state.userMail;
export const getIsAuthenticated = (state: UserState) => Boolean(getFirebaseUserId(state));
export const getUserIsLoggedIn = (state: State) => {
  const firebaseLoggedIn = getIsAuthenticated(state);
  const dataLoaded = Boolean(state.user) && state.chainData !== undefined;
  return firebaseLoggedIn && dataLoaded;
};
export const getEmailVerified = (state: UserState) => state.emailVerified ?? false;
export const getMitarbeiterUserIsLoggedIn = (state: State) => Boolean(getFirebaseUserId(state));
export const getUserChainId = (state: UserState) => state.user?.chainId;
export const getUserFirstName = (state: UserState) => state.user?.privateInfo?.firstName;
export const getUserLastName = (state: UserState) => state.user?.privateInfo?.lastName;
export const getUserId = (state: UserState) => state.user?.id;
export const getUser = (state: UserState) => state.user;
export const getUserReferralOrigin = (state: UserState) => state.user?.onboardingInfo?.referralOrigin;
export const getUserMailToChangeTo = (state: UserState) => state.user?.privateInfo?.emailToChangeTo;
export const getIsMitarbeiter = (state: UserState) => state.isMitarbeiter;
