import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonGroup } from '../../../core/components/buttons/ButtonGroup';
import { RadioButton } from '../../../core/components/buttons/RadioButton';
import { InfoText } from '../../../core/components/text/InfoText';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { getInvitationEmail } from '../../../store/invitationSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { Small } from '../../../styles/textStyles';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { useCheckForInvitationId } from '../utils/checkForInvitation';
import { checkIfValidEmail } from '../utils/checkIfValidMail';
import { createPracticeChainOwner } from '../utils/createPracticeChainOwner';

const getProgress = (email: string, password: string, conditionAccepted) => {
  let result = 0;
  if (email !== '') {
    result++;
  }
  if (password !== '') {
    result++;
  }
  if (conditionAccepted) {
    result++;
  }
  return result;
};

export const RegistrationScreen = () => {
  const { hasInvitation } = useCheckForInvitationId();
  const navigate = useNavigate();
  const email = useMainStore(getInvitationEmail);
  const [newEmail, setNewEmail] = useState(email ?? '');
  const [newPassword, setNewPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [conditionsAccepted, setConditionsAccepted] = useState(false);
  const [newsletterAccepted, setNewsletterAccepted] = useState(false);
  const { t } = useTranslation();
  const [_conditionsError, setConditionsError] = useState(false);

  const handleRegister = async () => {
    setErrorEmail('');
    setLoading(true);
    if (!checkIfValidEmail(newEmail)) {
      setErrorEmail(t('ONBOARDING-REGISTER-ERROR-EMAIL-NOT-VALID'));
    }
    if (newPassword.length < 8) {
      setErrorPassword(t('ONBOARDING-REGISTER-ERROR-PASSWORD-SHORT'));
    }
    if (!conditionsAccepted) {
      setConditionsError(true);
    }
    if (!checkIfValidEmail(newEmail) || newPassword.length < 8 || !conditionsAccepted) {
      setLoading(false);
      return;
    }
    try {
      const auth = getAuth();
      await createUserWithEmailAndPassword(auth, newEmail, newPassword);
      await createPracticeChainOwner(newsletterAccepted);
      navigate('../../onboarding/verification');
    } catch (error: any) {
      console.log(error);
      if (error.code === 'auth/email-already-in-use') {
        setErrorEmail(t('ONBOARDING-REGISTER-ERROR-EMAIL-ALREADY-IN-USE'));
      } else {
        setErrorEmail(t('ONBOARDING-REGISTER-ERROR-UNKNOWN'));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = () => {
    navigate('../login');
  };

  const handleChangeEmail = (value: string) => {
    setNewEmail(value);
    setErrorEmail('');
  };

  if (!hasInvitation) {
    return <Navigate to="../login" replace={true} />;
  }
  return (
    <OnboardingScreenContainer>
      <InfoText headline={t('ONBOARDING-REGISTRATION-TITLE')} text={[t('ONBOARDING-REGISTRATION-SUBTITLE')]} />
      <InputContainer
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <SingleTextInput
          value={newEmail}
          onChange={handleChangeEmail}
          placeholder={t('ONBOARDING-REGISTRATION-EMAIL-FIELD')}
          error={errorEmail}
          type="email"
          autoComplete="username"
        />
        <SingleTextInput
          value={newPassword}
          onChange={setNewPassword}
          placeholder={t('ONBOARDING-REGISTRATION-PASSWORD')}
          error={errorPassword}
          type="password"
          autoComplete="new-password"
        />
        <CheckboxContainer>
          <RadioButton checked={conditionsAccepted} onPress={() => setConditionsAccepted(!conditionsAccepted)} />
          <StyledSmall>
            {t('ONBOARDING-CONDITIONS-CONSENT-FIRST-PART')}
            <StyledLink
              href="https://physiofitapp.notion.site/Vertrag-und-Allgemeine-Gesch-ftsbedingungen-AGB-fedeef5a26c346829e5e86b42938e344"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('ONBOARDING-CONDITIONS-CONSENT-FIRST-LINK-PART')}
            </StyledLink>
            {t('ONBOARDING-CONDITIONS-CONSENT-SECOND-PART')}
            <StyledLink
              href="https://physiofitapp.notion.site/Datenschutzerkl-rung-PHYSIOFIT-Partner-4c964573b61c4b4e88d9efbd37b2f15d"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('ONBOARDING-CONDITIONS-CONSENT-SECOND-LINK-PART')}
            </StyledLink>
            {t('ONBOARDING-CONDITIONS-CONSENT-THIRD-PART')}
          </StyledSmall>
        </CheckboxContainer>
        <CheckboxContainer>
          <RadioButton checked={newsletterAccepted} onPress={() => setNewsletterAccepted(!newsletterAccepted)} />
          <StyledSmall>{t('ONBOARDING-NEWSLETTER-CONSENT')}</StyledSmall>
        </CheckboxContainer>
      </InputContainer>
      <ButtonGroup
        textButton={{
          text: t('ONBOARDING-BUTTON-LOGIN'),
          onClick: handleLogin,
        }}
        progressButton={{
          text: t('ONBOARDING-BUTTON-NEXT'),
          onClick: handleRegister,
          numberOfSteps: 3,
          currentStep: getProgress(newEmail, newPassword, conditionsAccepted),
          loading: loading,
        }}
      />
    </OnboardingScreenContainer>
  );
};

const InputContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
`;

const StyledSmall = styled(Small)`
  color: ${colors.Medium};
  padding-top: 10px;
  width: 90%;
`;

const StyledLink = styled.a`
  text-decoration: underline;
`;
