import { useState } from 'react';
import { getAppBrandingServices } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { AppBrandingServicesInput } from '../../../appBranding/components/AppBrandingServicesInput';
import { useAppBrandingPracticeInfo } from '../../../appBranding/utils/useAppBrandingPracticeInfo';
import { SettingsBrandingComponentsWrapper } from './SettingsBrandingComponentsWrapper';

export const SettingsBrandingServices = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { uploadAppBrandingData, uploading } = useAppBrandingPracticeInfo();
  const zustandAppBrandingServices = useMainStore(getAppBrandingServices);
  const [initialAppBrandingServices, setInitialAppBrandingServices] = useState(zustandAppBrandingServices);
  const nextButtonDisabled = zustandAppBrandingServices === initialAppBrandingServices;
  const handleSave = async () => {
    setInitialAppBrandingServices(zustandAppBrandingServices);
    await uploadAppBrandingData();
    setEventIndicator('success', 'Änderungen gespeichert');
  };
  return (
    <SettingsBrandingComponentsWrapper
      title="Leistungen"
      description="Gib deine Therapie- und Behandlungsangebote an, damit sich deine Patienten ein umfassendes Bild deiner Leistungen machen können."
      nextButton={{
        text: 'Speichern',
        onClick: handleSave,
        loading: uploading,
        disabled: nextButtonDisabled,
      }}
    >
      <AppBrandingServicesInput />
    </SettingsBrandingComponentsWrapper>
  );
};
