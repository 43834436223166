import { useState } from 'react';
import { useMainStore } from '../../../store/mainStore';
import { Asset } from '../../../types/AssetType';
import { Therapist } from '../../../types/Practices/TherapistType';

export const useHandleTherapistsLinks = (practiceId: string, therapist: Therapist) => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const [copyLinkLoading, setCopyLinkLoading] = useState(false);
  const therapistProfilePicture = therapist.profilePicture as Asset;

  const practiceDomain =
    import.meta.env.VITE_ENV === 'production'
      ? 'https://app.physiofit.app'
      : 'https://io-physiofit-development.web.app';
  const therapistUrl = `${practiceDomain}/newPatient?pId=${practiceId}&tId=${therapist.id}`;

  const copyLink = () => {
    try {
      setCopyLinkLoading(true);
      setTimeout(() => {
        setCopyLinkLoading(false);
      }, 1000);
      navigator.clipboard.writeText(therapistUrl);
      setEventIndicator('success', 'Link kopiert');
    } catch (error) {
      console.error('Error copying link: ', error);
      setEventIndicator('error', 'Fehler beim Kopieren des Links');
    }
  };

  const openLink = () => {
    window.open(therapistUrl, '_blank');
  };

  return {
    therapistProfilePicture,
    therapistUrl,
    openLink,
    copyLink,
    copyLinkLoading,
  };
};
