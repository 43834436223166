import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { CustomButton } from '../../../core/components/buttons/CustomButton';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';
import { colors } from '../../../styles/colors';

type Props = {
  imageUrl: string;
  handleDelete: () => void;
  moveLeft?: () => void;
  moveRight?: () => void;
};

export const AppBrandingPracticeImagePreview = ({ imageUrl, handleDelete, moveLeft, moveRight }: Props) => {
  return (
    <Container $url={imageUrl}>
      <IconContainer onClick={handleDelete}>
        <GenericSvg name="Close" size={24} />
      </IconContainer>
      <MoveContainer>
        {moveLeft ? (
          <MoveButton onClick={moveLeft}>
            <GenericSvg name="ArrowLeft" size={24} />
          </MoveButton>
        ) : (
          <div />
        )}
        {moveRight ? (
          <MoveButton onClick={moveRight}>
            <TurnedGenericSvg name="ArrowLeft" size={24} />
          </MoveButton>
        ) : (
          <div />
        )}
      </MoveContainer>
    </Container>
  );
};

const IconContainer = styled(CustomButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${hexWithOpacity(colors.Light, 0.8)};
  padding: 3px;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 5px;
  right: 5px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    opacity: 1;
  }
`;

const MoveContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 5px;
  left: 0;
  padding: 0 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    opacity: 1;
  }
`;

const MoveButton = styled(CustomButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${hexWithOpacity(colors.Light, 0.8)};
  padding: 3px;
  border-radius: 50%;
`;

const Container = styled.div<{ $url: string }>`
  width: 142px;
  height: 100px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-image: url(${({ $url }) => $url});
  background-position: center;
  background-size: cover;
  padding: 5px;
  position: relative;

  &:hover ${IconContainer}, &:hover ${MoveContainer} {
    opacity: 1;
  }
`;

const TurnedGenericSvg = styled(GenericSvg)`
  transform: rotate(180deg);
`;
