import { CSSProperties } from 'react';
import styled from 'styled-components';
import { colors } from './colors';

export type TextStyles = {
  h1: CSSProperties;
  h2: CSSProperties;
  h3: CSSProperties;
  h4: CSSProperties;
  body: CSSProperties;
  small: CSSProperties;
  smallCondensed: CSSProperties;
  mini: CSSProperties;
  button: CSSProperties;
  numbers: CSSProperties;
};

const BARLOW = 'Barlow';
const LORA = 'Lora';
const UBUNTU = 'Ubuntu';
const UBUNTU_MONO = 'Ubuntu Mono';

export const H1 = styled.h1`
  font-family: ${LORA};
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 0;
  color: ${colors.Dark};
`;

export const H2 = styled.h2`
  font-family: ${LORA};
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  font-style: normal;
  letter-spacing: 2%;
  color: ${colors.Dark};
`;

export const H3 = styled.h3`
  font-family: ${UBUNTU};
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
  font-style: normal;
  letter-spacing: 2%;
  color: ${colors.Dark};
`;

export const H4 = styled.h4`
  font-family: ${UBUNTU};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  letter-spacing: 0;
  color: ${colors.Dark};
`;

export const Body = styled.p`
  font-family: ${UBUNTU};
  font-size: 15px;
  line-height: 26px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0;
  color: ${colors.Dark};
`;
export const Body60 = styled(Body)`
  opacity: 0.6;
`;

export const Small = styled.p`
  font-family: ${UBUNTU};
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0;
  color: ${colors.Dark};
`;

export const Mini = styled.p`
  font-family: ${UBUNTU};
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0;
  color: ${colors.Dark};
`;

export const ButtonText = styled.p`
  font-family: ${UBUNTU};
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  font-style: normal;
  letter-spacing: 0;
  color: ${colors.Dark};
`;

export const TextButtonText = styled.p`
  /* Button TextOnly */
  font-family: ${UBUNTU};
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  font-style: normal;
  letter-spacing: 0;
  color: ${colors.Dark};
`;

export const Numbers = styled.p`
  font-family: ${BARLOW};
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
  font-style: normal;
  letter-spacing: 0;
  color: ${colors.Dark};
`;

export const MonoText = styled.p`
  font-family: ${UBUNTU_MONO};
  font-size: 16px;
  line-height: 26px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: 0;
  color: ${colors.Medium};
`;
