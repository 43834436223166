import { CSSProperties, ForwardedRef, forwardRef, InputHTMLAttributes, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { Mini } from '../../../styles/textStyles';
import { hexWithOpacity } from '../../utils/hexWithOpacity';
import { IconButton } from '../buttons/IconButton';

type Props = {
  error?: string;
  value: string;
  onChange?: (_value: string) => void;
  placeholder?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  style?: CSSProperties;
  disabled?: boolean;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  autoComplete?: InputHTMLAttributes<HTMLInputElement>['autoComplete'];
  onSubmit?: InputHTMLAttributes<HTMLInputElement>['onSubmit'];
  id?: string;
  label?: string;
  loadingData?: boolean;
  noPasswordManager?: boolean;
  withoutError?: boolean;
};

export const SingleTextInput = forwardRef((props: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const [textHidden, setTextHidden] = useState(true);
  const handleBlur = () => {
    props.onBlur?.();
  };

  const handleFocus = () => {
    props.onFocus?.();
  };

  return (
    <Container style={props.style}>
      {props.label && <StyledMini>{props.label}</StyledMini>}
      {props.loadingData ? (
        <SkeletonWrapper>
          <Skeleton height={42} />
        </SkeletonWrapper>
      ) : (
        <InputWrapper>
          <StyledInput
            id={props.id}
            disabled={props.disabled}
            ref={ref}
            type={props.type === 'password' ? (textHidden ? 'password' : 'text') : props.type}
            value={props.value}
            onChange={(event) => props.onChange?.(event.target.value)}
            placeholder={props.placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onSubmit={props.onSubmit}
            {...(props.noPasswordManager && {
              'data-1p-ignore': true,
              'data-bwignore': true,
              'data-lpignore': true,
              'data-form-type': 'other',
            })}
          />
          {props.type === 'password' && (
            <IconButtonWrapper>
              <IconButton
                iconName={textHidden ? 'EyeClosed' : 'EyeOpen'}
                onClick={() => setTextHidden(!textHidden)}
                size={24}
                stroke={colors.Primary}
              />
            </IconButtonWrapper>
          )}
        </InputWrapper>
      )}
      {!props.withoutError && (
        <Error>
          <ErrorText>{props.error ?? ' '}</ErrorText>
        </Error>
      )}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const IconButtonWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
`;

const ErrorText = styled(Mini)`
  color: ${colors.LightRed};
`;

const Error = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-left: 5px;
  padding-right: 10px;
  max-lines: 1;
  height: 20px;
`;

const StyledInput = styled.input`
  width: 100%;
  border-radius: 10px;
  border: 1.5px solid ${hexWithOpacity(colors.Medium, 0.25)};
  background-color: ${colors.White};
  color: ${colors.Dark};
  padding: 8px 12px;
  font:
    400 16px/26px Ubuntu,
    sans-serif;
  outline: none;
  box-shadow: none;
  transition: border-bottom-color 0.2s ease;

  &::placeholder {
    color: ${colors.Dark};
    opacity: 0.5;
  }
  &:hover:not(:disabled):not(:focus) {
    border: 1.5px solid ${colors.Medium};
  }
  &:focus {
    outline: none;
    border: 1.5px solid ${colors.Primary};
  }
  &:disabled {
    opacity: 0.5;
  }
`;

const StyledMini = styled(Mini)`
  color: ${hexWithOpacity(colors.Dark, 0.6)};
  padding-bottom: 5px;
`;

const SkeletonWrapper = styled.div`
  width: 100%;
`;
