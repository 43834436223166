import { useState } from 'react';
import { getAppBrandingClaim } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { AppBrandingClaimInput } from '../../../appBranding/components/AppBrandingClaimInput';
import { useAppBrandingPracticeInfo } from '../../../appBranding/utils/useAppBrandingPracticeInfo';
import { SettingsBrandingComponentsWrapper } from './SettingsBrandingComponentsWrapper';

export const SettingsBrandingPracticeSlogan = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { uploadAppBrandingData, uploading } = useAppBrandingPracticeInfo();
  const zustandAppBrandingClaim = useMainStore(getAppBrandingClaim);
  const [initialAppBrandingClaim, setInitialAppBrandingClaim] = useState(zustandAppBrandingClaim);
  const nextButtonDisabled = zustandAppBrandingClaim === initialAppBrandingClaim;
  const handleSave = async () => {
    setInitialAppBrandingClaim(zustandAppBrandingClaim);
    await uploadAppBrandingData();
    setEventIndicator('success', 'Änderungen gespeichert');
  };

  return (
    <SettingsBrandingComponentsWrapper
      title="Praxisslogan"
      description="Formuliere eine kurze Kernbotschaft an deine Patienten und zeige damit wofür deine Praxis steht."
      nextButton={{
        text: 'Speichern',
        onClick: handleSave,
        loading: uploading,
        disabled: nextButtonDisabled,
      }}
    >
      <AppBrandingClaimInput />
    </SettingsBrandingComponentsWrapper>
  );
};
