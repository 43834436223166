import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InfoText } from '../../../core/components/text/InfoText';
import { AppBrandingOpeningHours } from '../components/AppBrandingOpeningHours';
import { AppBrandingScreenContainer } from '../components/AppBrandingScreenContainer';
import { useAppBrandingPracticeInfo } from '../utils/useAppBrandingPracticeInfo';

export const AppBrandingOpeningScreen = () => {
  const { t } = useTranslation();
  const { practiceName } = useAppBrandingPracticeInfo();

  const navigate = useNavigate();

  const handleNext = () => {
    navigate('../therapists');
  };

  const handleBack = () => {
    navigate('../address');
  };

  return (
    <AppBrandingScreenContainer
      handleBack={handleBack}
      nextButton={{
        text: 'Weiter',
        onClick: handleNext,
        numberOfSteps: 3,
        currentStep: 3,
      }}
    >
      <StyledInfoText
        headline={'Öffnungszeiten'}
        text={[
          'Trage hier deine Öffnungszeiten ein, damit Patienten immer informiert sind, wann deine Praxis geöffnet ist.',
        ]}
        preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName: practiceName })}
      />
      <AppBrandingOpeningHours />
    </AppBrandingScreenContainer>
  );
};
const StyledInfoText = styled(InfoText)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
