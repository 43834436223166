import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MainButton } from '../../../../core/components/buttons/MainButton';
import { NavigationHeader } from '../../../../navigation/components/NavigationHeader';
import { AdminSalesCalculatorCompensationsComponent } from '../components/AdminSalesCalculatorCompensationsComponent';
import { AdminSalesCalculatorPartnerFeesComponent } from '../components/AdminSalesCalculatorPartnerFeesComponent';
import { AdminSalesCalculatorPotentialGainsComponent } from '../components/AdminSalesCalculatorPotentialGainsComponent';

export const priceFormatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

export const AdminSalesCalculatorScreen = () => {
  const navigate = useNavigate();
  const [compensationValue, setCompensationValue] = useState(0);
  const partnerFees = 100;
  const close = () => {
    navigate('/admin/sales/tools');
  };
  const handleNext = () => {
    navigate('/sales-tools/easy-plan-configurator');
  };
  const { t } = useTranslation();
  return (
    <Container>
      <ContentContainer>
        <AdminSalesCalculatorPartnerFeesComponent partnerFees={partnerFees} />
        <AdminSalesCalculatorCompensationsComponent setCompensationValue={setCompensationValue} />
        <AdminSalesCalculatorPotentialGainsComponent compensationValue={compensationValue} partnerFees={partnerFees} />
      </ContentContainer>
      <StyledMainButton text={t('ADMIN-SALES-CONTINUE-BUTTON')} onClick={handleNext} />
      <NavigationHeader onCloseClick={close} />
    </Container>
  );
};

const Container = styled.div`
  max-width: 1920px;
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
  padding: 0 40px;
  overflow: hidden;
`;

const StyledMainButton = styled(MainButton)`
  position: absolute;
  bottom: 20px;
  right: 40px;
`;
