import styled from 'styled-components';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from '../../../styles/breakpoints';
import { colors } from '../../../styles/colors';
import { H4, MonoText, Small } from '../../../styles/textStyles';

type Props = {
  active?: boolean;
  discountText?: string;
  value: string;
  title: string;
  subtitle?: string;
  text?: string;
};

export const CheckoutBillLine = ({ active, discountText, value, title, subtitle, text }: Props) => {
  return (
    <Container $active={active ?? false}>
      <LineContainer>
        <LeftContainer>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </LeftContainer>
        <RightContainer>
          {discountText && <DiscountText>{discountText}</DiscountText>}
          <StyledMono>{value}</StyledMono>
        </RightContainer>
      </LineContainer>
      {text && <StyledSmall>{text}</StyledSmall>}
    </Container>
  );
};

const Container = styled.div<{ $active: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: ${(props) => (props.$active ? 1 : 0.4)};
`;
const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    flex-wrap: wrap;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-end;
  margin-left: 20px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    flex: 1;
    min-width: 100px;
  }
`;

const DiscountText = styled(MonoText)`
  font-weight: 700;
  color: ${colors.LightRed};
  text-decoration: line-through;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    display: none;
  }
`;

const StyledMono = styled(MonoText)`
  font-weight: 700;
  color: ${colors.Medium};
`;

const Title = styled(H4)`
  color: ${colors.Medium};
`;

const Subtitle = styled(Small)`
  color: ${colors.Medium};
`;

const StyledSmall = styled(Small)`
  text-align: left;
  color: ${hexWithOpacity(colors.Medium, 0.65)};
  margin-top: 5px;
  margin-right: 200px;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    margin-right: 0px;
  }
`;
