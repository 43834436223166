import { collection, onSnapshot, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../../firebaseConfig';
import { languageSplitter } from '../../../../utils/languageSplitter';

export type Program = {
  id: string;
  title: string;
  bodyRegion: string;
  level: string;
  exerciseIds: string[];
};

export const useProgramsConnect = () => {
  const [programs, setPrograms] = useState<Program[]>();

  useEffect(() => {
    const fetchPrograms = onSnapshot(query(collection(db, 'content/courses/courses')), (snapshot) => {
      const programsData = snapshot.docs.map((doc) => doc.data());
      const progs = programsData.map((program) => {
        return {
          id: program.id,
          title: languageSplitter(program.title),
          bodyRegion: languageSplitter(program.bodyPart.name),
          level: program.level,
          exerciseIds: program.exerciseIds.slice(0, 10),
        };
      });
      setPrograms(progs as Program[]);
    });
    return fetchPrograms;
  }, []);

  return { programs };
};
