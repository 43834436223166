import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { AccordionComponent } from './AccordionComponent';
import { TextButton } from './buttons/TextButton';

type Props = {
  title: string;
  avatarUrl?: string;
  handleChangeAvatar?: () => void;
  items: {
    title: string;
    onClick: () => void;
    warning?: boolean;
  }[];
};

export const ButtonsAccordionComponent = ({ title, items, avatarUrl, handleChangeAvatar }: Props) => {
  return (
    <AccordionComponent title={title} iconUrl={avatarUrl} onIconClick={handleChangeAvatar}>
      <InnerContainer>
        {items.map((item, index) => (
          <TextButton
            key={index + item.title}
            text={item.title}
            onClick={item.onClick}
            color={item.warning ? colors.LightRed : colors.Dark}
          />
        ))}
      </InnerContainer>
    </AccordionComponent>
  );
};

const InnerContainer = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
