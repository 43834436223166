import { doc, onSnapshot } from 'firebase/firestore';

import { useEffect, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { Chain } from '../../../types/Practices/ChainType';

export const useAdminChainConnect = (chainId?: string) => {
  const [chain, setChain] = useState<Chain>();
  useEffect(() => {
    if (!chainId) {
      return;
    }
    const sub = onSnapshot(doc(db, 'practiceChains/' + chainId), (snapshot) => {
      const data = snapshot.data() as Chain;
      setChain(data);
    });
    return sub;
  }, [chainId]);
  return chain;
};
