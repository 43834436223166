import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ButtonGroup } from '../../../../core/components/buttons/ButtonGroup';
import { ModalContainer } from '../../../../core/components/container/ModalContainer';
import { SingleTextInput } from '../../../../core/components/textInput/SingleTextInput';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { H3 } from '../../../../styles/textStyles';

export const AdminInviteUserModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    navigate('..');
  };

  const inviteUser = async () => {
    setError('');
    setLoading(true);
    try {
      if (!email.includes('@physiofit.app')) {
        setError('Bitte gib eine gültige PhysioFit E-Mail Adresse ein');
        return;
      }
      const functions = getFunctions();
      const call = httpsCallable(functions, 'sendAdminUserInvitation');
      const callableReturnMessage = (await call({
        newMail: email,
        firstName,
        lastName,
        roles: ['admin', 'mitarbeiter'],
      })) as any;
      if (callableReturnMessage?.data?.status === 'error') {
        setError('Fehler: ' + callableReturnMessage?.data?.message);
        return;
      }
      setEventIndicator('success', callableReturnMessage?.data?.message);
      handleClose();
    } catch (error: any) {
      setEventIndicator('error', 'Fehler beim Einladen');
      setError('Fehler: ' + error.message);
      console.error('Error writing document: ', error);
    } finally {
      setLoading(false);
    }
  };

  const disabled = !firstName || !email || !lastName;

  return (
    <ModalContainer handleClose={handleClose}>
      <Container>
        <StyledH3>{'Neuen Mitarbeiter Einladen'}</StyledH3>
        <FormContainer>
          <SingleTextInput id="firstInput" value={firstName} onChange={setFirstName} placeholder={'Vorname'} />
          <SingleTextInput value={lastName} onChange={setLastName} placeholder={'Nachname'} />
          <SingleTextInput value={email} onChange={setEmail} placeholder={'E-Mail (...@physiofit.app)'} error={error} />
        </FormContainer>
        <ButtonGroup
          textButton={{
            text: t('BACK-BUTTON'),
            onClick: handleClose,
            loading,
          }}
          progressButton={{
            currentStep: 0,
            numberOfSteps: 0,
            text: t('SAVE-BUTTON'),
            onClick: inviteUser,
            loading,
            disabled,
          }}
        />
      </Container>
    </ModalContainer>
  );
};

const StyledH3 = styled(H3)`
  color: ${colors.Dark};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
