import { doc, updateDoc } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ProgressButton } from '../../../core/components/buttons/ProgressButton';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { usePracticeConnect } from '../../../core/utils/connectFirestore/usePracticeConnect';
import { db } from '../../../firebaseConfig';
import { getChainId, getChainPractices } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { SMSPhoneMock } from '../../onboarding/components/SMSPhoneMock';
import { SettingsHeadline } from './SettingsHeadline';

export const PracticeNamesSettings = () => {
  const { t } = useTranslation();
  const practiceId = useParams().practiceId;
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const chainPractices = useMainStore(getChainPractices);
  const chainId = useMainStore(getChainId);

  const { practice, loadingPractice } = usePracticeConnect(practiceId);

  const [smsLoading, setLoading] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  const [smsName, setSmsName] = useState(practice?.smsName ?? '');
  const [practiceName, setPracticeName] = useState(practice?.name ?? '');
  const [smsError, setSMSError] = useState('');
  const [nameError, setNameError] = useState('');

  useEffect(() => {
    setSmsName(practice?.smsName ?? '');
  }, [practice?.smsName]);

  useEffect(() => {
    setPracticeName(practice?.name ?? '');
  }, [practice?.name]);

  const smsErrorTimeout = useRef<NodeJS.Timeout | null>(null);

  const changeSMSName = (value: string) => {
    let newValue = value;

    let errorMessage = '';
    const specialChars = /[^A-Za-z0-9]/g;
    if (specialChars.test(value)) {
      newValue = value
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/Ä/g, 'Ae')
        .replace(/Ö/g, 'Oe')
        .replace(/Ü/g, 'Ue')
        .replace(/ß/g, 'ss');
      newValue = newValue.replace(specialChars, '');
      errorMessage = 'Keine Umlaute oder Sonderzeichen möglich';
    }
    if (value.length > 15) {
      newValue = newValue.substring(0, 15);
      errorMessage = 'Max. 15 Zeichen';
    }

    if (errorMessage !== '') {
      if (smsErrorTimeout.current) {
        clearTimeout(smsErrorTimeout.current);
      }
      smsErrorTimeout.current = setTimeout(() => {
        setSMSError('');
      }, 2000);

      setSMSError(errorMessage);
    } else {
      setSMSError('');
    }
    setSmsName(newValue);
  };

  const errorTimeout = useRef<NodeJS.Timeout | null>(null);

  const changePracticeName = (value: string) => {
    let newValue = value;

    if (value.length > 40) {
      if (errorTimeout.current) {
        clearTimeout(errorTimeout.current);
      }

      errorTimeout.current = setTimeout(() => {
        setNameError('');
      }, 2000);

      newValue = value.substring(0, 40);
      setNameError('Maximal 40 Zeichen');
    } else {
      setNameError('');
    }
    setPracticeName(newValue);
  };

  const handleSaveSms = async () => {
    setLoading(true);
    try {
      await updateDoc(doc(db, 'practices/' + practiceId), { smsName: smsName.trim() });
      setEventIndicator('success', 'Praxisname für Einladungs-SMS gespeichert');
    } catch (error) {
      console.error('Error writing document: ', error);
      setEventIndicator('error', 'Fehler beim Speichern');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveName = async () => {
    setNameLoading(true);
    try {
      await updateDoc(doc(db, 'practices/' + practiceId), { name: practiceName.trim() });
      const newChainPractices = chainPractices?.map((p) => {
        if (p.id === practiceId) {
          return { ...p, name: practiceName.trim() };
        }
        return p;
      });
      await updateDoc(doc(db, 'practiceChains/' + chainId), {
        practices: newChainPractices,
      });
      setEventIndicator('success', 'Praxisname gespeichert');
    } catch (error) {
      console.error('Error writing document: ', error);
      setEventIndicator('error', 'Fehler beim Speichern');
    } finally {
      setNameLoading(false);
    }
  };

  const smsButtonDisabled =
    loadingPractice || smsName.length === 0 || smsName.length > 15 || practice?.smsName === smsName;
  const nameButtonDisabled = loadingPractice || practiceName.length === 0 || practice?.name === practiceName;
  return (
    <Container>
      <div>
        <SettingsHeadline title="Praxisname festlegen" text="Der Praxisname wird dir und deinen Patienten angezeigt." />
        <SingleTextInput
          loadingData={loadingPractice}
          value={practiceName}
          onChange={(value) => changePracticeName(value)}
          placeholder={t('ONBOARDING-PRACTICE-NAMES-SCREEN-PRACTICE-NAME-PLACEHOLDER')}
          label="Praxisname"
          error={nameError}
        />
        <ButtonContainer>
          <ProgressButton
            onClick={handleSaveName}
            loading={nameLoading}
            disabled={nameButtonDisabled}
            text={'Speichern'}
          />
        </ButtonContainer>
      </div>
      <div>
        <SettingsHeadline
          title="Praxisname für Einladungs-SMS festlegen"
          text="Gib hier den Namen deiner Praxis ein, der in der Einladungs-SMS an deine Patienten angezeigt wird. So wissen deine Patienten direkt, von wem die Nachricht kommt, und können den Übungsplan leichter zuordnen."
        />
        <SingleTextInput
          loadingData={loadingPractice}
          value={smsName}
          onChange={(value) => changeSMSName(value)}
          placeholder={'Max. 15 Zeichen'}
          label={`Praxisname für Einladungs-SMS`}
          error={smsError}
        />
        <ButtonContainer>
          <ProgressButton
            onClick={handleSaveSms}
            loading={smsLoading}
            disabled={smsButtonDisabled}
            text={'Speichern'}
          />
        </ButtonContainer>
      </div>
      <SMSPhoneMock smsName={smsName} practiceId={practiceId ?? ''} practiceName={practiceName} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding-bottom: 200px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
