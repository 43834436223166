import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { colors } from '../../../../styles/colors';
import { H3 } from '../../../../styles/textStyles';
import { Exercise } from '../../../../types/Content/ExerciseType';
import { SingleImagePreview } from './SingleImagePreview';

type NextExercisesPreviewProps = {
  exercises?: Exercise[];
  setCurrentExercise: (exercise: Exercise) => void;
};

export const NextExercisesPreview = (props: NextExercisesPreviewProps) => {
  return (
    <Container>
      <ThumbnailsContainer>
        <>
          {!props.exercises && (
            <>
              {new Array(5).fill(0).map((_, index) => (
                <SkeletonWrapper key={'skeleton' + index}>
                  <Skeleton height={60} width={70} />
                </SkeletonWrapper>
              ))}
            </>
          )}
          {props.exercises?.map((exercise, index) => (
            <SingleImagePreview key={index} exercise={exercise} setCurrentExercise={props.setCurrentExercise} />
          ))}
        </>
      </ThumbnailsContainer>
      <TextContainer>
        <StyledH3>{`+49 weiter Videos`}</StyledH3>
      </TextContainer>
    </Container>
  );
};

const SkeletonWrapper = styled.div`
  width: 100%;
`;

const Container = styled.div`
  width: 680px;
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1;
`;
const StyledH3 = styled(H3)`
  color: ${colors.Medium};
  text-decoration: underline;
`;

const ThumbnailsContainer = styled.div`
  display: flex;
  gap: 5px;
  overflow-x: hidden;
  flex: 3;
`;
