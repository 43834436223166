import { LoadingSpinner } from '../../../core/components/loading/LoadingSpinner';
import { useOnboardingRedirect } from '../../../navigation/utils/useOnboardingRedirect';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';

export const OnboardingDefaultScreen = () => {
  useOnboardingRedirect();
  return (
    <OnboardingScreenContainer hideBackButton hideProgress>
      <LoadingSpinner />
    </OnboardingScreenContainer>
  );
};
