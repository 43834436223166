import { LanguageSplitter } from "../types/GeneralType";

export const languageSplitter = (
  textObject?:
    | LanguageSplitter
    | { en: string; de: string; fr: string; esp: string }
): string => {
  if (!textObject) {
    return "";
  }
  let lang = "de";
  let text = "";
  if (lang !== "en" && lang !== "de") {
    lang = "en";
  }
  if (lang === "en") {
    text =
      textObject.en !== undefined && textObject.en !== ""
        ? textObject.en
        : textObject.de;
  } else if (lang === "de") {
    text =
      textObject.de !== undefined && textObject.de !== ""
        ? textObject.de
        : textObject.en;
  }
  if (text?.includes("//")) {
    return text;
  } else {
    return text?.replace(/\s*&&&\s*/g, "\n");
  }
};

// export const languageSplitterObject = (object?: { de: any; en: any }) => {
//   if (!object) {
//     return;
//   }
//   const lang = "de";
//   // @ts-expect-error: No Language Setting yet
//   if (lang === "en") {
//     return object.en !== undefined && !isEmpty(object.en)
//       ? object.en
//       : object.de;
//   } else if (lang === "de") {
//     return object.de !== undefined && !isEmpty(object.de)
//       ? object.de
//       : object.en;
//   }
// };
