import { ComponentProps } from 'react';
import { useNavigate } from 'react-router-dom';
import { setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain';
import { getChainPractices, getOnboardingFlags } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { StepCard } from '../components/StepCard';

export const useGetAddTherapistsCardsData = () => {
  const navigate = useNavigate();
  const onboardingFlags = useMainStore(getOnboardingFlags);
  const practices = useMainStore(getChainPractices);
  const practicesFlags = onboardingFlags?.practices;
  const openAddTherapistsPage = (id?: string) => {
    if (!id) return;
    navigate(`/practice-settings/${id}/team/add-therapist`);
    setTimeout(() => {
      setChainOnboardingFlag(`practices.${id}.addedTherapists`);
    }, 50);
  };
  const addTherapistsElements: Array<ComponentProps<typeof StepCard>> = [];
  if (practicesFlags) {
    Object.entries(practicesFlags)?.forEach(([key, element]) => {
      const practice = practices?.find((practice) => practice.id === key);
      addTherapistsElements.push({
        title: `Dein Team am Standort ${practice?.name}`,
        active: element.addedTherapists,
        content: `Vervollständige oder erweitere jetzt die Liste deiner Therapeuten für den Standort ${practice?.name}.`,
        mainButton: {
          title: 'Team öffnen',
          onClick: () => openAddTherapistsPage(practice?.id),
        },
        variant: 'button',
      });
    });
  }
  return addTherapistsElements;
};
