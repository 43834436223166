import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { LoadingSpinner } from '../../../core/components/loading/LoadingSpinner';
import { InfoText } from '../../../core/components/text/InfoText';
import { NavigationHeader } from '../../../navigation/components/NavigationHeader';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';
import { colors } from '../../../styles/colors';
import { Body } from '../../../styles/textStyles';
import { OnboardingCheckoutForm } from '../components/OnboardingCheckoutForm';
import { useCreateSubscription } from '../utils/useCreateSubscription';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

const stripe = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

export const OnboardingCheckoutScreen = () => {
  const navigate = useNavigate();
  const { practiceId } = useOnboardingPracticeInfo();

  const { clientSecret, loading } = useCreateSubscription();

  if (!practiceId) {
    return <Navigate to="../../practices-names" />;
  } else if (!clientSecret && !loading) {
    return <Navigate to={`../bill`} />;
  }

  const handleBack = () => {
    navigate('../bill');
  };

  return (
    <>
      <NavigationHeader onBackClick={handleBack} />
      <Container>
        <InfoText
          headline={'Deine Physiofit Partnergebühr'}
          text={[
            'Bitte gib deine Zahlungsinformationen ein um die Zahlungsabwicklung abzuschließen. Nach der Zahlung findest du alle Rechnungen in deinem Praxisportal.',
          ]}
        />
        {!clientSecret ? (
          <LoadingContainer>
            <LoadingSpinner color={colors.Primary} />
            <LoadingText>{'Lade Zahlungsinformationen'}</LoadingText>
          </LoadingContainer>
        ) : (
          <Elements
            stripe={stripe}
            options={{
              locale: 'de',
              appearance,
              clientSecret,
            }}
          >
            <OnboardingCheckoutForm />
          </Elements>
        )}
      </Container>
    </>
  );
};

const appearance = {
  theme: 'stripe' as any,
  variables: {
    colorPrimary: colors.Primary,
    colorText: colors.Dark,
  },
};

const Container = styled.div<{ $hideProgress?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 640px;
  background-color: 'blue';
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    max-width: 640px;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    gap: 40px;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 500px;
`;

const LoadingText = styled(Body)`
  color: ${colors.Medium};
`;
