import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useState } from 'react';
import { useMainStore } from '../../../store/mainStore';
import { useOnboardingPracticeInfo } from '../../onboarding/utils/useOnboardingPracticeInfo';
import { AppBrandingMediaUpload } from './AppBrandingMediaUpload';

type Props = {
  setLoading: (loading: boolean) => void;
  setDownloadUrl?: (url: string) => void;
};

export const AppBrandingLogoUpload = ({ setLoading, setDownloadUrl }: Props) => {
  const { practiceId } = useOnboardingPracticeInfo();
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);

  const [file, setFile] = useState<File | null>();
  const setAppBrandingLogoUrl = useMainStore((state) => state.setAppBrandingLogoUrl);

  const MAX_FILE_SIZE_MB = 5;
  const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

  const handleFile = async (files: FileList) => {
    const file = files[0];
    const fileSizeMB = Math.round((file.size / 1024 / 1024) * 1000) / 1000;
    if (file.size > MAX_FILE_SIZE) {
      alert(
        `Die Datei ist zu groß (${fileSizeMB}MB). Bitte wähle eine Datei mit einer Größe von maximal ${MAX_FILE_SIZE_MB}MB.`,
      );
      return;
    }
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      setLoading(true);
      setFile(file);
      const storage = getStorage();
      const storageRef = ref(storage, `practiceLogos/${practiceId}/practiceLogo`);
      try {
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        if (setDownloadUrl) {
          setDownloadUrl(downloadURL);
        } else {
          setAppBrandingLogoUrl(downloadURL);
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        setEventIndicator('error', 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.');
      } finally {
        setLoading(false);
      }
    } else {
      alert('Bitte verwende nur Dateien vom Typ JPG oder PNG.');
    }
  };

  return <AppBrandingMediaUpload maxMBFileSize={MAX_FILE_SIZE_MB} handleUpload={handleFile} file={file} type="image" />;
};
