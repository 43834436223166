import { getFunctions, httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { getInvitationCustomSubscriptionId } from '../../../store/invitationSlice';
import { useMainStore } from '../../../store/mainStore';

type InvoiceItem = {
  name: string;
  description: string;
  price: number;
  quantity: number;
  priceReduction: number;
};

export const useGetCustomSubscriptionItems = () => {
  const customSubscriptionId = useMainStore(getInvitationCustomSubscriptionId);

  const [items, setItems] = useState<InvoiceItem[]>();
  const [nettoPrice, setNettoPrice] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const [bruttoPrice, setBruttoPrice] = useState<number>(0);

  useEffect(() => {
    const asyncFunc = async () => {
      const functions = getFunctions();
      const call = httpsCallable(functions, 'getStripeSubscriptionInvoiceItems');
      const callableReturnMessage = (await call({
        subscriptionId: customSubscriptionId,
      })) as any;
      if (callableReturnMessage?.data?.status === 'success') {
        const { invoiceItems, nettoTotal, taxTotal, bruttoTotal } = callableReturnMessage?.data;
        setItems(invoiceItems);
        setNettoPrice(nettoTotal);
        setTax(taxTotal);
        setBruttoPrice(bruttoTotal);
      }
    };
    if (customSubscriptionId) {
      asyncFunc();
    }
  }, [customSubscriptionId]);

  return { items, loadingItems: !items, nettoPrice, tax, bruttoPrice };
};
