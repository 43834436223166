import { ForwardedRef, forwardRef } from 'react';
import styled from 'styled-components';
import PhoneMock from '../../../assets/images/MockPhoneSMS.png';
import { SMSPhoneMockContent } from './SMSPhoneMockContent';

type Props = {
  className?: string;
  practiceName?: string;
  smsName: string;
  practiceId: string;
};
export const SMSPhoneMock = forwardRef(
  ({ className, practiceName, practiceId, smsName }: Props, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <Container className={className} ref={ref}>
        <PhoneImage>
          <SMSPhoneMockContent practiceName={practiceName} practiceId={practiceId} smsName={smsName} />
        </PhoneImage>
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PhoneImage = styled.div`
  position: relative;
  height: 675px;
  width: 420px;
  background-image: url(${PhoneMock});
  background-size: cover;
  padding: 0px 64px;
  padding-bottom: 22px;
`;
