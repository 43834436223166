import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { getChainPractices } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { Practice } from '../../../types/Practices/PracticeType';

export const usePracticeConnect = (practiceId?: string) => {
  const [practice, setPractice] = useState<Practice>();
  useEffect(() => {
    if (!practiceId) return;
    const sub = onSnapshot(doc(db, 'practices/' + practiceId), (practiceSnapshot) => {
      const practiceData = practiceSnapshot.data() as Practice;
      setPractice(practiceData);
    });
    return sub;
  }, [practiceId]);

  return {
    loadingPractice: practice === undefined,
    practice,
  };
};

export const useChainPracticesConnect = () => {
  const practiceIds = useMainStore(getChainPractices)?.map((p) => p.id);
  const [practices, setPractices] = useState<Practice[]>([]);
  useEffect(() => {
    if (!practiceIds) return;
    const subs = practiceIds.map((practiceId) => {
      return onSnapshot(doc(db, 'practices/' + practiceId), (practiceSnapshot) => {
        const practiceData = practiceSnapshot.data() as Practice;
        setPractices((prev) => [...prev, practiceData]);
      });
    });
    return () => subs.forEach((sub) => sub());
  }, [practiceIds]);

  return {
    loadingPractices: practices.length === 0,
    practices,
  };
};
