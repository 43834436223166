import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InfoText } from '../../../core/components/text/InfoText';
import { getAppBrandingAddress, getAppBrandingContact, getAppBrandingMapsInfo } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';
import { AppBrandingAddressInput } from '../components/AppBrandingAddressInput';
import { AppBrandingScreenContainer } from '../components/AppBrandingScreenContainer';
import { saveMapImageToFirebase } from '../utils/saveMapImageToFirebase';
import { useAppBrandingPracticeInfo } from '../utils/useAppBrandingPracticeInfo';

export const AppBrandingAddressScreen = () => {
  const { t } = useTranslation();
  const { practiceName, practiceId } = useAppBrandingPracticeInfo();
  const address = useMainStore(getAppBrandingAddress);
  const contact = useMainStore(getAppBrandingContact);
  const mapsInfo = useMainStore(getAppBrandingMapsInfo);
  const setMapsImageUrl = useMainStore((state) => state.setAppBrandingMapsImageUrl);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleNext = async () => {
    setLoading(true);
    if (!mapsInfo?.mapsStaticImageUrl) return;
    try {
      const mapsImageUrl = await saveMapImageToFirebase(mapsInfo?.mapsStaticImageUrl, practiceId);
      setMapsImageUrl(mapsImageUrl);
      navigate('../opening');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate('../logo');
  };

  const disabled =
    !address ||
    !Object.values(address).every((value) => value && value !== '') ||
    !contact ||
    !Object.values(contact).every((value) => value && value !== '') ||
    !mapsInfo ||
    !Object.values(mapsInfo).every((value) => value && value !== '');

  const calculateCurrentStep = () => {
    let result = 0;
    if (address && Object.values(address).every((value) => value && value !== '')) {
      result++;
    }
    if (contact?.mail) {
      result++;
    }
    if (contact?.phone) {
      result++;
    }
    return result;
  };

  const currentStep = calculateCurrentStep();

  return (
    <AppBrandingScreenContainer
      handleBack={handleBack}
      nextButton={{
        text: 'Weiter',
        onClick: handleNext,
        numberOfSteps: 3,
        currentStep,
        loading: loading,
        disabled: disabled,
      }}
    >
      <div>
        <StyledInfoText
          headline={'Praxis Adresse'}
          text={[
            'Erleichtere Patienten den Weg zu deiner Praxis. Die Standort-Adresse wird in der App hinterlegt und erlaubt deinen Patienten direkt mit der Navigation zu starten. Wähle deine Praxis direkt auf Google Maps aus um die Integration zu vereinfachen.',
          ]}
          preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName: practiceName })}
        />
        <AppBrandingAddressInput setLoading={setLoading} practiceId={practiceId} />
      </div>
    </AppBrandingScreenContainer>
  );
};
const StyledInfoText = styled(InfoText)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
