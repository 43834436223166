import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useState } from 'react';
import { useMainStore } from '../../../store/mainStore';
import { useOnboardingPracticeInfo } from '../../onboarding/utils/useOnboardingPracticeInfo';
import { AppBrandingMediaUpload } from './AppBrandingMediaUpload';

type Props = {
  setLoading: (loading: boolean) => void;
};

export const AppBrandingVideoUpload = ({ setLoading }: Props) => {
  const { practiceId } = useOnboardingPracticeInfo();
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);

  const [file, setFile] = useState<File | null>();

  const uploadVideoCloudflare = async (downloadUrl: string, file: File) => {
    const functions = getFunctions();
    const call = httpsCallable(functions, 'uploadVideoToCloudflare');
    const response = (await call({ videoUrl: downloadUrl, fileName: file.name, practiceId })) as any;
    console.log(response);
    if (response?.data?.data?.success) {
      setEventIndicator('success', 'Das Video wurde erfolgreich hochgeladen.');
    } else {
      setEventIndicator('error', 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.');
    }
  };

  const MAX_FILE_SIZE_MB = 500;
  const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

  const handleFile = async (files: FileList) => {
    const file = files[0];
    const fileSizeMB = Math.round((file.size / 1024 / 1024) * 1000) / 1000;
    if (file.size > MAX_FILE_SIZE) {
      alert(
        `Die Datei ist zu groß (${fileSizeMB}MB). Bitte wähle eine Datei mit einer Größe von maximal ${MAX_FILE_SIZE_MB}MB.`,
      );
      return;
    }
    console.log(file.type);
    if (file.type === 'video/mp4') {
      setLoading(true);
      setFile(file);
      const storage = getStorage();
      const storageRef = ref(storage, `onboardingVideos/${practiceId}/introVideo.mp4`);
      try {
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        await uploadVideoCloudflare(downloadURL, file);
      } catch (error) {
        console.error('Error uploading file:', error);
        setEventIndicator('error', 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.');
      } finally {
        setLoading(false);
      }
    } else {
      alert('Bitte verwende nur Dateien vom Typ MP4.');
    }
  };

  return <AppBrandingMediaUpload maxMBFileSize={MAX_FILE_SIZE_MB} handleUpload={handleFile} file={file} type="video" />;
};
