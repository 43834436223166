import Skeleton from 'react-loading-skeleton';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useTherapistsConnect } from '../../../core/utils/connectFirestore/useTherapistsConnect';
import { useAppBrandingPracticeInfo } from '../utils/useAppBrandingPracticeInfo';
import { AppBrandingTherapist } from './AppBrandingTherapist';

type Props = {
  setUploading: (uploading: boolean) => void;
};

export const AppBrandingTherapistsOverview = ({ setUploading }: Props) => {
  const { practiceId } = useAppBrandingPracticeInfo();
  const { loadingTherapists, therapists } = useTherapistsConnect(practiceId);

  return (
    <Container>
      {loadingTherapists ? (
        <SkeletonWrapper>
          <Skeleton height={72} count={3} borderRadius={10} />
        </SkeletonWrapper>
      ) : (
        therapists?.map((therapist) => (
          <AppBrandingTherapist key={therapist.id} therapist={therapist} setUploading={setUploading} />
        ))
      )}
      <Outlet />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  padding-bottom: 20px;
`;

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
