export type PracticeUser = {
  id: string;
  chainId: string;
  privateInfo: PrivateInfo;
  accessControl: AccessControl;
  onboardingInfo?: OnboardingInfo;
  creationDate?: string;
  terms: TermsInfo;
  hubspotData: {
    contactId: string;
    dealId: string;
    initialOwnerId: string;
  };
};

type TermsInfo = {
  agb: {
    accepted: boolean;
    timestamp: number;
  };
  datenschutz: {
    accepted: boolean;
    timestamp: number;
  };
  newsletter: {
    accepted: boolean;
    timestamp: number;
  };
};

type PrivateInfo = {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email: string;
  emailToChangeTo?: string;
  gender?: 'male' | 'female' | 'other';
};

type AccessControl = {
  role: string;
  lastChange: number;
};

type OnboardingInfo = {
  referralOrigin: ReferralOptions;
};

export enum ReferralOptions {
  socialMedia = 'socialMedia',
  recommendation = 'recommendation',
  training = 'training',
  fair = 'fair',
  google = 'google',
  call = 'call',
  walkIn = 'walkIn',
  other = 'other',
}
