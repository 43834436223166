export const generateMapsImage = (lat?: number, lng?: number, markerColor?: string, hideText?: boolean) => {
  const zoom = 15;
  const width = 350;
  const height = 220;
  const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string;

  // Base styles to hide POI labels
  let styles = '&style=feature:poi|element:labels|visibility:off';

  if (hideText) {
    styles += '&style=feature:all|element:labels|visibility:off';
  }

  const base = 'https://maps.googleapis.com/maps/api/staticmap?';
  const center = `center=${lat},${lng}`;
  const zoomLevel = `&zoom=${zoom}`;
  const size = `&size=${width}x${height}`;
  const markers = `&markers=color:${markerColor ? markerColor?.replace('#', '0x') : 0xffffff}%7C${lat},${lng}`;
  const key = `&key=${apiKey}`;
  const imageUrl = `${base}${center}${zoomLevel}${size}${styles}${markers}${key}`;

  return imageUrl;
};
