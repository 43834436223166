import styled from 'styled-components';
import { useTherapistsConnect } from '../../core/utils/connectFirestore/useTherapistsConnect';
import { colors } from '../../styles/colors';
import { H2 } from '../../styles/textStyles';
import { useOnboardingPracticeInfo } from '../onboarding/utils/useOnboardingPracticeInfo';
import { MainScreenContainer } from '../overview/components/MainScreenComponent';
import { PracticeLinkContainer } from './components/PracticeLinkContainer';
import { TherapistsLinksContainer } from './components/TherapistsLinksContainer';

export const PracticeSettingsLinksScreen = () => {
  const { practiceName, practiceId } = useOnboardingPracticeInfo();
  const { activeTherapists: therapists, loadingTherapists } = useTherapistsConnect(practiceId);

  return (
    <MainScreenContainer>
      <Container>
        <StyledH2>Wichtige Links für den Standort {practiceName}</StyledH2>
        <PracticeLinkContainer practiceId={practiceId ?? ''} />
        <TherapistsLinksContainer
          therapists={therapists ?? []}
          loadingTherapists={loadingTherapists}
          practiceId={practiceId}
        />
      </Container>
    </MainScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 720px;
  padding-bottom: 200px;
`;

const StyledH2 = styled(H2)`
  color: ${colors.Dark};
`;
