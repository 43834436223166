import { doc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { db } from '../../../../firebaseConfig';
import { getAppBrandingAddress, getAppBrandingMapsInfo } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { AppBrandingAddressInput } from '../../../appBranding/components/AppBrandingAddressInput';
import { saveMapImageToFirebase } from '../../../appBranding/utils/saveMapImageToFirebase';
import { useAppBrandingPracticeInfo } from '../../../appBranding/utils/useAppBrandingPracticeInfo';
import { SettingsBrandingComponentsWrapper } from './SettingsBrandingComponentsWrapper';

export const SettingsBrandingAddress = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { uploadAppBrandingData, uploading } = useAppBrandingPracticeInfo();
  const mapsInfo = useMainStore(getAppBrandingMapsInfo);
  const setMapsImageUrl = useMainStore((state) => state.setAppBrandingMapsImageUrl);
  const zustandAppBrandingAddress = useMainStore(getAppBrandingAddress);
  const [initialAppBrandingAddress, setInitialAppBrandingAddress] = useState(zustandAppBrandingAddress);
  const nextButtonDisabled = zustandAppBrandingAddress === initialAppBrandingAddress;
  const [loading, setLoading] = useState(false);
  const handleSave = async () => {
    setInitialAppBrandingAddress(zustandAppBrandingAddress);
    setLoading(true);
    if (!mapsInfo?.mapsStaticImageUrl) return;
    try {
      const mapsImageUrl = await saveMapImageToFirebase(mapsInfo?.mapsStaticImageUrl, practiceId);
      setMapsImageUrl(mapsImageUrl);
      await uploadAppBrandingData();
      await updateDoc(doc(db, 'practices/' + practiceId + '/documents/practiceAppCustomization'), {
        address: zustandAppBrandingAddress,
        mapsInfo: {
          ...mapsInfo,
          mapsImageUrl,
        },
      });
      setEventIndicator('success', 'Änderungen gespeichert');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const { practiceId } = useAppBrandingPracticeInfo();
  return (
    <SettingsBrandingComponentsWrapper
      title="StandortAdresse"
      description="Erleichtere Patienten den Weg zu deiner Praxis. Die Standort-Adresse wird in der App hinterlegt und erlaubt deinen Patienten direkt mit der Navigation zu starten."
      nextButton={{
        text: 'Speichern',
        onClick: handleSave,
        loading: uploading || loading,
        disabled: nextButtonDisabled,
      }}
    >
      <AppBrandingAddressInput setLoading={setLoading} practiceId={practiceId} />
    </SettingsBrandingComponentsWrapper>
  );
};
