import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { ScreenContainer } from '../core/components/container/ScreenContainer';
import { VersionTag } from '../core/components/VersionTag';
import { useStartPosthog } from '../core/utils/useStartPosthog';
import { useMainStore } from '../store/mainStore';
import { getIsMitarbeiter, getMitarbeiterUserIsLoggedIn, getUserIsLoggedIn } from '../store/userSlice';
import { Header } from './components/Header';

export const WelcomeLayout = () => {
  const userLoggedIn = useMainStore(getUserIsLoggedIn);
  const isMitarbeiter = useMainStore(getIsMitarbeiter);
  const mitarbeiterIsLoggedIn = useMainStore(getMitarbeiterUserIsLoggedIn);
  useStartPosthog();

  if (userLoggedIn) {
    return <Navigate to="/onboarding" />;
  } else if (mitarbeiterIsLoggedIn && isMitarbeiter) {
    return <Navigate to="/admin" />;
  }
  return (
    <ScreenContainer>
      <Header />
      <OutletContainer className="onBoardingScreenContainer-scroll-ancestor">
        <Outlet />
      </OutletContainer>
      <VersionTag />
    </ScreenContainer>
  );
};

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  padding-top: 100px;
  padding-bottom: 200px;
  background-color: 'red';
`;
