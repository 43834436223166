import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { H1 } from '../../../../styles/textStyles';
import { MainScreenContainer } from '../../../overview/components/MainScreenComponent';
import { Game } from '../components/Game';

export const AdminHomeScreen = () => {
  return (
    <MainScreenContainer>
      <Container>
        <H1>Admin-Tool</H1>
        <Game />
      </Container>
      <Outlet />
    </MainScreenContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
