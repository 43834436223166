import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { Body, H3 } from '../../../styles/textStyles';

type Props = {
  title: string;
  text: string;
  className?: string;
};

export const SettingsHeadline = ({ title, text, className }: Props) => {
  return (
    <HeadlineContainer className={className}>
      <StyledH3>{title}</StyledH3>
      <StyledBody>{text}</StyledBody>
    </HeadlineContainer>
  );
};

const HeadlineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: column;
  padding-bottom: 20px;
`;

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
  padding-bottom: 15px;
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
`;
