import styled from 'styled-components';

import { Outlet, useNavigate } from 'react-router-dom';
import { MainButton } from '../../../core/components/buttons/MainButton';
import { FullScreenContainer } from '../../../core/components/container/FullScreenContainer';
import { H1 } from '../../../styles/textStyles';
import { AdminUserLine } from './components/AdminUserLine';
import { useAdminUsersConnect } from './utils/useAdminUsersConnect';

export const AdminSettingsTeamScreen = () => {
  const navigate = useNavigate();
  const adminUsers = useAdminUsersConnect();

  const handleInviteUser = () => {
    navigate('invite');
  };

  return (
    <FullScreenContainer>
      <Container>
        <StyledH1>Admin Team</StyledH1>
        <StyledButton text="Benutzer einladen" onClick={handleInviteUser} />
        {adminUsers.map((user) => (
          <AdminUserLine key={user.id} user={user} />
        ))}
      </Container>
      <Outlet />
    </FullScreenContainer>
  );
};

const StyledH1 = styled(H1)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const StyledButton = styled(MainButton)`
  align-self: flex-start;
`;
