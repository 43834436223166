import { doc, getDoc } from 'firebase/firestore';
import { useState } from 'react';
import { LoaderFunction, LoaderFunctionArgs, Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { InfoText } from '../../core/components/text/InfoText';
import { db } from '../../firebaseConfig';
import { PhoneMockLayoutContainer } from '../../navigation/components/PhoneMockLayoutContainer';
import { getAppBrandingCompleted, getChainPracticeName } from '../../store/chainSlice';
import { useMainStore } from '../../store/mainStore';
import { DESKTOP_BREAKPOINT } from '../../styles/breakpoints';
import { AppCustomization } from '../../types/Practices/AppCustomizationType';
import { AppBrandingPhoneMock } from '../appBranding/components/AppBrandingPhoneMock';
import { useHandleScroll } from '../onboarding/utils/useHandleScroll';
import { SettingsBrandingAddress } from './components/settingsBrandingComponents/SettingsBrandingAddress';
import { SettingsBrandingColor } from './components/settingsBrandingComponents/SettingsBrandingColor';
import { SettingsBrandingDescription } from './components/settingsBrandingComponents/SettingsBrandingDescription';
import { SettingsBrandingImages } from './components/settingsBrandingComponents/SettingsBrandingImages';
import { SettingsBrandingLogoComponent } from './components/settingsBrandingComponents/SettingsBrandingLogoComponent';
import { SettingsBrandingOpeningTimes } from './components/settingsBrandingComponents/SettingsBrandingOpeningTimes';
import { SettingsBrandingPracticeSlogan } from './components/settingsBrandingComponents/SettingsBrandingPracticeSlogan';
import { SettingsBrandingServices } from './components/settingsBrandingComponents/SettingsBrandingServices';
import { SettingsBrandingVideo } from './components/settingsBrandingComponents/SettingsBrandingVideo';

export type PhoneMockType = 'home' | 'practice' | 'video' | 'summary';

export const brandingSettingsLoader: LoaderFunction = async ({ params }: LoaderFunctionArgs) => {
  const practiceId = params.practiceId;
  if (!practiceId) return null;
  try {
    const practiceSnapshot = await getDoc(doc(db, 'practices/' + practiceId + '/documents/practiceAppCustomization'));
    const appBranding = practiceSnapshot?.data() as undefined | AppCustomization;
    const state = useMainStore.getState();
    state.setAppBranding(appBranding ?? undefined);
    return appBranding ?? null;
  } catch (error) {
    console.error(error);
  }
};

export const PracticeSettingsBrandingScreen = () => {
  const { containerRef, childRef } = useHandleScroll();
  const practiceId = useParams().practiceId;
  const practiceName = useMainStore((state) => getChainPracticeName(state, practiceId));
  const [phoneMockType, setPhoneMockType] = useState<PhoneMockType>('summary');
  const [section, setSection] = useState<string | undefined>(undefined);
  const handleChange = (type: PhoneMockType, section?: string) => {
    setPhoneMockType(type);
    setSection(section);
  };

  const finishedBranding = useMainStore((state) => getAppBrandingCompleted(state, practiceId ?? ''));

  if (!finishedBranding) {
    return <Navigate to={'/branding/' + practiceId + '/intro'} />;
  }
  return (
    <PhoneMockLayoutContainer ref={containerRef}>
      <ContentContainer>
        <InfoText headline={'Dein App Branding für den Standort ' + practiceName} />

        <SectionContainer onMouseEnter={() => handleChange('home')}>
          <SettingsBrandingColor />
        </SectionContainer>
        <SectionContainer onMouseEnter={() => handleChange('home')}>
          <SettingsBrandingLogoComponent />
        </SectionContainer>
        <SectionContainer onMouseEnter={() => handleChange('practice', 'address')}>
          <SettingsBrandingAddress />
        </SectionContainer>
        <SectionContainer onMouseEnter={() => handleChange('practice', 'opening')}>
          <SettingsBrandingOpeningTimes />
        </SectionContainer>
        <SectionContainer onMouseEnter={() => handleChange('practice', 'claim')}>
          <SettingsBrandingPracticeSlogan />
        </SectionContainer>
        <SectionContainer onMouseEnter={() => handleChange('practice', 'services')}>
          <SettingsBrandingServices />
        </SectionContainer>
        <SectionContainer onMouseEnter={() => handleChange('practice', 'images')}>
          <SettingsBrandingImages />
        </SectionContainer>
        <SectionContainer onMouseEnter={() => handleChange('practice', 'description')}>
          <SettingsBrandingDescription />
        </SectionContainer>
        <VideoSectionContainer onMouseEnter={() => handleChange('video')}>
          <SettingsBrandingVideo />
        </VideoSectionContainer>
      </ContentContainer>
      <StyledAppBrandingPhoneMock type={phoneMockType} ref={childRef} section={section} className="child" />
    </PhoneMockLayoutContainer>
  );
};

const StyledAppBrandingPhoneMock = styled(AppBrandingPhoneMock)`
  position: absolute;
  top: 0;
  right: 0;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    display: none;
  }
`;

const VideoSectionContainer = styled.div`
  width: 100%;
  padding-bottom: 200px;
`;

const SectionContainer = styled.div`
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  padding: 0 20px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  gap: 20px;
`;
