import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { CustomButton } from '../../../core/components/buttons/CustomButton';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { colors } from '../../../styles/colors';
import { Body } from '../../../styles/textStyles';

type Props = {
  path: string;
  title: string;
  closeDrawer: () => void;
  isParent?: boolean;
  dropdown?: boolean;
};

export const DrawerNavItem = ({ path, title, closeDrawer, isParent, dropdown }: Props) => {
  const navigate = useNavigate();
  const selected = window.location.pathname.includes(path);
  const handleClick = () => {
    closeDrawer();
    navigate(path, { replace: true });
  };
  return (
    <Container $selected={selected} onClick={handleClick} $isParent={isParent}>
      <StyledBody>{title}</StyledBody>
      {dropdown && <GenericSvg name={'DropdownDown'} stroke={colors.Light} size={32} />}
    </Container>
  );
};

const Container = styled(CustomButton)<{ $selected: boolean; $isParent?: boolean }>`
  display: flex;
  margin: 5px 0;
  padding: 5px 10px;
  min-height: 32px;
  border-radius: 5px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  background-color: ${(props) => (props.$selected && !props.$isParent ? colors.Light : 'transparent')};
  opacity: ${(props) => (props.$selected ? 1 : 0.6)};
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) => (props.$selected ? colors.Light : hexWithOpacity(colors.Primary, 0.1))};
  }
`;

const StyledBody = styled(Body)`
  text-align: left;
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
`;
