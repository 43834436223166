import React from 'react';
import styled from 'styled-components';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { colors } from '../../../styles/colors';
import { Body } from '../../../styles/textStyles';
import { CheckCard } from './CheckCard';

type BaseStepCardProps = {
  title: string;
  content: string;
  active?: boolean;
};

type ButtonVariantProps = BaseStepCardProps & {
  variant: 'button';
  mainButton: {
    title: string;
    onClick: () => void;
    label?: string;
  };
};

type CustomVariantProps = BaseStepCardProps & {
  variant: 'custom';
  bottomComponent: React.ReactNode;
};

export type StepCardProps = ButtonVariantProps | CustomVariantProps;

export const StepCard = (props: StepCardProps) => {
  const { title, content, active, variant } = props;

  return (
    <CheckCard title={title} content={content} active={active}>
      {variant === 'button' ? (
        <>
          {props.mainButton.label ? <StyledBody $active={active}>{props.mainButton.label}</StyledBody> : <div />}
          <TextButton
            text={props.mainButton.title}
            color={active ? colors.White : colors.Dark}
            onClick={props.mainButton.onClick}
          />
        </>
      ) : (
        props.bottomComponent
      )}
    </CheckCard>
  );
};

const StyledBody = styled(Body)<{ $active?: boolean }>`
  color: ${({ $active }) => ($active ? colors.White : colors.Medium)};
`;
