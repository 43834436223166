import styled from 'styled-components';
import { InfoText } from '../../../core/components/text/InfoText';
import { getInvitationCustomSubscriptionId } from '../../../store/invitationSlice';
import { useMainStore } from '../../../store/mainStore';
import { CheckoutBillCustom } from './CheckoutBillCustom';
import { CheckoutBillNormal } from './CheckoutBillNormal';

export const CheckoutBill = () => {
  const customSubscriptionId = useMainStore(getInvitationCustomSubscriptionId);
  return (
    <Container>
      <InfoText headline={'Deine Physiofit Partnergebühr'} />
      {customSubscriptionId ? <CheckoutBillCustom /> : <CheckoutBillNormal />}
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 100%;
  gap: 20px;
  padding: 0 10px;
`;
