import { Asset } from '../../../../types/AssetType';
import { Exercise } from '../../../../types/Content/ExerciseType';

export const getExerciseVideo = (
  exercise?: Exercise,
): {
  videos: (Asset & { type: 'video' })[] | undefined;
  perspective: 'horizontal' | 'portrait';
} => {
  if (!exercise) {
    return { videos: undefined, perspective: 'horizontal' };
  }
  if (exercise.videoAssets?.d) {
    const videos = [exercise.videoAssets.d];
    if (exercise.videoAssets.b) {
      videos.push(exercise.videoAssets.b);
    }
    return { videos: videos, perspective: 'horizontal' };
  } else if (exercise.videoAssets?.b) {
    return { videos: [exercise.videoAssets.b], perspective: 'horizontal' };
  } else if (exercise.videoAssets?.c) {
    const videos = [exercise.videoAssets.c];
    if (exercise.videoAssets.a) {
      videos.push(exercise.videoAssets.a);
    }
    return { videos: videos, perspective: 'portrait' };
  } else if (exercise.videoAssets?.a) {
    return { videos: [exercise.videoAssets.a], perspective: 'portrait' };
  }
  return { videos: undefined, perspective: 'horizontal' };
};
