import styled from 'styled-components';
import { GenericSvg, SVGName } from '../../../assets/svgs/GenericSVG';
import { colors } from '../../../styles/colors';
import { TextButtonText } from '../../../styles/textStyles';
import { LoadingSpinner } from '../loading/LoadingSpinner';
import { CustomButton } from './CustomButton';

type Props = {
  text: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
  loading?: boolean;
  noUnderline?: boolean;
  color?: string;
  noPadding?: boolean;
  icon?: {
    name: SVGName;
    size?: number;
    color?: string;
  };
};

export const TextButton = (props: Props) => {
  return (
    <StyledCustomButton ref={props.ref} onClick={props.onClick} disabled={props.disabled || props.loading}>
      <ContentContainer>
        <LoadingContainer $loading={props.loading}>
          <LoadingSpinner color={props.color ?? colors.Dark} />
        </LoadingContainer>
        <ButtonContent $loading={props.loading} $noPadding={props.noPadding}>
          {props.icon && (
            <GenericSvg
              name={props.icon.name}
              size={props.icon.size ?? 24}
              primaryColor={props.icon.color ?? colors.Dark}
            />
          )}
          <StyledTextButtonText $noUnderline={props.noUnderline} $color={props.color}>
            {props.text}
          </StyledTextButtonText>
        </ButtonContent>
      </ContentContainer>
    </StyledCustomButton>
  );
};

const StyledCustomButton = styled(CustomButton)`
  width: fit-content;
  position: relative;
`;

const ContentContainer = styled.div`
  position: relative;
`;

const ButtonContent = styled.div<{ $loading?: boolean; $noPadding?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.$noPadding ? '0' : '14px 20px')};
  gap: 5px;
  visibility: ${(props) => (props.$loading ? 'hidden' : 'visible')};
`;

const LoadingContainer = styled.div<{ $loading?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${(props) => (props.$loading ? 'visible' : 'hidden')};
`;

const StyledTextButtonText = styled(TextButtonText)<{ $noUnderline?: boolean; $color?: string }>`
  text-decoration: ${(props) => (props.$noUnderline ? 'none' : 'underline')};
  text-decoration-color: ${(props) => (props.$color ? props.$color : colors.Dark)};
  color: ${(props) => (props.$color ? props.$color : colors.Dark)};
`;
