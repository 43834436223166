import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { useWindowDimensions } from '../../../core/utils/useWindowDimensions';
import { Therapist } from '../../../types/Practices/TherapistType';
import { SettingsHeadline } from './SettingsHeadline';
import { TherapistLinks } from './TherapistLinks';

type TherapistsLinksContainerProps = {
  therapists: Therapist[];
  loadingTherapists: boolean;
  practiceId: string | undefined;
};

export const TherapistsLinksContainer = ({
  therapists,
  loadingTherapists,
  practiceId,
}: TherapistsLinksContainerProps) => {
  const { isMobileWidth } = useWindowDimensions();
  return (
    <div>
      <SettingsHeadline
        title={`Therapeuten Links`}
        text={
          'Hier findest du die individuellen Links für deine Therapeuten. Damit können sie direkt loslegen und Patienten zu Physiofit einladen.'
        }
      />
      <TherapistsContainer $isMobile={isMobileWidth}>
        {loadingTherapists ? (
          <Skeleton height={50} count={3} />
        ) : (
          therapists?.map((therapist) => (
            <TherapistLinks key={therapist.id} practiceId={practiceId ?? ''} therapist={therapist} />
          ))
        )}
      </TherapistsContainer>
    </div>
  );
};

const TherapistsContainer = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.$isMobile ? '40px' : '20px')};
`;
