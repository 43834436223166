import styled from 'styled-components';
import TherapistMock from '../../../../assets/images/MockTherapist.jpg';
import { GenericSvg } from '../../../../assets/svgs/GenericSVG';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { getAppBrandingColors } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { Mini, Small } from '../../../../styles/textStyles';

export const MockTherapistCard = () => {
  const appColors = useMainStore(getAppBrandingColors);

  return (
    <TherapistCard>
      <TherapistImage src={TherapistMock} />
      <TherapistRight>
        <TherapistText>
          <ProgressSubtitle $color={appColors.Medium}>{'Dein Therapeut'}</ProgressSubtitle>
          <ProgressHeadline $color={appColors.Dark}>{'Katherina Mustermann'}</ProgressHeadline>
        </TherapistText>
        <TherapistButton $borderColor={appColors.Light}>
          <ProgressSubtitle $color={appColors.Medium}>{'Informationen zur Praxis'}</ProgressSubtitle>
          <StyledGenericSVG name="ArrowDown" size={24} primaryColor={appColors.Medium} />
        </TherapistButton>
      </TherapistRight>
    </TherapistCard>
  );
};

const ProgressHeadline = styled(Small)<{ $color: string }>`
  font-weight: 500;
  color: ${({ $color }) => $color};
`;

const ProgressSubtitle = styled(Mini)<{ $color: string }>`
  color: ${({ $color }) => hexWithOpacity($color, 0.6)};
`;

const TherapistCard = styled.div`
  margin: 0 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background-color: ${colors.White}; // Add this line to ensure the container is visible
`;

const TherapistButton = styled.div<{ $borderColor: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border-top: 2px solid ${({ $borderColor }) => $borderColor};
  padding: 0 10px;
`;

const TherapistRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TherapistImage = styled.img`
  height: 100px;
  width: 80px;
`;

const TherapistText = styled.div`
  flex: 1;
  padding: 10px 10px;
`;

const StyledGenericSVG = styled(GenericSvg)`
  rotate: -90deg;
`;
