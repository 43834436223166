import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { getChainId, getChainName } from '../../store/chainSlice';
import { useMainStore } from '../../store/mainStore';
import { getUserId } from '../../store/userSlice';

export const useStartPosthog = () => {
  const posthog = usePostHog();
  const uid = useMainStore(getUserId);
  const chainName = useMainStore(getChainName);
  const chainId = useMainStore(getChainId);

  useEffect(() => {
    if (!uid) return;
    posthog?.identify(uid, {
      chainId: chainId,
      chainName: chainName,
    });
    if (chainId) posthog?.group('Kette', chainId);
  }, [uid, chainId, chainName]);
};
