import { Stream } from '@cloudflare/stream-react';
import { useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { GenericSvg } from '../../../../assets/svgs/GenericSVG';
import { CustomButton } from '../../../../core/components/buttons/CustomButton';
import { colors } from '../../../../styles/colors';
import { Exercise } from '../../../../types/Content/ExerciseType';
import { getExerciseVideo } from '../utils/getExerciseVideo';

type VideoViewerProps = {
  exercise?: Exercise;
};

const getVideoCloudflareUidFromUrl = (url: string) => {
  return url.split('/')[3];
};

export const VideoViewer = ({ exercise }: VideoViewerProps) => {
  const ref = useRef(null);
  const [shownVideoIndex, setShownVideoIndex] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const { videos } = getExerciseVideo(exercise);
  const videoCloudflareUIDs = videos?.map((video) => getVideoCloudflareUidFromUrl(video.assetUrl));

  const switchVideo = () => {
    if (videoCloudflareUIDs) {
      setShownVideoIndex((shownVideoIndex + 1) % videoCloudflareUIDs.length);
    }
  };

  return (
    <VideoContainer>
      <SkeletonWrapper>
        <Skeleton width={'680px'} height={'380px'} />
      </SkeletonWrapper>
      {videoCloudflareUIDs?.map((uid, index) => (
        <VideoWrapper ref={ref} key={uid} isVisible={index === shownVideoIndex && videoLoaded}>
          <Stream controls src={uid} muted loop responsive onLoadedData={() => setVideoLoaded(true)} />
          <StyledCustomButton onClick={switchVideo} disabled={videoCloudflareUIDs.length === 1}>
            <GenericSvg name="Exchange" primaryColor={colors.Medium} size={24} />
          </StyledCustomButton>
        </VideoWrapper>
      ))}
    </VideoContainer>
  );
};

// Styled components
const VideoContainer = styled.div`
  min-height: 380px;
  width: 100%;
`;

const VideoWrapper = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: relative;
  width: 680px;
  height: 380px;
  border-radius: 20px;
  overflow: hidden;
`;

const StyledCustomButton = styled(CustomButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  height: 48px;
  width: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const SkeletonWrapper = styled.div`
  position: absolute;
  width: 100%;
  max-width: 680px;
`;
