import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonGroup } from '../../../../core/components/buttons/ButtonGroup';
import { MainButton } from '../../../../core/components/buttons/MainButton';
import { LoadingSpinner } from '../../../../core/components/loading/LoadingSpinner';
import { InfoText } from '../../../../core/components/text/InfoText';
import { SingleTextInput } from '../../../../core/components/textInput/SingleTextInput';
import { OnboardingScreenContainer } from '../../../onboarding/components/OnboardingScreenContainer';
import { checkIfValidEmail } from '../../../onboarding/utils/checkIfValidMail';
import { createAdminUser } from '../utils/createAdminUser';

export const AdminRegistrationScreen = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');

  const [invitationData, setInvitationData] = useState<{
    email?: string;
    state: 'error' | 'success';
    invitationId?: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const checkAdminUser = async (id: string) => {
      const functions = getFunctions();
      const call = httpsCallable(functions, 'checkAdminInvitation');
      const callableReturnMessage = (await call({
        userId: id,
      })) as { data: { status: string; message: 'Valid' | 'NotValid'; email: string } };
      console.log(callableReturnMessage);
      if (callableReturnMessage?.data?.status !== 'success' || callableReturnMessage?.data?.message !== 'Valid') {
        setInvitationData({ state: 'error' });
      } else {
        setInvitationData({ state: 'success', email: callableReturnMessage.data.email, invitationId: id });
      }
    };
    const queryParameters = new URLSearchParams(window.location.search);
    const newInvitationId = queryParameters.get('id');
    console.log(newInvitationId);
    if (!newInvitationId) {
      setInvitationData({ state: 'error' });
    } else {
      checkAdminUser(newInvitationId);
    }
  }, []);

  const handleRegister = async () => {
    setErrorEmail('');
    setErrorPassword('');
    setLoading(true);
    if (!invitationData?.email || !checkIfValidEmail(invitationData?.email ?? '')) {
      setErrorEmail(t('ONBOARDING-REGISTER-ERROR-EMAIL-NOT-VALID'));
      setLoading(false);
      return;
    }
    if (newPassword.length < 12) {
      setErrorPassword('Dein Password muss mindestens 12 Zeichen lang sein.');
      setLoading(false);
      return;
    }
    try {
      const auth = getAuth();
      await createUserWithEmailAndPassword(auth, invitationData?.email, newPassword);
      await createAdminUser(invitationData?.invitationId ?? '');
      navigate('../../onboarding/verification');
    } catch (error: any) {
      console.log(error);
      if (error.code === 'auth/email-already-in-use') {
        setErrorEmail(t('ONBOARDING-REGISTER-ERROR-EMAIL-ALREADY-IN-USE'));
      } else {
        setErrorEmail(t('ONBOARDING-REGISTER-ERROR-UNKNOWN'));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = () => {
    navigate('../login');
  };

  if (invitationData?.state === 'error') {
    return <Navigate to="/adminWelcome/login" />;
  }

  return (
    <OnboardingScreenContainer hideProgress hideBackButton>
      {!invitationData ? (
        <LoadingSpinner />
      ) : (
        <>
          <InfoText
            headline={'Physiofit Mitarbeiter Terminal'}
            text={['Kein Physiofit Mitarbeiter? Klicke hier um zu unserem Partner Login zu gelangen.']}
          />
          <StyledMainButton text={'Partner Login'} onClick={() => navigate('/welcome/login')} />
          <InputContainer
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <SingleTextInput
              value={invitationData.email ?? ''}
              placeholder={t('ONBOARDING-REGISTRATION-EMAIL-FIELD')}
              error={errorEmail}
              type="email"
              autoComplete="username"
              disabled={true}
            />
            <SingleTextInput
              value={newPassword}
              onChange={setNewPassword}
              placeholder={t('ONBOARDING-REGISTRATION-PASSWORD')}
              error={errorPassword}
              type="password"
              autoComplete="new-password"
            />
          </InputContainer>
          <ButtonGroup
            textButton={{
              text: t('ONBOARDING-BUTTON-LOGIN'),
              onClick: handleLogin,
            }}
            progressButton={{
              text: t('ONBOARDING-BUTTON-NEXT'),
              onClick: handleRegister,
              numberOfSteps: 2,
              currentStep: newPassword ? 2 : 1,
              loading: loading,
            }}
          />
        </>
      )}
    </OnboardingScreenContainer>
  );
};

const InputContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledMainButton = styled(MainButton)`
  width: 100%;
`;
