const claims = [
  'Ihr Wohlbefinden ist unser Antrieb. Wir bieten individuelle Therapiepläne, um Ihre Gesundheit nachhaltig zu fördern.',
  'Wir helfen Ihnen, Ihre Beweglichkeit und Lebensqualität zurückzugewinnen – Schritt für Schritt.',
  'Gemeinsam schaffen wir eine Basis für ein schmerzfreies und aktives Leben.',
  'Unser Ziel: Ihre Gesundheit in den Mittelpunkt zu stellen und Ihnen zu mehr Bewegungsfreiheit zu verhelfen.',
  'Jeder Patient ist einzigartig – genauso wie unsere Therapien.',
  'Weil Sie es verdienen, schmerzfrei zu leben – Ihre Gesundheit ist unsere Priorität.',
  'Ihr Partner für Prävention, Heilung und nachhaltige Gesundheit.',
  'Wir begleiten Sie auf Ihrem Weg zu neuer Kraft und Beweglichkeit.',
  'Mit modernen Therapiemethoden und persönlicher Betreuung zur ganzheitlichen Gesundheit.',
  'Von akuten Beschwerden zur dauerhaften Stabilität – wir sind für Sie da.',
  'Therapie, die sich Ihnen anpasst: Weil jede Heilung einzigartig ist.',
  'Mehr als nur Behandlung – wir unterstützen Sie in Ihrem gesamten Heilungsprozess.',
  'Wir setzen auf persönliche Betreuung, um Ihre Mobilität und Lebensqualität zu steigern.',
  'Individuelle Therapieansätze für langfristige Gesundheit und Wohlbefinden.',
  'Mit unserer Erfahrung und Ihrem Willen zu mehr Lebensfreude durch Schmerzfreiheit.',
  'Ihr Erfolg ist unser Ziel: gezielte Behandlung für ein aktives Leben.',
  'Weil Ihre Gesundheit Zeit und Hingabe verdient – das ist unser Versprechen.',
  'Moderne Physiotherapie mit Herz und Verstand – für Ihr bestes Ich.',
  'Gesundheit ist mehr als Schmerzfreiheit – wir helfen Ihnen, Ihr volles Potenzial auszuschöpfen.',
  'Gemeinsam gestalten wir Ihre Gesundheitsgeschichte neu – mit Fachkompetenz und Einfühlungsvermögen.',
  'Wir sind Ihr Partner für ein gesundes Leben – nachhaltig und individuell.',
  'Ihre Gesundheit und Ihr Wohlbefinden stehen bei uns an erster Stelle.',
  'Unsere Therapien sind so einzigartig wie Sie. Wir behandeln nicht nur Symptome, sondern fördern Ihre Gesundheit langfristig.',
  'Von der ersten Beratung bis zur letzten Therapieeinheit – bei uns stehen Sie im Mittelpunkt.',
  'Mit modernen Methoden und traditionellen Techniken bringen wir Sie zurück in Bewegung.',
  'Individuell, kompetent und menschlich – das ist unsere Praxisphilosophie.',
  'Wir nehmen uns Zeit für Ihre Gesundheit – mit fundiertem Fachwissen und Einfühlungsvermögen.',
  'Ihr Weg zur Schmerzfreiheit beginnt hier – wir begleiten Sie professionell und persönlich.',
  'Ganzheitliche Therapieansätze für eine optimale Genesung und langfristige Stabilität.',
  'Unsere Erfahrung für Ihre Gesundheit – damit Sie sich wieder rundum wohlfühlen.',
  'Gesundheit bedeutet für uns: Prävention, Heilung und Stabilisierung auf höchstem Niveau.',
  'Wir stärken Sie in Ihrer Genesung – mit gezielten Maßnahmen und persönlicher Unterstützung.',
  'Bei uns erhalten Sie mehr als nur eine Behandlung – wir bieten Ihnen ganzheitliche Gesundheitskonzepte.',
  'Ihre Gesundheit in den besten Händen – professionelle Betreuung mit Herz und Verstand.',
  'Fachliche Kompetenz und persönliche Betreuung – so definieren wir Erfolg in der Physiotherapie.',
  'Individuelle Lösungen für jede Herausforderung – von der Rehabilitation bis zur Prävention.',
  'Ihre Gesundheit ist unser Ziel – Ihre Zufriedenheit unser Maßstab.',
  'Wir begleiten Sie von der Akuttherapie bis zur präventiven Gesundheitsförderung.',
  'Durch gezielte Behandlung und Bewegungstherapie schaffen wir die Basis für Ihre Gesundheit.',
  'Erleben Sie Physiotherapie auf höchstem Niveau – mit ganzheitlichem Ansatz und modernster Technik.',
  'Für uns bedeutet Therapie: Ihre Gesundheit und Lebensqualität langfristig zu sichern.',
  'Weil Sie uns wichtig sind – maßgeschneiderte Therapiepläne für Ihre persönliche Gesundheit.',
  'Unsere Mission: Schmerzfreiheit, Stabilität und gesteigerte Lebensqualität für jeden Patienten.',
  'Von der ersten Beratung bis zum letzten Behandlungstag – wir sind für Sie da.',
  'Gesundheit ist unser höchstes Gut – wir helfen Ihnen, sie zu bewahren und zurückzugewinnen.',
  'Unsere Praxis steht für ganzheitliche Therapieansätze und individuelle Lösungen.',
  'Ihr Partner auf dem Weg zur Schmerzfreiheit – mit gezielten Maßnahmen und kompetenter Beratung.',
  'Ihre Gesundheit liegt uns am Herzen – vertrauen Sie auf unsere Expertise.',
  'Für ein Leben ohne Schmerzen – wir helfen Ihnen, Ihre Beweglichkeit zurückzugewinnen.',
  'Wir verstehen Ihre Bedürfnisse – und passen unsere Therapiepläne individuell an.',
  'Ihr Wohlbefinden ist unsere Motivation – ganzheitliche Physiotherapie für ein besseres Leben.',
  'Bei uns stehen Sie und Ihre Gesundheit im Mittelpunkt.',
  'Für ein schmerzfreies und aktives Leben – Ihr Partner in der Physiotherapie.',
  'Unsere Erfahrung und Ihr Wille – gemeinsam erreichen wir Ihre Gesundheitsziele.',
  'Ihre Gesundheit verdient Zeit, Verständnis und individuelle Betreuung.',
  'Unser Ziel: Ihnen ein schmerzfreies und aktives Leben zu ermöglichen.',
  'Mit Herz und Verstand zur optimalen Genesung – das ist unser Versprechen.',
  'Therapie, die Sie stärkt – körperlich und mental.',
  'Wir unterstützen Sie auf dem Weg zur vollständigen Genesung.',
  'Ihre Gesundheit ist unsere Leidenschaft – für mehr Lebensqualität und Wohlbefinden.',
  'Für eine nachhaltige Genesung – wir sind an Ihrer Seite.',
  'Jeder Mensch ist einzigartig – so sind auch unsere Behandlungskonzepte.',
  'Gesundheit neu erleben – mit persönlicher Betreuung und modernster Technik.',
  'Gesundheit bedeutet für uns mehr als Schmerzfreiheit – wir fördern Ihr ganzheitliches Wohlbefinden.',
  'Ihre Gesundheit ist unser Fokus – mit gezielten Therapien zu mehr Bewegungsfreiheit.',
  'Physiotherapie, die Sie stärkt – für ein aktives und gesundes Leben.',
  'Ihr Partner auf dem Weg zu neuer Kraft und Stabilität.',
  'Unsere Praxis – Ihre Gesundheit – Ihr Wohlbefinden.',
  'Schmerzfreiheit ist der Anfang – Ihr Wohlbefinden ist das Ziel.',
  'Individuelle Betreuung und ganzheitliche Therapie für nachhaltige Ergebnisse.',
  'Ihre Gesundheit ist es wert – gezielte Therapieansätze für dauerhafte Ergebnisse.',
  'Physiotherapie mit Herz, Verstand und modernen Methoden.',
  'Kompetenz, Einfühlungsvermögen und moderne Technik – für Ihre Gesundheit.',
  'Ganzheitliche Ansätze für Ihre Gesundheit und Mobilität.',
  'Ihr Wohlbefinden ist unser Antrieb – Ihre Gesundheit unser Ziel.',
  'Therapie, die zu Ihnen passt – weil Sie einzigartig sind.',
  'Für Ihre Gesundheit: individuelle Betreuung und maßgeschneiderte Therapieansätze.',
  'Ihre Gesundheit in besten Händen – von der ersten Beratung bis zur Genesung.',
  'Unsere Praxis steht für gezielte Therapie und ganzheitliche Gesundheit.',
  'Ihre Gesundheit ist unsere Leidenschaft – wir helfen Ihnen, sich besser zu fühlen.',
  'Mehr Bewegungsfreiheit, mehr Lebensqualität – das ist unser Ziel.',
  'Ihr Wohlbefinden ist unsere höchste Priorität.',
  'Moderne Physiotherapie – für ein aktives und schmerzfreies Leben.',
  'Unsere Erfahrung für Ihre Genesung – individuell, kompetent und menschlich.',
  'Unsere Praxis – Ihr Partner für ein gesundes Leben.',
  'Therapie, die wirkt – mit Herz und Verstand.',
  'Ihr Wohlbefinden ist unser Maßstab.',
  'Wir stärken Ihre Gesundheit – nachhaltig und individuell.',
  'Ihr Weg zu mehr Bewegungsfreiheit und Lebensqualität beginnt bei uns.',
  'Physiotherapie, die zu Ihnen passt – persönlich und professionell.',
  'Für mehr Beweglichkeit und ein Leben ohne Schmerzen.',
  'Ihre Gesundheit ist unser Versprechen – Ihre Zufriedenheit unser Erfolg.',
  'Wir behandeln nicht nur Symptome – wir fördern Ihre Gesundheit langfristig.',
  'Wir helfen Ihnen, Ihre Bewegungsfreiheit zurückzugewinnen und zu bewahren.',
  'Weil Ihre Gesundheit mehr als nur Schmerzfreiheit ist.',
];

export const getRandomPracticeClaim = () => {
  return claims[Math.floor(Math.random() * claims.length)];
};
