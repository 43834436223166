import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../../firebaseConfig';
import { PracticePackageInfo } from '../../../types/Practices/PracticePackageInfoType';

export const usePracticePackageConnect = (practiceId: string) => {
  const [practicePackage, setPracticePackage] = useState<PracticePackageInfo>();

  useEffect(() => {
    const sub = onSnapshot(doc(db, 'practices/' + practiceId + '/documents/practicePackageInfo'), (snapshot) => {
      const data = snapshot.data() as PracticePackageInfo;
      setPracticePackage(data);
    });
    return sub;
  }, []);
  return practicePackage;
};
