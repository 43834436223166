import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../../styles/colors';
import { H2 } from '../../../../styles/textStyles';
import { useExercisesConnect } from '../utils/useExercisesConnect';
import { Program } from '../utils/useProgramsConnect';
import { NextExercisesPreview } from './NextExercisesPreview';
import { VideoViewer } from './VideoViewer';

type VideoPreviewComponentProps = {
  program: Program;
};

export const VideoPreviewComponent = (props: VideoPreviewComponentProps) => {
  const { exercises } = useExercisesConnect(props.program?.exerciseIds);
  const [currentExercise, setCurrentExercise] = useState(exercises?.[0]);
  const remainingExercises = exercises?.filter((exercise) => exercise.id !== currentExercise?.id);
  useEffect(() => {
    setCurrentExercise(exercises?.[0]);
  }, [exercises]);

  return (
    <Container>
      <StyledH2>{props.program?.title}</StyledH2>
      <VideoViewer exercise={currentExercise} />
      <NextExercisesPreview exercises={remainingExercises} setCurrentExercise={setCurrentExercise} />
    </Container>
  );
};

const Container = styled.div``;

const StyledH2 = styled(H2)`
  color: ${colors.Medium};
`;
