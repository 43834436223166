import { useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useState } from 'react';
import { addValueIfExists } from '../../../core/utils/addValueIfExists';

const libraries: ['places'] = ['places'];

const formateOpeningHours = (text: string) => {
  let newText = text.split(': ')?.[1];
  newText = newText.trim();
  newText = newText.replace('–', '-');
  newText = newText.replace(' Uhr', '');
  const textArray = newText.split('-');
  if (textArray.length === 2) {
    return textArray[0] + ' - ' + textArray[1];
  } else {
    return newText;
  }
};

export const useGooglePlaces = () => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string,
    libraries,
    language: 'de-DE',
    region: 'DE',
  });

  const onLoad = useCallback((autoComplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autoComplete);
  }, []);

  const onPlaceChanged = useCallback(() => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      return processPlaceResult(place);
    }
    return null;
  }, [autocomplete]);

  const processPlaceResult = (place: google.maps.places.PlaceResult | google.maps.GeocoderResult) => {
    const addressComponents = place.address_components;
    const name = 'name' in place ? place.name : place.formatted_address;

    if (addressComponents) {
      const streetNumber =
        addressComponents.find((component) => component.types.includes('street_number'))?.long_name || '';
      const route = addressComponents.find((component) => component.types.includes('route'))?.long_name || '';
      const locality = addressComponents.find((component) => component.types.includes('locality'))?.long_name || '';
      const postalCode =
        addressComponents.find((component) => component.types.includes('postal_code'))?.long_name || '';
      const lat = place.geometry?.location?.lat();
      const lng = place.geometry?.location?.lng();
      const phone = 'formatted_phone_number' in place ? place.formatted_phone_number : null;
      const website = 'website' in place ? place.website : null;

      let openingHours;
      const googleOpeningHours = (place as any).opening_hours?.weekday_text;
      openingHours = new Array(7).fill(0)?.map((_, index) => {
        let text = '08:00-18:00';
        const day = googleOpeningHours?.[index];
        if (day) {
          text = formateOpeningHours(day);
        }
        return { dayNumber: index, text };
      });
      const contact = {
        ...addValueIfExists('phone', phone),
        ...addValueIfExists('website', website),
      };

      return {
        address: {
          practiceName: name ?? route,
          street: route,
          houseNumber: streetNumber,
          city: locality,
          zip: postalCode,
        },
        openingHours,
        ...addValueIfExists('contact', contact),
        placeId: place.place_id,
        lat,
        lng,
      };
    }
    return null;
  };

  const autocompleteOptions = {
    bounds: { east: 13.8, west: 5.9, north: 55.1, south: 47.2 },
    fields: [
      'name',
      'address_components',
      'place_id',
      'opening_hours',
      'geometry',
      'formatted_phone_number',
      'website',
    ] as google.maps.places.AutocompleteOptions['fields'],
  };

  return {
    googleApiLoaded: isLoaded,
    googleApiError: loadError,
    autocompleteOptions,
    onLoad,
    onPlaceChanged,
  };
};
