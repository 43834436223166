import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { colors } from '../../../../styles/colors';
import { Body, H2, H4 } from '../../../../styles/textStyles';
import { priceFormatter } from '../screens/AdminSalesCalculatorScreen';

type AdminSalesCalculatorPotentialGainsComponentProps = {
  compensationValue: number;
  partnerFees: number;
};
export const AdminSalesCalculatorPotentialGainsComponent = (
  props: AdminSalesCalculatorPotentialGainsComponentProps,
) => {
  const { t } = useTranslation();
  return (
    <Container>
      <H2>{t('ADMIN-SALES-POTENTIAL-TITLE')}</H2>
      <LowerContainer>
        <ContentContainer>
          <TransactionContainer>
            <StyledH4>{t('ADMIN-SALES-POTENTIAL-PARAMETER-1')}</StyledH4>
            <StyledH4>{priceFormatter.format(props.compensationValue)}</StyledH4>
          </TransactionContainer>
          <TransactionContainer>
            <StyledH4>{t('ADMIN-SALES-POTENTIAL-PARAMETER-2')}</StyledH4>
            <StyledH4>{priceFormatter.format(-props.partnerFees)}</StyledH4>
          </TransactionContainer>
          <Separator />
          <StyledBody>{t('ADMIN-SALES-POTENTIAL-DESCRIPTION')}</StyledBody>
        </ContentContainer>
        <PriceContainer>
          <PriceLabel>{t('ADMIN-SALES-POTENTIAL-PRICE-LABEL')}</PriceLabel>
          <Price>{priceFormatter.format(Math.max(0, props.compensationValue - props.partnerFees))}</Price>
        </PriceContainer>
      </LowerContainer>
    </Container>
  );
};

const Container = styled.div`
  flex: 2;
  max-width: 480px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${hexWithOpacity(colors.Medium, 0.1)};
  border-radius: 20px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${hexWithOpacity(colors.Medium, 0.2)};
  margin: 10px 0;
`;

const LowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TransactionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledH4 = styled(H4)`
  color: ${colors.Medium};
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
`;

const Price = styled(H2)`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const PriceLabel = styled(H4)`
  flex: 2;
  display: flex;
  justify-content: flex-start;
`;
