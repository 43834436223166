import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { Small } from '../../styles/textStyles';
import { IconButton } from './buttons/IconButton';

type Props = {
  actions: { label: string; onClick: () => void; disabled?: boolean }[];
  color?: string;
};

export const ActionMenu = ({ actions, color }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openUpwards, setOpenUpwards] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (isOpen && menuRef.current) {
      const menuItems = menuRef.current.querySelectorAll('button');
      let maxWidth = 0;
      menuItems.forEach((item) => {
        const itemWidth = item.offsetWidth;
        if (itemWidth > maxWidth) {
          maxWidth = itemWidth;
        }
      });
    }
  }, [isOpen]);

  const toggleMenu = () => {
    if (!isOpen) {
      const rect = buttonRef.current?.getBoundingClientRect();
      if (rect) {
        const spaceBelow = window.innerHeight - rect.bottom;
        setOpenUpwards(spaceBelow < 200); // Adjust 200 based on your menu's max height
      }
    }
    setIsOpen(!isOpen);
  };

  return (
    <MenuContainer>
      <IconButton ref={buttonRef} onClick={toggleMenu} iconName={'Burger'} stroke={color} />
      {isOpen && (
        <MenuItems ref={menuRef} $openUpwards={openUpwards}>
          {actions.map((action, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                if (!action.disabled) {
                  action.onClick();
                  setIsOpen(false);
                }
              }}
              disabled={action.disabled}
            >
              <Small>{action.label}</Small>
            </MenuItem>
          ))}
        </MenuItems>
      )}
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const MenuItems = styled.div<{ $openUpwards: boolean }>`
  position: absolute;
  right: 0;
  ${(props) => (props.$openUpwards ? 'bottom: 100%;' : 'top: 100%;')}
  background-color: ${colors.White};
  border: 1px solid ${colors.Light};
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  white-space: nowrap;
`;

const MenuItem = styled.button`
  display: block;
  width: 100%;
  padding: 10px 15px;
  text-align: left;
  background: none;
  border: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  color: ${colors.Dark};

  &:hover {
    background-color: ${(props) => (props.disabled ? 'transparent' : colors.Light)};
  }
`;
