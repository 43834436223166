import { useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useState } from 'react';

const libraries: ['places'] = ['places'];

export const useGooglePlacesAutocomplete = () => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string,
    libraries,
    language: 'de-DE',
    region: 'DE',
  });

  const onLoad = useCallback((autoComplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autoComplete);
  }, []);

  const onPlaceChanged = useCallback(() => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;
      // const name = place.name;

      if (addressComponents) {
        const streetNumber =
          addressComponents.find((component) => component.types.includes('street_number'))?.long_name || '';
        const route = addressComponents.find((component) => component.types.includes('route'))?.long_name || '';
        const locality = addressComponents.find((component) => component.types.includes('locality'))?.long_name || '';
        const postalCode =
          addressComponents.find((component) => component.types.includes('postal_code'))?.long_name || '';

        return {
          // ...addValueIfExists('practiceName', name),
          street: route,
          houseNumber: streetNumber,
          city: locality,
          cityCode: postalCode,
        };
      }
    }
    return null;
  }, [autocomplete]);

  const autocompleteOptions = {
    bounds: { east: 13.8, west: 5.9, north: 55.1, south: 47.2 },
    fields: ['name', 'address_components'],
  };

  return { googleApiLoaded: isLoaded, googleApiError: loadError, autocompleteOptions, onLoad, onPlaceChanged };
};
