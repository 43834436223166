import { forwardRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { getAppBrandingColors, getAppBrandingDescription } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { H4, Small } from '../../../../styles/textStyles';
import { useOnboardingPracticeInfo } from '../../../onboarding/utils/useOnboardingPracticeInfo';

export const MockDescription = forwardRef<HTMLDivElement>((_, ref) => {
  const { practiceName } = useOnboardingPracticeInfo();
  const appColors = useMainStore(getAppBrandingColors);

  const description = useMainStore(getAppBrandingDescription);

  return (
    <Card ref={ref}>
      <Headline $color={appColors.Medium}>{practiceName}</Headline>
      {!description ? (
        <SkeletonWrapper>
          <Skeleton height={14} count={3} />
        </SkeletonWrapper>
      ) : (
        <Description $color={appColors.Dark}>{description}</Description>
      )}
    </Card>
  );
});

const Description = styled(Small)<{ $color: string }>`
  text-align: left;
  color: ${({ $color }) => $color};
`;

const Headline = styled(H4)<{ $color: string }>`
  font-weight: 500;
  color: ${({ $color }) => $color};
  margin-bottom: 10px;
`;

const Card = styled.div`
  margin: 0 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.White};
  overflow: hidden;
  gap: 10px;
  padding: 20px 15px;
`;

const SkeletonWrapper = styled.div`
  width: 100%;
`;
