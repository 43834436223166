import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useAppBrandingPracticeInfo } from '../../../features/appBranding/utils/useAppBrandingPracticeInfo';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { AppCustomization } from '../../../types/Practices/AppCustomizationType';

export const useAppBrandingConnect = () => {
  const [loading, setLoading] = useState(true);
  const { practiceId } = useAppBrandingPracticeInfo();
  const setAppBrandingZustand = useMainStore((state) => state.setAppBranding);

  useEffect(() => {
    const fetchAppBranding = async () => {
      setLoading(true);
      try {
        const data = (
          await getDoc(doc(db, 'practices/' + practiceId + '/documents/practiceAppCustomization'))
        ).data() as AppCustomization;
        if (data) {
          setAppBrandingZustand(data);
        } else {
          setAppBrandingZustand({ practiceId });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppBranding();
  }, [practiceId]);

  return loading;
};
