import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MockClaimCard } from './MockClaimCard';
import { MockContactCard } from './MockContactCard';
import { MockDescription } from './MockDescription';
import { MockLogo } from './MockLogo';
import { MockOpeningHoursCard } from './MockOpeningHoursCard';
import { MockPracticeImagesCard } from './MockPracticeImagesCard';
import { MockServices } from './MockServices';
import { MockTherapistsOverviewCard } from './MockTherapistsOverviewCard';

type Props = {
  section?: string;
};

export const MockPracticeScreen = ({ section }: Props) => {
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const openingHoursRef = useRef<HTMLDivElement>(null);
  const therapistsRef = useRef<HTMLDivElement>(null);
  const servicesRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const imagesRef = useRef<HTMLDivElement>(null);
  const claimRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const path = section ?? location.pathname.split('/').pop();
      let targetRef;
      switch (path) {
        case 'address':
          targetRef = contactRef;
          break;
        case 'opening':
          targetRef = openingHoursRef;
          break;
        case 'therapists':
          targetRef = therapistsRef;
          break;
        case 'services':
          targetRef = servicesRef;
          break;
        case 'description':
          targetRef = descriptionRef;
          break;
        case 'claim':
          targetRef = claimRef;
          break;
        case 'images':
          targetRef = imagesRef;
          break;
        default:
          targetRef = null;
      }

      if (targetRef && targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [location, section]);

  return (
    <Container ref={containerRef}>
      <Content>
        <MockLogo />
        <MockContactCard ref={contactRef} />
        <MockOpeningHoursCard ref={openingHoursRef} />
        <MockTherapistsOverviewCard ref={therapistsRef} />
        <MockClaimCard ref={claimRef} />
        <MockServices ref={servicesRef} />
        <MockPracticeImagesCard ref={imagesRef} />
        <MockDescription ref={descriptionRef} />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  /* Hide scrollbar */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  /* Disable text selection */
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const Content = styled.div`
  min-height: 100%;
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  padding-top: 22px;
`;
