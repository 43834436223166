import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { IconButton } from '../../../core/components/buttons/IconButton';
import { ModalContainer } from '../../../core/components/container/ModalContainer';
import { SMSPhoneMock } from '../components/SMSPhoneMock';

export const SMSPreviewModalScreen = () => {
  const navigate = useNavigate();
  const { practiceId, smsName, practiceName } = useLocation()?.state;

  const handleClose = () => {
    navigate('/onboarding/sms-names');
  };
  console.log(practiceId, practiceName);
  if (!practiceId || !practiceName || !smsName) {
    handleClose();
  }

  return (
    <ModalContainer handleClose={handleClose}>
      <HeaderContainer>
        <IconButton iconName="Close" onClick={handleClose} size={24} />
      </HeaderContainer>
      <Container>
        <SMSPhoneMock practiceName={practiceName as string} smsName={smsName} practiceId={practiceId} />
      </Container>
    </ModalContainer>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 40px 20px;
  width: 100%;
  gap: 10px;
  max-width: 640px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
