import { useEffect, useRef, useState } from 'react';
import { useMainStore } from '../../../store/mainStore';
import { createSubscription } from './createPracticeSubscription';
import { useOnboardingPracticeInfo } from './useOnboardingPracticeInfo';

export const useCreateSubscription = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const { practiceId, paymentType, allPracticeIds, chainId, practiceIndex } = useOnboardingPracticeInfo();
  const samePayment = paymentType === 'same';
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const asyncFunc = async () => {
      if (!practiceId || !chainId || !allPracticeIds || timeoutRef.current) return;

      try {
        setEventIndicator('success', 'Daten werden verarbeitet');
        timeoutRef.current = setTimeout(() => {
          setEventIndicator('success', 'Checkout wird vorbereitet');
          timeoutRef.current = setTimeout(() => {
            setEventIndicator('success', 'Angebot wird erstellt');
          }, 4000);
        }, 2000);

        const subscriptionData = await createSubscription(
          samePayment ? allPracticeIds : [practiceId],
          chainId,
          practiceIndex ?? 0,
        );
        setClientSecret(subscriptionData.clientSecret);
      } catch (error) {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        setEventIndicator('error', 'Ein Fehler ist aufgetreten');
        console.error(error);
      } finally {
        timeoutRef.current && clearTimeout(timeoutRef.current);
        setLoading(false);
      }
    };
    asyncFunc();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  return { loading, clientSecret };
};
