import styled from 'styled-components';
import { getAppBrandingColors, getAppBrandingServices } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { H4, Mini } from '../../../../styles/textStyles';

import { isEmpty } from 'lodash';
import { forwardRef } from 'react';
import Skeleton from 'react-loading-skeleton';

export const MockServices = forwardRef<HTMLDivElement>((_, ref) => {
  const services = useMainStore(getAppBrandingServices);
  const appColors = useMainStore(getAppBrandingColors);

  return (
    <Card ref={ref}>
      {isEmpty(services) ? (
        <SkeletonWrapperTitle>
          <Skeleton height={22} />
          <Skeleton height={14} count={2} />
        </SkeletonWrapperTitle>
      ) : (
        <Content>
          {services?.map((category, index) => (
            <Category>
              {category.title === '' ? (
                <SkeletonWrapperTitle>
                  <Skeleton height={24} />
                </SkeletonWrapperTitle>
              ) : (
                <CategoryTitle key={index} $color={appColors.Medium}>
                  {category.title}
                </CategoryTitle>
              )}
              <Items>
                {category.services.map((item, index) => (
                  <Item>
                    <Dot $color={appColors.Dark} />
                    {item === '' ? (
                      <SkeletonWrapper>
                        <Skeleton height={14} />
                      </SkeletonWrapper>
                    ) : (
                      <ItemText key={index} $color={appColors.Dark}>
                        {item}
                      </ItemText>
                    )}
                  </Item>
                ))}
              </Items>
            </Category>
          ))}
        </Content>
      )}
    </Card>
  );
});

const CategoryTitle = styled(H4)<{ $color: string }>`
  font-weight: 500;
  color: ${({ $color }) => $color};
  text-align: left;
`;

const Card = styled.div`
  margin: 0 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 20px 15px;
  display: flex;

  overflow: hidden;
  background-color: ${colors.White};
`;

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const SkeletonWrapperTitle = styled(SkeletonWrapper)`
  width: 100%;
  gap: 5px;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Dot = styled.div<{ $color: string }>`
  min-width: 4px;
  min-height: 4px;
  border-radius: 100%;
  background-color: ${({ $color }) => $color};
`;

const Item = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 5px;
  min-height: 20px;
`;

const ItemText = styled(Mini)<{ $color: string }>`
  text-align: left;
  color: ${({ $color }) => $color};
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;
