import { forwardRef } from 'react';
import styled from 'styled-components';
import { GenericSvg } from '../../../../assets/svgs/GenericSVG';
import { CustomButton } from '../../../../core/components/buttons/CustomButton';
import {
  getAppBrandingAddress,
  getAppBrandingColors,
  getAppBrandingContact,
  getAppBrandingMapsInfo,
} from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { Small } from '../../../../styles/textStyles';
import { MockContactTextLine } from './MockContactTextLine';
import { MockMapsImage } from './MockMapsImage';

export const MockContactCard = forwardRef<HTMLDivElement>((_, ref) => {
  const address = useMainStore(getAppBrandingAddress);
  const googleMapsInfo = useMainStore(getAppBrandingMapsInfo);
  const appColors = useMainStore(getAppBrandingColors);
  const contact = useMainStore(getAppBrandingContact);

  const handleAddress = () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${googleMapsInfo?.longitude},${googleMapsInfo?.longitude}&query_place_id=${googleMapsInfo?.googleMapsId}`,
    );
  };

  const handleCall = () => {
    window.open(`tel:${contact?.phone}`);
  };

  const handleMail = () => {
    window.open(`mailto:${contact?.mail}`);
  };

  const handleWebsite = () => {
    window.open(contact?.website, '_blank');
  };

  return (
    <TherapistCard ref={ref}>
      <MockMapsImage />

      <Content>
        <Section>
          <Headline $color={appColors.Dark}>{'Addresse'}</Headline>
          <ClickableRow onClick={!address?.street ? undefined : handleAddress}>
            <AddressContainer>
              <MockContactTextLine text={address?.practiceName} />
              <MockContactTextLine text={address?.owner} />
              <MockContactTextLine text={address?.street ? address?.street + ' ' + address?.houseNumber : undefined} />
              <MockContactTextLine text={address?.city ? address?.zip + ' ' + address?.city : undefined} />
            </AddressContainer>
            <GenericSvg name="CircleLocation" primaryColor={appColors.Primary} size={24} />
          </ClickableRow>
        </Section>
        <Section>
          <Headline $color={appColors.Dark}>{'Kontakt'}</Headline>
          <ContactRow onClick={!contact?.phone ? undefined : handleCall}>
            <MockContactTextLine text={contact?.phone} />
            <GenericSvg name="CirclePhone" primaryColor={appColors.Primary} size={24} />
          </ContactRow>
        </Section>
        <Section>
          <ContactRow onClick={!contact?.mail ? undefined : handleMail}>
            <MockContactTextLine text={contact?.mail} />
            <GenericSvg name="CircleMail" primaryColor={appColors.Primary} size={24} />
          </ContactRow>
        </Section>
        {contact?.website && (
          <Section>
            <ContactRow onClick={!contact?.website ? undefined : handleWebsite}>
              <MockContactTextLine text={contact?.website} />
              <GenericSvg name="CircleWebsite" primaryColor={appColors.Primary} size={24} />
            </ContactRow>
          </Section>
        )}
      </Content>
    </TherapistCard>
  );
});

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
  width: 100%;
`;

const ClickableRow = styled(CustomButton)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

const Section = styled.div`
  padding: 8px 0;
`;

const Content = styled.div`
  padding: 15px;
`;

const Headline = styled(Small)<{ $color: string }>`
  font-weight: 500;
  color: ${({ $color }) => $color};
  margin-bottom: 10px;
`;

const ContactRow = styled(ClickableRow)`
  align-items: center;
`;

const TherapistCard = styled.div`
  margin: 0 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.White};
  overflow: hidden;
`;
