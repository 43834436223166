import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MainButton } from '../../../../core/components/buttons/MainButton';
import { NavigationHeader } from '../../../../navigation/components/NavigationHeader';
import { AdminSalesBenefitsCard } from '../components/AdminSalesBenefitsCard';

export const AdminSalesBenefitsScreen = () => {
  const navigate = useNavigate();

  const close = () => {
    navigate('/admin/sales/tools');
  };
  const patientBenefits = [
    'Feature - Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dia',
    'Feature - Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dia',
    'Feature - Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dia',
  ];

  const therapistBenefits = [
    'Feature - Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dia',
    'Feature - Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dia',
    'Feature - Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dia',
  ];

  const practiceBenefits = [
    'Feature - Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dia',
    'Feature - Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dia',
    'Feature - Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dia',
  ];
  return (
    <Container>
      <ContentContainer>
        <AdminSalesBenefitsCard title="Vorteile für Therapeuten" benefits={therapistBenefits} />
        <AdminSalesBenefitsCard title="Vorteile für Patienten" benefits={patientBenefits} />
        <AdminSalesBenefitsCard title="Vorteile für Praxen" benefits={practiceBenefits} />
      </ContentContainer>
      <StyledMainButton text="Weiter" />
      <NavigationHeader onCloseClick={close} />
    </Container>
  );
};

const Container = styled.div`
  max-width: 1920px;
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
  padding: 0 40px;
  overflow: hidden;
`;

const StyledMainButton = styled(MainButton)`
  position: absolute;
  bottom: 20px;
  right: 40px;
`;
