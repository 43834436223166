import { Autocomplete } from '@react-google-maps/api';
import { checkVAT, countries } from 'jsvat';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { useGooglePlacesAutocomplete } from '../utils/useGooglePlacesAutocomplete';

type Props = {
  fields: {
    practiceName: string;
    street: string;
    houseNumber: string;
    cityCode: string;
    city: string;
    taxId: string;
  };
  handleInput: (name: string, value: string) => void;
  setFields: (fields: any) => void;
  loadingData?: boolean;
};

export const BillingForm = ({ fields, handleInput, setFields, loadingData }: Props) => {
  const { t } = useTranslation();
  const { practiceName, street, houseNumber, cityCode, city, taxId } = fields;
  const [taxIdError, setTaxIdError] = useState('');

  const changeTaxId = (newTaxId: string) => {
    setTaxIdError('');
    const result = checkVAT(newTaxId, countries);
    if (!result.isValid && newTaxId.length > 0) {
      setTaxIdError('Ungültige Umsatzsteuer-ID');
    }
    handleInput('taxId', newTaxId);
  };

  const { googleApiLoaded, googleApiError, autocompleteOptions, onLoad, onPlaceChanged } =
    useGooglePlacesAutocomplete();

  const handlePlaceChanged = () => {
    const newAddress = onPlaceChanged();
    if (newAddress) {
      setFields({
        ...fields,
        ...newAddress,
      });
    }
  };
  return (
    <FormContainer>
      <SingleTextInput
        loadingData={loadingData}
        id="firstInput"
        value={practiceName}
        onChange={(value) => handleInput('practiceName', value)}
        placeholder={t('ONBOARDING-ADDRESS-INFORMATION-FORM-PRACTICE-NAME')}
        noPasswordManager
        type="practiceName"
      />

      <HorizontalContainer>
        <LargeContainer>
          {!loadingData && googleApiLoaded && !googleApiError ? (
            <Autocomplete onLoad={onLoad} onPlaceChanged={handlePlaceChanged} options={autocompleteOptions}>
              <SingleTextInput
                value={street}
                onChange={(value) => handleInput('street', value)}
                placeholder={t('ONBOARDING-ADDRESS-INFORMATION-FORM-PRACTICE-STREET')}
                type="street"
              />
            </Autocomplete>
          ) : (
            <SingleTextInput
              loadingData={loadingData}
              value={street}
              onChange={(value) => handleInput('street', value)}
              placeholder={t('ONBOARDING-ADDRESS-INFORMATION-FORM-PRACTICE-STREET')}
            />
          )}
        </LargeContainer>
        <SmallContainer>
          <SingleTextInput
            loadingData={loadingData}
            value={houseNumber}
            onChange={(value) => handleInput('houseNumber', value)}
            placeholder={t('ONBOARDING-ADDRESS-INFORMATION-FORM-PRACTICE-HOUSE-NUMBER')}
          />
        </SmallContainer>
      </HorizontalContainer>
      <HorizontalContainer>
        <SmallContainer>
          <SingleTextInput
            loadingData={loadingData}
            value={cityCode}
            onChange={(value) => handleInput('cityCode', value)}
            placeholder={t('ONBOARDING-ADDRESS-INFORMATION-FORM-PRACTICE-ZIP')}
          />
        </SmallContainer>
        <LargeContainer>
          <SingleTextInput
            loadingData={loadingData}
            value={city}
            onChange={(value) => handleInput('city', value)}
            placeholder={t('ONBOARDING-ADDRESS-INFORMATION-FORM-PRACTICE-STATE')}
          />
        </LargeContainer>
      </HorizontalContainer>
      <SingleTextInput
        loadingData={loadingData}
        value={taxId}
        onChange={changeTaxId}
        placeholder={t('ONBOARDING-ADDRESS-INFORMATION-FORM-PRACTICE-TAXNUMBER')}
        error={taxIdError}
      />
    </FormContainer>
  );
};

const LargeContainer = styled.div`
  width: 75%;
`;

const SmallContainer = styled.div`
  width: 25%;
`;

const HorizontalContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
