import { useState } from 'react';
import { getAppBrandingDescription } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { AppBrandingDescription } from '../../../appBranding/components/AppBrandingDescription';
import { useAppBrandingPracticeInfo } from '../../../appBranding/utils/useAppBrandingPracticeInfo';
import { SettingsBrandingComponentsWrapper } from './SettingsBrandingComponentsWrapper';

export const SettingsBrandingDescription = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { uploadAppBrandingData, uploading } = useAppBrandingPracticeInfo();
  const zustandAppBrandingDescription = useMainStore(getAppBrandingDescription);
  const [initialAppBrandingDescription, setInitialAppBrandingDescription] = useState(zustandAppBrandingDescription);
  const nextButtonDisabled = zustandAppBrandingDescription === initialAppBrandingDescription;
  const handleSave = async () => {
    setInitialAppBrandingDescription(zustandAppBrandingDescription);
    await uploadAppBrandingData();
    setEventIndicator('success', 'Änderungen gespeichert');
  };
  return (
    <SettingsBrandingComponentsWrapper
      title="Praxisbeschreibung"
      description="Schreibe hier was deine Praxis ausmacht und gib deinen Patienten einen Einblick in deine Motivation und Geschichte. Lass sie wissen, warum sie bei dir in den besten Händen sind."
      nextButton={{
        text: 'Speichern',
        onClick: handleSave,
        loading: uploading,
        disabled: nextButtonDisabled,
      }}
    >
      <AppBrandingDescription />
    </SettingsBrandingComponentsWrapper>
  );
};
