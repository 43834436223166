import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { AccordionComponent } from '../../../core/components/AccordionComponent';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { InfoText } from '../../../core/components/text/InfoText';
import { usePracticeConnect } from '../../../core/utils/connectFirestore/usePracticeConnect';
import { getAppBrandingLogoUrl } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { Body } from '../../../styles/textStyles';
import { AppBrandingScreenContainer } from '../components/AppBrandingScreenContainer';
import { AppBrandingVideoUpload } from '../components/AppBrandingVideoUpload';
import { useAppBrandingPracticeInfo } from '../utils/useAppBrandingPracticeInfo';

export const AppBrandingVideoScreen = () => {
  const { t } = useTranslation();
  const { practiceName, practiceId } = useAppBrandingPracticeInfo();
  const initialAppBrandingColor = useMainStore(getAppBrandingLogoUrl);
  const [loading, setLoading] = useState(false);
  const { practice } = usePracticeConnect(practiceId);
  const onboardingVideo = practice?.assets?.onboardingVideo;

  const navigate = useNavigate();

  const handleNext = () => {
    navigate('../summary');
  };

  const handleBack = () => {
    navigate('../description');
  };

  const currentStep = initialAppBrandingColor ? 1 : 0;

  const copyText = () => {
    const text = `Hallo, ich bin XY aus der XY Physiotherapiepraxis / dem XY Rehazentrum.
    
    Ich freue mich, dir unsere App vorzustellen, mit der wir dich während deiner Therapie auch digital begleiten können. Hier sind ein paar nützliche Informationen für dein Programm:
    
    Das Programm dauert 8 Wochen und beinhaltet jede Woche 5 Übungen, die sich wöchentlich ändern und im Verlauf anspruchsvoller werden. Du solltest diese Übungen mindestens einmal pro Woche machen, idealerweise drei Mal pro Woche. Alle sieben Tage werden die nächsten Übungen freigeschaltet, sobald du die Erfolgskontrolle der vorherigen Woche abgeschlossen hast.
    
    Für deinen optimalen Therapieerfolg und die Abrechnung mit deiner Krankenkasse ist es sehr wichtig, dass du mindestens für acht Wochen an dem Programm teilnimmst. Als Dankeschön erhältst du zwei Bonuswochen und die Möglichkeit, ein Aufbauprogramm zu machen.
    
    Wir wünschen dir viel Spaß und Erfolg mit deinem persönlichen Übungsprogramm!`;

    navigator.clipboard.writeText(text);
  };

  return (
    <AppBrandingScreenContainer
      handleBack={handleBack}
      nextButton={{
        text: 'Weiter',
        onClick: handleNext,
        numberOfSteps: onboardingVideo ? 1 : 0,
        currentStep: currentStep,
        loading: loading,
        disabled: !onboardingVideo,
      }}
      textButton={onboardingVideo ? undefined : { text: 'Schritt überspringen', onClick: handleNext }}
    >
      <div>
        <StyledInfoText
          headline={'Praxis Vorstellung'}
          text={[
            'Begrüße deine Patienten mit einem kurzen Intro Video. Stelle dabei Physiofit, dein Team und deine Praxis kurz vor.',
          ]}
          preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName: practiceName })}
        />
        <InfoContainer>
          <AccordionComponent title="Beispieltext anzeigen">
            <TextExample>
              <Paragraph>Hallo, ich bin XY aus der XY Physiotherapiepraxis / dem XY Rehazentrum.</Paragraph>
              <Paragraph>
                Ich freue mich, dir unsere App vorzustellen, mit der wir dich während deiner Therapie auch digital
                begleiten können. Hier sind ein paar nützliche Informationen für dein Programm:
              </Paragraph>
              <Paragraph>
                Das Programm dauert 8 Wochen und beinhaltet jede Woche 5 Übungen, die sich wöchentlich ändern und im
                Verlauf anspruchsvoller werden. Du solltest diese Übungen mindestens einmal pro Woche machen,
                idealerweise drei Mal pro Woche. Alle sieben Tage werden die nächsten Übungen freigeschaltet, sobald du
                die Erfolgskontrolle der vorherigen Woche abgeschlossen hast.
              </Paragraph>
              <Paragraph>
                Für deinen optimalen Therapieerfolg und die Abrechnung mit deiner Krankenkasse ist es sehr wichtig, dass
                du mindestens für acht Wochen an dem Programm teilnimmst. Als Dankeschön erhältst du zwei Bonuswochen
                und die Möglichkeit, ein Aufbauprogramm zu machen.
              </Paragraph>
              <Paragraph>Wir wünschen dir viel Spaß und Erfolg mit deinem persönlichen Übungsprogramm!</Paragraph>
            </TextExample>
          </AccordionComponent>
          <ButtonContainer>
            <TextButton
              text="Text kopieren"
              onClick={copyText}
              icon={{ name: 'Clipboard', size: 24, color: colors.Primary }}
            />
          </ButtonContainer>
          <Info>
            <GenericSvg name="Info" primaryColor={colors.Primary} size={24} />
            <div>
              <Body>
                Hochkant mit dem Handy gefilmt, idealerweise mit Mikrofon. Für eine flüssige Präsentation könnt ihr
                einen Teleprompter nutzen.
              </Body>
            </div>
          </Info>
          <ButtonContainer>
            <TextButton
              text="Online Teleprompter"
              onClick={() => window.open('https://telepromptermirror.com/telepromptersoftware.htm', '_blank')}
              icon={{ name: 'Text', size: 24, color: colors.Primary }}
            />
          </ButtonContainer>
        </InfoContainer>

        <AppBrandingVideoUpload setLoading={setLoading} />
      </div>
    </AppBrandingScreenContainer>
  );
};

const StyledInfoText = styled(InfoText)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const TextExample = styled(Body)`
  font-style: italic;
`;

const Paragraph = styled.p`
  margin-bottom: 1em;
`;

const InfoContainer = styled.div`
  margin-bottom: 20px;
`;

const Info = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  background-color: ${colors.Light};
  padding: 20px;
  border-radius: 20px;
  gap: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
