import { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import styled from 'styled-components';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { getAppBrandingColors } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';

export const getRgbFromHex = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
};

export const AppBrandingColorPicker = () => {
  const brandingColor = useMainStore(getAppBrandingColors)?.Primary;
  const [rgb, setRgb] = useState(getRgbFromHex(brandingColor));
  const saveColors = useMainStore((state) => state.setAppBrandingColors);
  const [localColor, setLocalColor] = useState(brandingColor);

  const handleColorChange = (newColor: string) => {
    newColor = newColor.replace(/[^a-fA-F0-9]/g, '');
    let hexColor = newColor.startsWith('#') ? newColor : `#${newColor}`;
    hexColor = hexColor.toUpperCase();
    if (hexColor.length > 7) {
      hexColor = hexColor.slice(0, 7);
    }
    setLocalColor(hexColor);

    if (hexColor.length < 7) {
      hexColor = hexColor + '0'.repeat(7 - hexColor.length);
    }
    updateRgbFromHex(hexColor);
    saveColors({
      primary: hexColor,
      light: '#EFF1F1',
      medium: '#909090',
      dark: '#424242',
    });
  };

  const updateRgbFromHex = (hex: string) => {
    setRgb(getRgbFromHex(hex));
  };

  const handleRgbChange = (channel: 'r' | 'g' | 'b', value: string) => {
    const numValue = Math.min(255, Math.max(0, parseInt(value) || 0));
    const newRgb = { ...rgb, [channel]: numValue };
    setRgb(newRgb);
    let hexColor = `#${newRgb.r.toString(16).padStart(2, '0')}${newRgb.g.toString(16).padStart(2, '0')}${newRgb.b.toString(16).padStart(2, '0')}`;
    hexColor = hexColor.toUpperCase();
    setLocalColor(hexColor);
    saveColors({
      primary: hexColor,
      light: '#EFF1F1',
      medium: '#909090',
      dark: '#424242',
    });
  };
  return (
    <Row>
      <ColorPickerContainer>
        <HexColorPicker color={brandingColor} onChange={handleColorChange} />
      </ColorPickerContainer>
      <Column>
        <SingleTextInput label={'Hex Code #'} placeholder={'#000000'} value={localColor} onChange={handleColorChange} />

        <RgbInputContainer>
          <SingleTextInput
            label={'R'}
            placeholder={'0'}
            value={rgb.r.toString()}
            onChange={(value) => handleRgbChange('r', value)}
          />
          <SingleTextInput
            label={'G'}
            placeholder={'0'}
            value={rgb.g.toString()}
            onChange={(value) => handleRgbChange('g', value)}
          />
          <SingleTextInput
            label={'B'}
            placeholder={'0'}
            value={rgb.b.toString()}
            onChange={(value) => handleRgbChange('b', value)}
          />
        </RgbInputContainer>
      </Column>
    </Row>
  );
};

const RgbInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
`;

const Row = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 50px;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    padding-top: 0px;
  }
`;

const ColorPickerContainer = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;
