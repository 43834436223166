import styled from 'styled-components';
import MockHero from '../../../../assets/images/MockHero.jpg';

export const MockHeaderImage = () => {
  return (
    <Container>
      <HeaderImage src={MockHero} />
      <TopGradient />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const HeaderImage = styled.img`
  height: 230px;
  width: 100%;
  position: relative; // Add this line
`;

const TopGradient = styled.div`
  background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
`;
