import React from 'react';
import styled from 'styled-components';
import { hexWithOpacity } from '../../../../core/utils/hexWithOpacity';
import { colors } from '../../../../styles/colors';
import { H2 } from '../../../../styles/textStyles';

type AdminSalesBenefitsCardProps = {
  title: string;
  benefits: string[];
};

export const AdminSalesBenefitsCard = React.memo(({ title, benefits }: AdminSalesBenefitsCardProps) => {
  return (
    <Container>
      <StyledH2>{title}</StyledH2>
      <List>
        {benefits.map((benefit, index) => (
          <ListItem key={index}>{benefit}</ListItem>
        ))}
      </List>
    </Container>
  );
});

const Container = styled.div`
  flex: 1;
  max-width: 480px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${hexWithOpacity(colors.Medium, 0.1)};
  color: ${colors.Medium};
  border-radius: 20px;
  gap: 40px;
  min-height: 460px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.Primary};
    color: ${colors.White} !important;

    h2 {
      color: ${colors.White};
    }

    li {
      color: ${colors.White};
    }
  }
`;

const StyledH2 = styled(H2)`
  color: ${colors.Dark};
  transition: color 0.3s ease;
`;

const List = styled.ul`
  list-style: inside;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ListItem = styled.li`
  font-family: 'Lora';
  font-size: 15px;
  font-weight: 400px;
  transition: color 0.3s ease;
`;
