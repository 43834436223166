import { useParams } from 'react-router-dom';
import { getChainOnboardingPracticeInfo, getChainPractices, getIsAddNewPractice } from '../../../store/chainSlice';
import { getInvitationTypeIsMigration } from '../../../store/invitationSlice';
import { useMainStore } from '../../../store/mainStore';

export const useOnboardingPracticeInfo = () => {
  const { practiceId } = useParams();
  const allPracticeIds = useMainStore(getChainPractices)?.map((e) => e.id);
  const data = useMainStore((state) => getChainOnboardingPracticeInfo(state, practiceId));
  const isMigration = useMainStore(getInvitationTypeIsMigration);
  const isAddNewPractice = useMainStore(getIsAddNewPractice);
  return { isAddNewPractice, allPracticeIds, isMigration, practiceId, ...data };
};
