import styled from 'styled-components';
import { MockHeaderImage } from './MockHeaderImage';
import { MockLogo } from './MockLogo';
import { MockProgressCard } from './MockProgressCard';
import { MockTherapistCard } from './MockTherapistCard';
import { MockWeekHeader } from './MockWeekHeader';

export const MockHomeScreen = () => {
  return (
    <Container>
      <MockLogo />
      <MockHeaderImage />
      <MockProgressCard />
      <MockTherapistCard />
      <MockWeekHeader />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 22px;
`;
