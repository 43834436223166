import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InfoText } from '../../../core/components/text/InfoText';
import { AppBrandingScreenContainer } from '../components/AppBrandingScreenContainer';
import { AppBrandingTherapistsOverview } from '../components/AppBrandingTherapistsOverview';
import { useAppBrandingPracticeInfo } from '../utils/useAppBrandingPracticeInfo';

export const AppBrandingTherapistsScreen = () => {
  const { t } = useTranslation();
  const { practiceName } = useAppBrandingPracticeInfo();
  const [uploading, setUploading] = useState(false);

  const navigate = useNavigate();

  const handleNext = () => {
    navigate('../claim');
  };

  const handleBack = () => {
    navigate('../opening');
  };

  return (
    <AppBrandingScreenContainer
      handleBack={handleBack}
      nextButton={{
        text: 'Weiter',
        onClick: handleNext,
        numberOfSteps: 3,
        currentStep: 3,
        loading: uploading,
      }}
    >
      <StyledInfoText
        headline={'Stelle dein Team vor'}
        text={[
          'Die Therapeuten, die du in den Standorteinstellungen angelegt hast, werden hier angezeigt Lade Fotos hoch, um dein Team optimal deinen Patienten vorzustellen..',
        ]}
        preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName: practiceName })}
      />
      <AppBrandingTherapistsOverview setUploading={setUploading} />
    </AppBrandingScreenContainer>
  );
};
const StyledInfoText = styled(InfoText)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
