import styled from 'styled-components';
import { hexWithOpacity } from '../../../core/utils/hexWithOpacity';
import { getInvitationData } from '../../../store/invitationSlice';
import { useMainStore } from '../../../store/mainStore';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';
import { colors } from '../../../styles/colors';
import { Body } from '../../../styles/textStyles';
import { CheckoutContactButton } from './CheckoutContactButton';

export const CheckoutCalculatorButtons = () => {
  const invitationData = useMainStore(getInvitationData);
  const contactName = invitationData?.hubspotData?.ownerData?.firstName ?? 'Manuel';

  return (
    <Container>
      {/* <Row>
        {!isMobileWidth && <GenericSvg name="ArrowCurved" size={60} primaryColor={colors.Primary} />}{' '}
        <StyledBody>{'Errechne dein Praxis Potential mit dem Physiofit Kalkulator:'}</StyledBody>
        <ButtonContainer>
          <TextButton
            text="Potential berechnen"
            onClick={openCalculator}
            color={colors.Dark}
            noPadding
            icon={{ color: colors.Dark, name: 'Calculator', size: 24 }}
          />
        </ButtonContainer>
      </Row> */}
      <Row>
        <div>
          <StyledBody>{'Du hast noch Fragen?'}</StyledBody>
          <StyledBody>
            {`Melde dich gerne telefonisch bei ${contactName} oder schreibe uns direkt hier im Chat.`}
          </StyledBody>
        </div>
        <CheckoutContactButton />
      </Row>
    </Container>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${hexWithOpacity(colors.Medium, 0.1)};
  border-radius: 10px;
  justify-content: space-between;
  gap: 20px;
  padding: 30px 20px;
  align-items: center;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    padding: 20px 10px;
  }
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
  flex: 1;
  min-width: 200px;
`;

const Container = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;
