import styled from 'styled-components';
import { MockOnboardingVideo } from './MockOnboardingVideo';

export const MockVideoScreen = () => {
  return (
    <Container>
      <MockOnboardingVideo />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
