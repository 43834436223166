import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { Body, H3 } from '../../../styles/textStyles';
import { Therapist } from '../../../types/Practices/TherapistType';
import { TherapistCell } from './TherapistCell';

type Props = {
  therapists?: Therapist[];
};

export const InactiveTherapists = ({ therapists }: Props) => {
  if (!therapists || isEmpty(therapists)) {
    return null;
  } else {
    return (
      <Container>
        <StyledH3>Deaktivierte Therapeuten</StyledH3>
        <StyledBody>
          Der persönliche Link von deaktivierten Nutzern funktioniert nach der Deaktivierung nicht mehr. Weiterhin
          können deaktivierte Nutzer keine Patienten mehr einladen oder die Einladungshistorie einsehen.{' '}
        </StyledBody>
        {therapists?.map((therapist) => <TherapistCell key={therapist.id} therapist={therapist} />)}
      </Container>
    );
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
`;
