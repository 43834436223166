import React from 'react';
import styled from 'styled-components';
import { GenericSvg, SVGName } from '../../../assets/svgs/GenericSVG';
import { CustomButton } from './CustomButton';

type Props = {
  onClick?: (event: React.MouseEvent) => void;
  iconName: SVGName;
  stroke?: string;
  secondaryColor?: string;
  size?: number;
};

export const IconButton = React.forwardRef((props: Props, ref: React.Ref<HTMLButtonElement>) => {
  return (
    <StyledCustomButton ref={ref} onClick={props.onClick}>
      <GenericSvg
        name={props.iconName}
        primaryColor={props.stroke}
        secondaryColor={props.secondaryColor}
        size={props.size ?? 30}
      />
    </StyledCustomButton>
  );
});
const StyledCustomButton = styled(CustomButton)`
  height: 48px;
  width: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
