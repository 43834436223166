import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { InfoText } from '../../../core/components/text/InfoText';

import { setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain';
import { OnboardingScreenContainer } from '../components/OnboardingScreenContainer';
import { useHandleOnSubmitKeyPress } from '../utils/useHandleOnSubmitKeyPress';
import { useOnboardingPracticeInfo } from '../utils/useOnboardingPracticeInfo';

export const OnboardingPracticeDataInfoScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { practiceId, paymentType, practiceIndex, practiceName, previousPracticeId } = useOnboardingPracticeInfo();
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    setLoading(true);
    try {
      await setChainOnboardingFlag(`practices.${practiceId}.info`, 'practices-info');
      navigate(`../practice-payout-data`);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (previousPracticeId) {
      if (practiceIndex === 1 && paymentType === 'same') {
        navigate('../payment-type');
      } else {
        navigate(`../../${previousPracticeId}/invoice-address/`);
      }
    } else {
      navigate('../../practices-names');
    }
  };
  useHandleOnSubmitKeyPress(handleNext);

  if (!practiceId || practiceId === 'undefined') {
    return <Navigate to="/onboarding/practices-names" />;
  }

  return (
    <OnboardingScreenContainer
      handleBack={handleBack}
      nextButton={{
        onClick: handleNext,
        loading: loading,
      }}
    >
      <InfoText
        headline={t('ONBOARDING-PRACTICE-DATA-INFO-SCREEN-TITLE', { practiceName: practiceName })}
        text={[t('ONBOARDING-PRACTICE-DATA-INFO-SCREEN-SUBTITLE', { practiceName: practiceName })]}
      />
    </OnboardingScreenContainer>
  );
};
