import styled from 'styled-components';
import { GenericSvg } from '../../../assets/svgs/GenericSVG';
import { colors } from '../../../styles/colors';
import { Body, H2 } from '../../../styles/textStyles';

export const ComingSoonCard = () => {
  return (
    <Container>
      <BannerContainer>
        <Banner>
          <BannerText>{'Bald verfügbar'}</BannerText>
        </Banner>
      </BannerContainer>
      <TopContainer>
        <ContentContainer>
          <StyledH2>{'Dein Physiofit Dashboard'}</StyledH2>
          <StyledBody>
            {
              'Das Physiofit Dashboard bietet dir eine schnelle Übersicht über alle deine Standorte, Vergütungen und Team-Auswertungen.'
            }
          </StyledBody>
        </ContentContainer>
      </TopContainer>
      <GenericSvg
        name={'DashboardPreview'}
        width={'100%'}
        height={120}
        primaryColor={colors.Light}
        secondaryColor={colors.Medium}
      />
    </Container>
  );
};

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`;

const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const Banner = styled.div`
  background-color: ${colors.Success};
  padding: 5px 20px;
  border-radius: 5px;
  transform: rotate(5deg);
`;
const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${colors.White};
  width: 100%;
  padding: 40px 30px 30px 30px;
  border: 2px solid ${colors.Light};
  border-radius: 20px;
  gap: 15px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const StyledH2 = styled(H2)`
  color: ${colors.Medium};
`;

const StyledBody = styled(Body)`
  color: ${colors.Medium};
`;

const BannerText = styled(Body)`
  color: ${colors.White};
`;
