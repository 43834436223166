import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { LoaderFunction, Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { IconButton } from '../../../core/components/buttons/IconButton';
import { LabeledSwitchButton } from '../../../core/components/buttons/LabeledSwitchButton';
import { InfoText } from '../../../core/components/text/InfoText';
import { SingleTextInput } from '../../../core/components/textInput/SingleTextInput';
import { setChainOnboardingFlag } from '../../../core/utils/editFirestore/editChain';
import { useWindowDimensions } from '../../../core/utils/useWindowDimensions';
import { db } from '../../../firebaseConfig';
import { PhoneMockLayoutContainer } from '../../../navigation/components/PhoneMockLayoutContainer';
import { getChainPractices } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { DESKTOP_BREAKPOINT } from '../../../styles/breakpoints';
import { Practice } from '../../../types/Practices/PracticeType';
import { AppBrandingScreenContainer } from '../../appBranding/components/AppBrandingScreenContainer';
import { SMSPhoneMock } from '../components/SMSPhoneMock';

export const smsNamesDataLoader: LoaderFunction = async () => {
  const state = useMainStore.getState();
  const practiceIds = getChainPractices(state)?.map((p) => p.id);
  if (!practiceIds) return null;
  try {
    const practicesSnapshot = await Promise.all(practiceIds.map((id) => getDoc(doc(db, 'practices/' + id))));
    const practiceData = practicesSnapshot.map((doc) => doc.data() as Practice);
    return practiceData ?? null;
  } catch (error) {
    console.error(error);
  }
};
export const OnboardingSMSNamesScreen = () => {
  const navigate = useNavigate();
  const { isMobileWidth } = useWindowDimensions();
  const practiceData = useLoaderData() as null | Practice[];
  const smsData =
    practiceData?.map((p) => ({ id: p.id, smsName: p.smsName ?? 'Physiofit', practiceName: p.name })) ?? [];
  const namesAreEqual = smsData.every((p) => p.smsName === smsData[0].smsName);
  const [differentNames, setDifferentNames] = useState(namesAreEqual);
  const [smsNames, setSmsNames] = useState<{ id: string; smsName: string; practiceName: string }[]>(smsData ?? []);
  const [loading, setLoading] = useState(false);
  const [lengthErrors, setLengthErrors] = useState<{ index: number; text: string; resetTimer: NodeJS.Timeout }[]>([]);
  const [focusIndex, setFocusIndex] = useState<number>(0);

  const toggleSwitch = () => {
    if (!differentNames) {
      const firstSmsName = smsNames[0].smsName;
      setSmsNames(smsNames.map((p) => ({ ...p, smsName: firstSmsName })));
    }
    setFocusIndex(0);
    setDifferentNames(!differentNames);
  };

  const changeSMSName = (index: number, value: string) => {
    let newValue = value;
    const newSMSNames = [...smsNames];

    let errorMessage = '';
    const specialChars = /[^A-Za-z0-9]/g;
    if (specialChars.test(value)) {
      newValue = value
        .replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/Ä/g, 'Ae')
        .replace(/Ö/g, 'Oe')
        .replace(/Ü/g, 'Ue')
        .replace(/ß/g, 'ss');
      newValue = newValue.replace(specialChars, '');
      errorMessage = 'Keine Umlaute oder Sonderzeichen möglich';
    }
    if (value.length > 15) {
      newValue = newValue.substring(0, 15);
      errorMessage = 'Max. 15 Zeichen';
    }

    if (errorMessage !== '') {
      const existingError = lengthErrors.find((e) => e.index === index);
      if (existingError?.resetTimer) {
        clearTimeout(existingError.resetTimer);
      }
      const resetTimer = setTimeout(() => {
        setLengthErrors((prev) => prev.filter((e) => e.index !== index));
      }, 2000);

      setLengthErrors((prev) => {
        const filtered = prev.filter((e) => e.index !== index);
        return [...filtered, { index, text: errorMessage, resetTimer }];
      });
    } else {
      setLengthErrors((prev) => prev.filter((e) => e.index !== index));
    }
    if (differentNames) {
      newSMSNames[index].smsName = newValue;
    } else {
      newSMSNames.forEach((p) => (p.smsName = newValue));
    }
    setSmsNames(newSMSNames);
  };

  const handleNext = async () => {
    setLoading(true);
    try {
      await setChainOnboardingFlag('practiceSMSNames', 'practiceSMSNames');
      smsNames.forEach((p) => {
        updateDoc(doc(db, 'practices/' + p.id), { smsName: p.smsName.trim() });
      });
      navigate('../feedback');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const showPreview = (index: number) => {
    setFocusIndex(index);
    navigate('./sms-preview', {
      state: {
        smsName: smsNames[index].smsName,
        practiceId: smsNames[index].id,
        practiceName: smsNames[index].practiceName,
      },
    });
  };

  const disabled = !smsNames || smsNames.some((p) => !p.smsName);

  const showToggle = smsNames.length > 1;

  return (
    <PhoneMockLayoutContainer>
      <AppBrandingScreenContainer
        hideCloseButton
        nextButton={{
          onClick: handleNext,
          disabled,
          loading,
        }}
      >
        <InfoText
          headline={'Praxisname für Einladungs-SMS festlegen'}
          text={[
            'Gib hier den Namen deiner Praxis ein, der in der Einladungs-SMS an deine Patienten angezeigt wird. So wissen deine Patienten direkt, von wem die Nachricht kommt, und können den Übungsplan leichter zuordnen.',
          ]}
        />
        <ContentContainer>
          {showToggle && (
            <LabeledSwitchButton
              label={'Unterschiedliche Praxisnamen je Praxis verwenden'}
              isChecked={differentNames}
              setIsChecked={toggleSwitch}
            />
          )}
          <div>
            {differentNames ? (
              smsNames.map((practice, index) => (
                <Row>
                  <SingleTextInput
                    id={index === 0 ? 'firstInput' : ''}
                    key={index}
                    value={practice.smsName}
                    onChange={(value) => changeSMSName(index, value)}
                    placeholder={'Max. 15 Zeichen'}
                    label={`"${practice.practiceName}" - Praxisname für Einladungs-SMS`}
                    error={lengthErrors.find((e) => e.index === index)?.text}
                    onFocus={() => setFocusIndex(index)}
                  />
                  {isMobileWidth && <IconButton iconName="EyeOpen" onClick={() => showPreview(index)} />}{' '}
                </Row>
              ))
            ) : (
              <Row>
                <SingleTextInput
                  id={'firstInput'}
                  value={smsNames[0]?.smsName}
                  onChange={(value) => changeSMSName(0, value)}
                  placeholder={'Max. 15 Zeichen'}
                  label="Praxisname für Einladungs-SMS"
                  error={lengthErrors[0]?.text}
                  onFocus={() => setFocusIndex(0)}
                />
                {isMobileWidth && <IconButton iconName="EyeOpen" onClick={() => showPreview(0)} />}{' '}
              </Row>
            )}
          </div>
        </ContentContainer>
      </AppBrandingScreenContainer>
      <StyledSMSPhoneMock smsName={smsNames[focusIndex]?.smsName} practiceId={smsNames[focusIndex]?.id} />
      <Outlet />
    </PhoneMockLayoutContainer>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
`;

const StyledSMSPhoneMock = styled(SMSPhoneMock)`
  position: absolute;
  top: 0;
  right: 0;
  @media (max-width: ${DESKTOP_BREAKPOINT}) {
    display: none;
  }
`;
