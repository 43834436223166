import { doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { CustomButton } from '../../../core/components/buttons/CustomButton';
import { IconButton } from '../../../core/components/buttons/IconButton';
import { LoadingSpinner } from '../../../core/components/loading/LoadingSpinner';
import { db } from '../../../firebaseConfig';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { H4 } from '../../../styles/textStyles';
import { Therapist } from '../../../types/Practices/TherapistType';

type Props = {
  therapist?: Therapist;
  setUploading: (uploading: boolean) => void;
};

export const AppBrandingTherapist = ({ therapist, setUploading }: Props) => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const [file, setFile] = useState<File | null>();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onButtonClick = () => {
    inputRef.current?.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  const MAX_FILE_SIZE_MB = 5;
  const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

  const handleFiles = async (files: FileList) => {
    const file = files[0];
    const fileSizeMB = Math.round((file.size / 1024 / 1024) * 1000) / 1000;
    if (file.size > MAX_FILE_SIZE) {
      alert(
        `Die Datei ist zu groß (${fileSizeMB}MB). Bitte wähle eine Datei mit einer Größe von maximal ${MAX_FILE_SIZE_MB}MB.`,
      );
      return;
    }
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      setUploading(true);
      setLoading(true);
      setFile(file);
      const storage = getStorage();
      const storageRef = ref(storage, `therapistImages/${therapist?.id}/${file.name}`);
      try {
        const snapshot = await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(snapshot.ref);
        await updateDoc(doc(db, 'therapists/' + therapist?.id), {
          profilePicture: { assetUrl: downloadURL, tpye: 'image', name: file.name },
          profilePictureUrl: downloadURL,
        });
      } catch (error) {
        console.error('Error uploading file:', error);
        setEventIndicator('error', 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.');
      } finally {
        setUploading(false);
        setLoading(false);
      }
    } else {
      alert('Bitte verwende nur Dateien vom Typ JPG oder PNG.');
    }
  };

  return (
    <Container>
      <HiddenInput ref={inputRef} type="file" accept=".jpg,.jpeg,.png" onChange={handleChange} />
      {loading ? (
        <LoadingContainer>
          <LoadingSpinner color={colors.Primary} />
        </LoadingContainer>
      ) : therapist?.profilePicture?.assetUrl ? (
        <CustomButton onClick={onButtonClick}>
          <ProfilePicture src={file ? URL.createObjectURL(file) : therapist?.profilePicture?.assetUrl} />
        </CustomButton>
      ) : (
        <IconButton iconName="AddProfilePicture" stroke={colors.Primary} size={50} onClick={onButtonClick} />
      )}

      <Text>{therapist?.firstName + ' ' + therapist?.lastName}</Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  align-items: center;
  padding: 10px 0;
  border-bottom: 2px solid ${colors.Light};
`;

const Text = styled(H4)`
  min-width: 100px;
  color: ${colors.Medium};
`;

const ProfilePicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const HiddenInput = styled.input`
  display: none;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
`;
