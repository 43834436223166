import styled from 'styled-components';
import { GenericSvg } from '../../../../assets/svgs/GenericSVG';
import { getAppBrandingColors } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { Body } from '../../../../styles/textStyles';

export const MockWeekHeader = () => {
  const appColors = useMainStore(getAppBrandingColors);

  return (
    <WeekContainer>
      <WeekHeader $color={appColors.Primary}>
        <WeekLeft>
          <GenericSvg name="Checkmark" size={20} primaryColor={appColors.White} />
          <WeekText>{'Woche 1'}</WeekText>
        </WeekLeft>
        <GenericSvg name="ArrowDown" size={24} primaryColor={appColors.White} />
      </WeekHeader>
      <WeekHeader $color={appColors.Primary}>
        <WeekLeft>
          <GenericSvg name="Checkmark" size={20} primaryColor={appColors.White} />
          <WeekText>{'Woche 2'}</WeekText>
        </WeekLeft>
        <GenericSvg name="ArrowDown" size={24} primaryColor={appColors.White} />
      </WeekHeader>
    </WeekContainer>
  );
};

const WeekHeader = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

const WeekLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const WeekText = styled(Body)`
  color: ${colors.White};
`;

const WeekContainer = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-top: 20px;
`;
