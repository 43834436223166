import { useEffect, useState } from 'react';
import { useProgramsConnect } from './useProgramsConnect';

export const useHandleEasyPlanner = () => {
  const { programs } = useProgramsConnect();
  const [selectedProgramId, setSelectedProgramId] = useState(programs?.[0].id);

  const handleSelectProgram = (programId: string) => {
    setSelectedProgramId(programId);
  };

  useEffect(() => {
    if (!programs) return;
    setSelectedProgramId(programs[0].id);
  }, [programs]);

  return {
    programs,
    selectedProgramId,
    handleSelectProgram,
    selectedProgram: programs?.find((program) => program.id === selectedProgramId),
  };
};
