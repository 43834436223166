import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '../../../core/components/buttons/ButtonGroup';
import { NavigationHeader } from '../../../navigation/components/NavigationHeader';
import { useMainStore } from '../../../store/mainStore';

type Props = {
  children: React.ReactNode;
  hideBackButton?: boolean;
  hideCloseButton?: boolean;
  handleBack?: () => void;
  nextButton?: {
    text?: string;
    onClick: () => void;
    disabled?: boolean;
    currentStep?: number;
    numberOfSteps?: number;
    loading?: boolean;
  };
  textButton?: {
    disabled?: boolean;
    text: string;
    onClick: () => void;
    loading?: boolean;
  };
};
export const AppBrandingScreenContainer = ({
  children,
  hideBackButton,
  nextButton,
  handleBack,
  textButton,
  hideCloseButton,
}: Props) => {
  const navigate = useNavigate();
  const resetAppBranding = useMainStore((state) => state.resetAppBrandingState);

  const onBackClick = () => {
    if (handleBack) {
      handleBack();
    } else {
      navigate(-1);
    }
  };

  const handleClose = async () => {
    if (
      confirm(
        'Möchtest du das Erstellen deines App Brandings wirklich beenden? Alle bisherigen Eingaben werden gelöscht.',
      )
    ) {
      resetAppBranding();
      navigate('/');
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <NavigationHeader
        onBackClick={hideBackButton ? undefined : onBackClick}
        onCloseClick={hideCloseButton ? undefined : handleClose}
      />
      <ContentContainer>
        {children}
        <ButtonGroup
          progressButton={
            nextButton ? { ...nextButton, text: nextButton.text ?? t('ONBOARDING-BUTTON-NEXT') } : undefined
          }
          textButton={textButton}
        />
      </ContentContainer>
    </>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  padding: 0 20px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  gap: 20px;
`;
