import styled from 'styled-components';
import { getAppBrandingColors } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { Small } from '../../../../styles/textStyles';

type Props = {
  text: string;
};

export const MockDayLine = ({ text }: Props) => {
  const appColors = useMainStore(getAppBrandingColors);

  return <Text $color={appColors.Dark}>{text ?? ''}</Text>;
};

const Text = styled(Small)<{ $color: string }>`
  text-align: left;
  color: ${({ $color }) => $color};
  max-lines: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
