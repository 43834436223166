import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BrandingHeader from '../../../assets/images/BrandingHeader.jpg';
import { ButtonGroup } from '../../../core/components/buttons/ButtonGroup';
import { InfoText } from '../../../core/components/text/InfoText';
import { useAppBrandingConnect } from '../../../core/utils/connectFirestore/useAppBrandingConnect';
import { OnboardingScreenContainer } from '../../onboarding/components/OnboardingScreenContainer';

export const AppBrandingIntroScreen = () => {
  const loading = useAppBrandingConnect();
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('../color');
  };

  const handleBack = () => {
    navigate('/');
  };

  return (
    <OnboardingScreenContainer handleBack={handleBack} hideProgress hideCloseButton>
      <div>
        <HeaderImage src={BrandingHeader} alt="BrandingHeader" />

        <StyledInfoText
          headline={'Deine Praxis, deine App – individuell gestaltet!'}
          text={[
            'Gestalte deine Praxis in der Physiofit App ganz individuell, indem du dein eigenes Logo und deine bevorzugten Farben einfügst. Zudem kannst du eine eigene Praxis-Seite erstellen, auf der du wichtige Informationen wie Adresse, Öffnungszeiten, Leistungen und Bilder präsentierst. Diese Daten kannst du später jederzeit bequem über unsere Website aktualisieren.',
          ]}
        />
        <ButtonGroup progressButton={{ text: 'Jetzt starten', onClick: handleNext, loading }} />
      </div>
    </OnboardingScreenContainer>
  );
};

const HeaderImage = styled.img`
  width: 100%;
  max-height: 140px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
`;

const StyledInfoText = styled(InfoText)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
