import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InfoText } from '../../../core/components/text/InfoText';
import { getAppBrandingColors } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { AppBrandingColorPicker } from '../components/AppBrandingColorPicker';
import { AppBrandingScreenContainer } from '../components/AppBrandingScreenContainer';
import { useAppBrandingPracticeInfo } from '../utils/useAppBrandingPracticeInfo';

export const AppBrandingColorScreen = () => {
  const { t } = useTranslation();
  const { practiceName } = useAppBrandingPracticeInfo();
  const brandingColor = useMainStore(getAppBrandingColors)?.Primary;

  const navigate = useNavigate();

  const handleNext = () => {
    navigate('../logo');
  };

  const currentStep = brandingColor === colors.Primary ? 0 : 1;

  return (
    <AppBrandingScreenContainer
      hideBackButton
      nextButton={{
        text: 'Weiter',
        onClick: handleNext,
        numberOfSteps: 1,
        currentStep: currentStep,
      }}
    >
      <div>
        <StyledInfoText
          headline={'App Farbe'}
          text={[
            'Deine Corporate Farbe verleiht der App den letzten Schliff. Wähle sie hier aus, um das Design deiner Praxis optimal widerzuspiegeln.',
          ]}
          preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName: practiceName })}
        />
        <AppBrandingColorPicker />
      </div>
    </AppBrandingScreenContainer>
  );
};
const StyledInfoText = styled(InfoText)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
