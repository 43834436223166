import { useState } from 'react';
import { getAppBrandingOpeningHours } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { AppBrandingOpeningHours } from '../../../appBranding/components/AppBrandingOpeningHours';
import { useAppBrandingPracticeInfo } from '../../../appBranding/utils/useAppBrandingPracticeInfo';
import { SettingsBrandingComponentsWrapper } from './SettingsBrandingComponentsWrapper';

export const SettingsBrandingOpeningTimes = () => {
  const setEventIndicator = useMainStore((state) => state.setEventIndicatorData);
  const { uploadAppBrandingData, uploading } = useAppBrandingPracticeInfo();
  const zustandAppBrandingOpeningHours = useMainStore(getAppBrandingOpeningHours);
  const [initialAppBrandingOpeningHours, setInitialAppBrandingOpeningHours] = useState(zustandAppBrandingOpeningHours);
  const nextButtonDisabled = zustandAppBrandingOpeningHours === initialAppBrandingOpeningHours;
  const handleSave = async () => {
    setInitialAppBrandingOpeningHours(zustandAppBrandingOpeningHours);
    await uploadAppBrandingData();
    setEventIndicator('success', 'Änderungen gespeichert');
  };
  return (
    <SettingsBrandingComponentsWrapper
      title="Öffnungszeiten"
      description="Trage hier deine Öffnungszeiten ein, damit Patienten immer informiert sind, wann deine Praxis geöffnet ist."
      nextButton={{
        text: 'Speichern',
        onClick: handleSave,
        loading: uploading,
        disabled: nextButtonDisabled,
      }}
    >
      <AppBrandingOpeningHours />
    </SettingsBrandingComponentsWrapper>
  );
};
