import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';

export const saveMapImageToFirebase = async (imageUrl: string, practiceId: string) => {
  try {
    // Fetch the image
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    // Convert blob to base64
    const reader = new FileReader();
    const base64Image = await new Promise<string>((resolve) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });

    // Initialize Firebase Storage
    const storage = getStorage();

    // Generate a unique filename
    const filename = `maps.png`;
    const storageRef = ref(storage, `mapsImage/${practiceId}/${filename}`);

    // Upload the image
    const snapshot = await uploadString(storageRef, base64Image, 'data_url');

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);

    return downloadURL;
  } catch (error) {
    console.error('Error saving map image to Firebase:', error);
    throw error;
  }
};
