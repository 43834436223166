import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { InfoText } from '../../../core/components/text/InfoText';
import { getAppBrandingClaim } from '../../../store/appBrandingSlice';
import { useMainStore } from '../../../store/mainStore';
import { AppBrandingClaimInput } from '../components/AppBrandingClaimInput';
import { AppBrandingScreenContainer } from '../components/AppBrandingScreenContainer';
import { useAppBrandingPracticeInfo } from '../utils/useAppBrandingPracticeInfo';

export const AppBrandingClaimScreen = () => {
  const { t } = useTranslation();
  const { practiceName } = useAppBrandingPracticeInfo();
  const claim = useMainStore(getAppBrandingClaim);
  const setClaim = useMainStore((state) => state.setAppBrandingClaim);

  const navigate = useNavigate();

  const handleNext = () => {
    navigate('../services');
  };
  const handleBack = () => {
    navigate('../therapists');
  };

  const handleSkip = () => {
    setClaim('');
    navigate('../services');
  };

  const currentStep = claim ? 1 : 0;

  return (
    <AppBrandingScreenContainer
      handleBack={handleBack}
      nextButton={{
        text: 'Weiter',
        onClick: handleNext,
        numberOfSteps: 1,
        currentStep,
      }}
      textButton={{
        text: 'Schritt überspringen',
        onClick: handleSkip,
      }}
    >
      <div>
        <StyledInfoText
          headline={'Praxis Slogan'}
          text={['Formuliere eine kurze Kernbotschaft an deine Patienten und zeige damit wofür deine Praxis steht.']}
          preHeadline={t('ONBOARDING-PRACTICE-PRE-HEADLINE', { practiceName: practiceName })}
        />
        <AppBrandingClaimInput />
      </div>
    </AppBrandingScreenContainer>
  );
};
const StyledInfoText = styled(InfoText)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
