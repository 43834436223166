import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { TextButton } from '../../../core/components/buttons/TextButton';
import { getChainPractices, getOnboardingFlags } from '../../../store/chainSlice';
import { useMainStore } from '../../../store/mainStore';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';

type Props = {
  completed: boolean;
};

export const AppBrandingStartButtons = ({ completed }: Props) => {
  const practices = useMainStore(getChainPractices);
  const navigate = useNavigate();
  const onboardingFlags = useMainStore(getOnboardingFlags);

  const openBranding = (practiceId: string) => {
    if (onboardingFlags?.practices?.[practiceId]?.brandingData) {
      navigate(`/practice-settings/${practiceId}/branding-settings`);
    } else {
      navigate(`/branding/${practiceId}/intro`);
    }
  };

  return (
    <ContentContainer>
      <StyledH3 $color={completed ? colors.White : colors.Dark}>App Design Anpassen:</StyledH3>
      {practices?.map((practice) => (
        <TextButton
          key={practice.id}
          text={practice.name}
          color={completed ? colors.White : colors.Dark}
          onClick={() => openBranding(practice.id)}
        />
      ))}
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledH3 = styled(H3)<{ $color: string }>`
  color: ${(props) => props.$color};
  margin-bottom: 10px;
`;
