import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { ProgressButton } from '../../../../core/components/buttons/ProgressButton';
import { TextButton } from '../../../../core/components/buttons/TextButton';
import { usePracticeConnect } from '../../../../core/utils/connectFirestore/usePracticeConnect';
import { getAppBrandingColors } from '../../../../store/appBrandingSlice';
import { useMainStore } from '../../../../store/mainStore';
import { colors } from '../../../../styles/colors';
import { Body } from '../../../../styles/textStyles';
import { useAppBrandingPracticeInfo } from '../../utils/useAppBrandingPracticeInfo';

export const MockOnboardingVideo = () => {
  const { practiceId } = useAppBrandingPracticeInfo();
  const { practice } = usePracticeConnect(practiceId);
  const practiceVideoUrl = practice?.assets?.onboardingVideo?.storageUrl;
  const [muted, setMuted] = useState(true);
  const [finishedOnce, setFinishedOnce] = useState(false);
  const appColors = useMainStore(getAppBrandingColors);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [progress, setProgress] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);

  useEffect(() => {
    if (practiceVideoUrl && videoRef.current) {
      if (videoRef.current) {
        videoRef.current.src = practiceVideoUrl;
        videoRef.current.play();
      }

      const video = videoRef.current;

      const handleLoadedMetadata = () => {
        setVideoDuration(Math.floor(video.duration));
      };

      const handleTimeUpdate = () => {
        setProgress(Math.floor(video.currentTime));
        if (video.currentTime >= video.duration - 0.5) {
          setFinishedOnce(true);
        }
      };

      video.addEventListener('loadedmetadata', handleLoadedMetadata);
      video.addEventListener('timeupdate', handleTimeUpdate);

      return () => {
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
        video.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [practiceVideoUrl]);

  const handleReplay = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
      setFinishedOnce(false);
    }
  };
  const storageUrl = practice?.assets?.onboardingVideo?.storageUrl;

  return (
    <Container>
      {!storageUrl ? (
        <SkeletonWrapper>
          <Skeleton height="100%" />
          <LoadingTextContainer>
            <StyledBody>{'Noch kein Video hochgeladen'}</StyledBody>
          </LoadingTextContainer>
        </SkeletonWrapper>
      ) : (
        <Video ref={videoRef} autoPlay playsInline muted={muted} />
      )}
      {storageUrl && videoRef.current && (
        <ButtonContainer>
          {finishedOnce && (
            <ProgressButton
              text={'Erneut Abspielen'}
              onClick={handleReplay}
              primaryColor={appColors.Primary}
              currentStep={progress}
              numberOfSteps={videoDuration}
              disabled={!videoRef.current}
            />
          )}
          <TextButton
            text={muted ? 'Ton an' : 'Ton aus'}
            onClick={() => setMuted(!muted)}
            disabled={!videoRef.current}
            color={colors.White}
          />
        </ButtonContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: white;
  position: relative;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: black;
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`;

const SkeletonWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const LoadingTextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBody = styled(Body)`
  color: ${colors.Dark};
  text-align: center;
`;
