import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LabeledSwitchButton } from '../../../core/components/buttons/LabeledSwitchButton';
import { TagSelection } from '../../../core/components/tags/TagSelection';
import { colors } from '../../../styles/colors';
import { H3 } from '../../../styles/textStyles';
import { PracticePayout } from '../../../types/Practices/PracticePayoutType';
import { useHandleInformationForm } from '../utils/useHandleInformationForm';
import { useHandleTransferPracticeData } from '../utils/useHandleTransferPracticeData';
import { BillingForm } from './BillingForm';

type FormFields = {
  practiceName: string;
  street: string;
  houseNumber: string;
  cityCode: string;
  city: string;
  taxId: string;
  copyId: string;
};
type Props = {
  setCurrentStep?: (step: number) => void;
  practiceIndex: number;
  fields: FormFields;
  setFields: (value: FormFields) => void;
  focusOnFirstInput?: boolean;
  loadingData?: boolean;
};

export const PayoutAddressInformation = ({
  practiceIndex,
  setCurrentStep,
  fields,
  setFields,
  focusOnFirstInput,
  loadingData,
}: Props) => {
  const handleTransfer = (data: PracticePayout) => {
    const payoutAddress = data.payoutAddress;
    setFields({
      practiceName: payoutAddress?.payoutRecipient ?? '',
      street: payoutAddress?.street ?? '',
      houseNumber: payoutAddress?.houseNumber ?? '',
      cityCode: payoutAddress?.cityCode ?? '',
      city: payoutAddress?.city ?? '',
      taxId: data.taxId ?? '',
      copyId: data.practiceId ?? '',
    });
  };

  const clearData = () => {
    setFields({
      practiceName: '',
      street: '',
      houseNumber: '',
      cityCode: '',
      city: '',
      taxId: '',
      copyId: '',
    });
  };

  const {
    showToggle,
    toggleActive,
    handleToggle,
    practicesToTransfer,
    setToggleInactive,
    setSelectedTransferPractice,
  } = useHandleTransferPracticeData(practiceIndex, handleTransfer, clearData, fields.copyId);

  const setFieldsHelper = (newFields: any) => {
    setToggleInactive();
    setFields({ ...newFields, copyId: '' });
  };

  const { handleInput } = useHandleInformationForm(fields, setFieldsHelper, setCurrentStep, [
    'taxNumber',
    'complement',
  ]);
  const { t } = useTranslation();

  useEffect(() => {
    if (focusOnFirstInput) {
      document.getElementById('firstInput')?.focus();
    }
  }, []);

  return (
    <Container>
      <StyledH3>{t('ONBOARDING-ADDRESS-INFORMATION-FORM-TITLE')}</StyledH3>
      {showToggle && (
        <LabeledSwitchButton
          label={t('ONBOARDING-BANK-INFORMATION-FORM-TRANSFER-DATA')}
          isChecked={toggleActive}
          setIsChecked={handleToggle}
        />
      )}
      {toggleActive && (
        <TagSelection
          selected={[fields.copyId]}
          tags={practicesToTransfer?.map((practice) => ({ text: practice.name, value: practice.id })) ?? []}
          setSelected={(value) => setSelectedTransferPractice(value?.[0] as string)}
          type={'singleSelect'}
        />
      )}
      <BillingForm fields={fields} handleInput={handleInput} setFields={setFields} loadingData={loadingData} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 10px;
  width: 100%;
`;

const StyledH3 = styled(H3)`
  color: ${colors.Medium};
`;
